import {
  Heading,
  Avatar,
  Box,
  Center,
  Image,
  Flex,
  Text,
  HStack,
  Stack,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";

//  METAGAME IMAGES
// https://bafybeigg7gtgk7pdzpgazmb5bgqcewosazv7v73t6gcyj7g52xp7bfkcky/
// https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4/
// https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq/
// https://bafybeiggmevfo3wujbvt3xqcvaudhrbs5wug7heigsxnnbkueburlefena/

function CardContact1() {
  return (
    <div style={{ backgroundImage: `url(${background})` }}>
      <HStack>
        <Center py={6}>
          <Box
            ml="2"
            p={6}
            maxW={"270px"}
            w={"full"}
            bg={useColorModeValue("silver", "gray.800")}
            boxShadow={"2xl"}
            rounded={"md"}
            overflow={"hidden"}
          >
            <Image
              h={"80px"}
              w={"full"}
              src={
                " https://bafybeigg7gtgk7pdzpgazmb5bgqcewosazv7v73t6gcyj7g52xp7bfkcky/"
              }
              objectFit={"cover"}
            />
            <Flex justify={"center"} mt={-6}>
              <Avatar
                size={"xl"}
                src={
                  " https://bafybeigdaojzkct45fqzgqqggziemqyzog2uhpb5cpe5zhwsro2jw7tq64/ "
                }
                alt={" MetaversePro "}
                css={{
                  border: "2px solid white",
                }}
              />
            </Flex>

            <Box p={6}>
              <Stack spacing={0} align={"center"} mb={5}>
                <Heading fontSize={"2xl"} fontWeight={500} fontFamily={"body"}>
                  Designer One
                </Heading>
                <Text color={"gray.500"}> MetaversePro Designer 1 </Text>
              </Stack>
              <Stack direction={"row"} justify={"center"} spacing={6}>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    All-time XP
                  </Text>
                </Stack>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Seasonal XP
                  </Text>
                </Stack>
              </Stack>
              <Button
                w={"full"}
                mt={8}
                bg={useColorModeValue("#001975", "gray.900")}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
              >
                Web3 Handshake
              </Button>
            </Box>
          </Box>
        </Center>

        <Center py={6}>
          <Box
            p={6}
            maxW={"270px"}
            w={"full"}
            bg={useColorModeValue("silver", "gray.800")}
            boxShadow={"2xl"}
            rounded={"md"}
            overflow={"hidden"}
          >
            <Image
              h={"80px"}
              w={"full"}
              src={
                " https://bafybeigg7gtgk7pdzpgazmb5bgqcewosazv7v73t6gcyj7g52xp7bfkcky"
              }
              objectFit={"cover"}
            />
            <Flex justify={"center"} mt={-6}>
              <Avatar
                size={"xl"}
                src={
                  " https://bafybeieu4t6tn32cc7yvvnt5mwfaur5cle6ktndqh47tru65asbwpztkga/ "
                }
                alt={" MetaversePro "}
                css={{
                  border: "2px solid white",
                }}
              />
            </Flex>

            <Box p={6}>
              <Stack spacing={0} align={"center"} mb={5}>
                <Heading fontSize={"2xl"} fontWeight={500} fontFamily={"body"}>
                  Designer Two
                </Heading>
                <Text color={"gray.500"}> MetaversePro Designer 2 </Text>
              </Stack>
              <Stack direction={"row"} justify={"center"} spacing={6}>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    All-time XP
                  </Text>
                </Stack>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Seasonal XP
                  </Text>
                </Stack>
              </Stack>
              <Button
                w={"full"}
                mt={8}
                bg={useColorModeValue("#001975", "gray.900")}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
              >
                Web3 Handshake
              </Button>
            </Box>
          </Box>
        </Center>

        <Center py={6}>
          <Box
            p={6}
            maxW={"270px"}
            w={"full"}
            bg={useColorModeValue("silver", "gray.800")}
            boxShadow={"2xl"}
            rounded={"md"}
            overflow={"hidden"}
          >
            <Image
              h={"80px"}
              w={"full"}
              src={
                " https://bafybeigg7gtgk7pdzpgazmb5bgqcewosazv7v73t6gcyj7g52xp7bfkcky"
              }
              objectFit={"cover"}
            />
            <Flex justify={"center"} mt={-6}>
              <Avatar
                size={"xl"}
                src={
                  " https://bafybeih4g5hqfjj2wwtqlzom7ci6pvxzv3rxlehnxd3i4ua66e5pckqp2q/ "
                }
                alt={" MetaversePro "}
                css={{
                  border: "2px solid white",
                }}
              />
            </Flex>

            <Box p={6}>
              <Stack spacing={0} align={"center"} mb={5}>
                <Heading fontSize={"2xl"} fontWeight={500} fontFamily={"body"}>
                  Designer Thr3
                </Heading>
                <Text color={"gray.500"}> MetaversePro Designer 3 </Text>
              </Stack>
              <Stack direction={"row"} justify={"center"} spacing={6}>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    All-time XP
                  </Text>
                </Stack>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Seasonal XP
                  </Text>
                </Stack>
              </Stack>
              <Button
                w={"full"}
                mt={8}
                bg={useColorModeValue("#001975", "gray.900")}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
              >
                Web3 Handshake
              </Button>
            </Box>
          </Box>
        </Center>

        <Center py={6}>
          <Box
            p={6}
            maxW={"270px"}
            w={"full"}
            bg={useColorModeValue("silver", "gray.800")}
            boxShadow={"2xl"}
            rounded={"md"}
            overflow={"hidden"}
          >
            <Image
              h={"80px"}
              w={"full"}
              src={
                " https://bafybeigg7gtgk7pdzpgazmb5bgqcewosazv7v73t6gcyj7g52xp7bfkcky"
              }
              objectFit={"cover"}
            />
            <Flex justify={"center"} mt={-6}>
              <Avatar
                size={"xl"}
                src={
                  " https://bafybeigdaojzkct45fqzgqqggziemqyzog2uhpb5cpe5zhwsro2jw7tq64/ "
                }
                alt={" MetaversePro "}
                css={{
                  border: "2px solid white",
                }}
              />
            </Flex>

            <Box p={6}>
              <Stack spacing={0} align={"center"} mb={5}>
                <Heading fontSize={"2xl"} fontWeight={500} fontFamily={"body"}>
                  Designer Four
                </Heading>
                <Text color={"gray.500"}> MetaversePro Designer 4 </Text>
              </Stack>
              <Stack direction={"row"} justify={"center"} spacing={6}>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    All-time XP
                  </Text>
                </Stack>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Seasonal XP
                  </Text>
                </Stack>
              </Stack>
              <Button
                w={"full"}
                mt={8}
                bg={useColorModeValue("#001975", "gray.900")}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
              >
                Web3 Handshake
              </Button>
            </Box>
          </Box>
        </Center>

        <Center py={6}>
          <Box
            p={6}
            maxW={"270px"}
            w={"full"}
            bg={useColorModeValue("silver", "gray.800")}
            boxShadow={"2xl"}
            rounded={"md"}
            overflow={"hidden"}
          >
            <Image
              h={"80px"}
              w={"full"}
              src={
                " https://bafybeigg7gtgk7pdzpgazmb5bgqcewosazv7v73t6gcyj7g52xp7bfkcky"
              }
              objectFit={"cover"}
            />
            <Flex justify={"center"} mt={-6}>
              <Avatar
                size={"xl"}
                src={
                  " https://bafybeib4lcuqh2iylaj6ywqxn4ccgpz5faiirppitodl5h7b6kmxnevppa/ "
                }
                alt={" MetaversePro "}
                css={{
                  border: "2px solid white",
                }}
              />
            </Flex>

            <Box p={6}>
              <Stack spacing={0} align={"center"} mb={5}>
                <Heading fontSize={"2xl"} fontWeight={500} fontFamily={"body"}>
                  Designer Five
                </Heading>
                <Text color={"gray.500"}> MetaversePro Designer 5 </Text>
              </Stack>
              <Stack direction={"row"} justify={"center"} spacing={6}>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    All-time XP
                  </Text>
                </Stack>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Seasonal XP
                  </Text>
                </Stack>
              </Stack>
              <Button
                w={"full"}
                mt={8}
                bg={useColorModeValue("#001975", "gray.900")}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
              >
                Web3 Handshake
              </Button>
            </Box>
          </Box>
        </Center>

        <Center py={6}>
          <Box
            p={6}
            maxW={"270px"}
            w={"full"}
            bg={useColorModeValue("silver", "gray.800")}
            boxShadow={"2xl"}
            rounded={"md"}
            overflow={"hidden"}
          >
            <Image
              h={"80px"}
              w={"full"}
              src={
                " https://bafybeigg7gtgk7pdzpgazmb5bgqcewosazv7v73t6gcyj7g52xp7bfkcky"
              }
              objectFit={"cover"}
            />
            <Flex justify={"center"} mt={-6}>
              <Avatar
                size={"xl"}
                src={
                  " https://bafybeicbntmwdx3vayh7pxfg6sduwculxshwtaof2agjbqgx2z5fgtxrvi "
                }
                alt={" MetaversePro "}
                css={{
                  border: "2px solid white",
                }}
              />
            </Flex>

            <Box p={6}>
              <Stack spacing={0} align={"center"} mb={5}>
                <Heading fontSize={"2xl"} fontWeight={500} fontFamily={"body"}>
                  Designer Six
                </Heading>
                <Text color={"gray.500"}> MetaversePro Designer 6 </Text>
              </Stack>
              <Stack direction={"row"} justify={"center"} spacing={6}>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    All-time XP
                  </Text>
                </Stack>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Seasonal XP
                  </Text>
                </Stack>
              </Stack>
              <Button
                w={"full"}
                mt={8}
                bg={useColorModeValue("#001975", "gray.900")}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
              >
                Web3 Handshake
              </Button>
            </Box>
          </Box>
        </Center>

        <Center py={6}>
          <Box
            p={6}
            maxW={"270px"}
            w={"full"}
            bg={useColorModeValue("silver", "gray.800")}
            boxShadow={"2xl"}
            rounded={"md"}
            overflow={"hidden"}
          >
            <Image
              h={"80px"}
              w={"full"}
              src={
                " https://bafybeigg7gtgk7pdzpgazmb5bgqcewosazv7v73t6gcyj7g52xp7bfkcky"
              }
              objectFit={"cover"}
            />
            <Flex justify={"center"} mt={-6}>
              <Avatar
                size={"xl"}
                src={
                  " https://bafybeihb465w6sxhpiqntihkpe5x24kkdcxvjvonlipgnvzhar6zqapefq/ "
                }
                alt={" MetaversePro "}
                css={{
                  border: "2px solid white",
                }}
              />
            </Flex>

            <Box p={6}>
              <Stack spacing={0} align={"center"} mb={5}>
                <Heading fontSize={"2xl"} fontWeight={500} fontFamily={"body"}>
                  Designer Sev
                </Heading>
                <Text color={"gray.500"}> MetaversePro Designer 7 </Text>
              </Stack>
              <Stack direction={"row"} justify={"center"} spacing={6}>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    All-time XP
                  </Text>
                </Stack>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Seasonal XP
                  </Text>
                </Stack>
              </Stack>
              <Button
                w={"full"}
                mt={8}
                bg={useColorModeValue("#001975", "gray.900")}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
              >
                Web3 Handshake
              </Button>
            </Box>
          </Box>
        </Center>
        <Center py={6}>
          <Box
            p={6}
            maxW={"270px"}
            w={"full"}
            bg={useColorModeValue("silver", "gray.800")}
            boxShadow={"2xl"}
            rounded={"md"}
            overflow={"hidden"}
          >
            <Image
              h={"80px"}
              w={"full"}
              src={
                " https://bafybeigg7gtgk7pdzpgazmb5bgqcewosazv7v73t6gcyj7g52xp7bfkcky/"
              }
              objectFit={"cover"}
            />
            <Flex justify={"center"} mt={-6}>
              <Avatar
                size={"xl"}
                src={
                  " https://bafybeigdaojzkct45fqzgqqggziemqyzog2uhpb5cpe5zhwsro2jw7tq64/ "
                }
                alt={" MetaversePro "}
                css={{
                  border: "2px solid white",
                }}
              />
            </Flex>

            <Box p={6}>
              <Stack spacing={0} align={"center"} mb={5}>
                <Heading fontSize={"2xl"} fontWeight={500} fontFamily={"body"}>
                  Designer Eight
                </Heading>
                <Text color={"gray.500"}> MetaversePro Designer 8 </Text>
              </Stack>
              <Stack direction={"row"} justify={"center"} spacing={6}>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    All-time XP
                  </Text>
                </Stack>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Seasonal XP
                  </Text>
                </Stack>
              </Stack>
              <Button
                w={"full"}
                mt={8}
                bg={useColorModeValue("#001975", "gray.900")}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
              >
                Web3 Handshake
              </Button>
            </Box>
          </Box>
        </Center>

        <Center py={6}>
          <Box
            p={6}
            maxW={"270px"}
            w={"full"}
            bg={useColorModeValue("silver", "gray.800")}
            boxShadow={"2xl"}
            rounded={"md"}
            overflow={"hidden"}
          >
            <Image
              h={"80px"}
              w={"full"}
              src={
                " https://bafybeigg7gtgk7pdzpgazmb5bgqcewosazv7v73t6gcyj7g52xp7bfkcky"
              }
              objectFit={"cover"}
            />
            <Flex justify={"center"} mt={-6}>
              <Avatar
                size={"xl"}
                src={
                  " https://bafybeieu4t6tn32cc7yvvnt5mwfaur5cle6ktndqh47tru65asbwpztkga/ "
                }
                alt={" MetaversePro "}
                css={{
                  border: "2px solid white",
                }}
              />
            </Flex>

            <Box p={6}>
              <Stack spacing={0} align={"center"} mb={5}>
                <Heading fontSize={"2xl"} fontWeight={500} fontFamily={"body"}>
                  Designer Nine
                </Heading>
                <Text color={"gray.500"}> MetaversePro Designer 9 </Text>
              </Stack>
              <Stack direction={"row"} justify={"center"} spacing={6}>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    All-time XP
                  </Text>
                </Stack>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Seasonal XP
                  </Text>
                </Stack>
              </Stack>
              <Button
                w={"full"}
                mt={8}
                bg={useColorModeValue("#001975", "gray.900")}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
              >
                Web3 Handshake
              </Button>
            </Box>
          </Box>
        </Center>

        <Center py={6}>
          <Box
            p={6}
            maxW={"270px"}
            w={"full"}
            bg={useColorModeValue("silver", "gray.800")}
            boxShadow={"2xl"}
            rounded={"md"}
            overflow={"hidden"}
          >
            <Image
              h={"80px"}
              w={"full"}
              src={
                " https://bafybeigg7gtgk7pdzpgazmb5bgqcewosazv7v73t6gcyj7g52xp7bfkcky"
              }
              objectFit={"cover"}
            />
            <Flex justify={"center"} mt={-6}>
              <Avatar
                size={"xl"}
                src={
                  " https://bafybeih4g5hqfjj2wwtqlzom7ci6pvxzv3rxlehnxd3i4ua66e5pckqp2q/ "
                }
                alt={" MetaversePro "}
                css={{
                  border: "2px solid white",
                }}
              />
            </Flex>

            <Box p={6}>
              <Stack spacing={0} align={"center"} mb={5}>
                <Heading fontSize={"2xl"} fontWeight={500} fontFamily={"body"}>
                  Designer Ten
                </Heading>
                <Text color={"gray.500"}> MetaversePro Designer 10 </Text>
              </Stack>
              <Stack direction={"row"} justify={"center"} spacing={6}>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    All-time XP
                  </Text>
                </Stack>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Seasonal XP
                  </Text>
                </Stack>
              </Stack>
              <Button
                w={"full"}
                mt={8}
                bg={useColorModeValue("#001975", "gray.900")}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
              >
                Web3 Handshake
              </Button>
            </Box>
          </Box>
        </Center>

        <Center py={6}>
          <Box
            p={6}
            maxW={"270px"}
            w={"full"}
            bg={useColorModeValue("silver", "gray.800")}
            boxShadow={"2xl"}
            rounded={"md"}
            overflow={"hidden"}
          >
            <Image
              h={"80px"}
              w={"full"}
              src={
                " https://bafybeigg7gtgk7pdzpgazmb5bgqcewosazv7v73t6gcyj7g52xp7bfkcky"
              }
              objectFit={"cover"}
            />
            <Flex justify={"center"} mt={-6}>
              <Avatar
                size={"xl"}
                src={
                  " https://bafybeigdaojzkct45fqzgqqggziemqyzog2uhpb5cpe5zhwsro2jw7tq64/ "
                }
                alt={" MetaversePro "}
                css={{
                  border: "2px solid white",
                }}
              />
            </Flex>

            <Box p={6}>
              <Stack spacing={0} align={"center"} mb={5}>
                <Heading fontSize={"2xl"} fontWeight={500} fontFamily={"body"}>
                  Designer Elev
                </Heading>
                <Text color={"gray.500"}> MetaversePro Designer 11 </Text>
              </Stack>
              <Stack direction={"row"} justify={"center"} spacing={6}>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    All-time XP
                  </Text>
                </Stack>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Seasonal XP
                  </Text>
                </Stack>
              </Stack>
              <Button
                w={"full"}
                mt={8}
                bg={useColorModeValue("#001975", "gray.900")}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
              >
                Web3 Handshake
              </Button>
            </Box>
          </Box>
        </Center>

        <Center py={6}>
          <Box
            p={6}
            maxW={"270px"}
            w={"full"}
            bg={useColorModeValue("silver", "gray.800")}
            boxShadow={"2xl"}
            rounded={"md"}
            overflow={"hidden"}
          >
            <Image
              h={"80px"}
              w={"full"}
              src={
                " https://bafybeigg7gtgk7pdzpgazmb5bgqcewosazv7v73t6gcyj7g52xp7bfkcky"
              }
              objectFit={"cover"}
            />
            <Flex justify={"center"} mt={-6}>
              <Avatar
                size={"xl"}
                src={
                  " https://bafybeib4lcuqh2iylaj6ywqxn4ccgpz5faiirppitodl5h7b6kmxnevppa/ "
                }
                alt={" MetaversePro "}
                css={{
                  border: "2px solid white",
                }}
              />
            </Flex>

            <Box p={6}>
              <Stack spacing={0} align={"center"} mb={5}>
                <Heading fontSize={"2xl"} fontWeight={500} fontFamily={"body"}>
                  Designer Twelv
                </Heading>
                <Text color={"gray.500"}> MetaversePro Designer 12 </Text>
              </Stack>
              <Stack direction={"row"} justify={"center"} spacing={6}>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    All-time XP
                  </Text>
                </Stack>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Seasonal XP
                  </Text>
                </Stack>
              </Stack>
              <Button
                w={"full"}
                mt={8}
                bg={useColorModeValue("#001975", "gray.900")}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
              >
                Web3 Handshake
              </Button>
            </Box>
          </Box>
        </Center>

        <Center py={6}>
          <Box
            p={6}
            maxW={"270px"}
            w={"full"}
            bg={useColorModeValue("silver", "gray.800")}
            boxShadow={"2xl"}
            rounded={"md"}
            overflow={"hidden"}
          >
            <Image
              h={"80px"}
              w={"full"}
              src={
                " https://bafybeigg7gtgk7pdzpgazmb5bgqcewosazv7v73t6gcyj7g52xp7bfkcky"
              }
              objectFit={"cover"}
            />
            <Flex justify={"center"} mt={-6}>
              <Avatar
                size={"xl"}
                src={
                  " https://bafybeihb465w6sxhpiqntihkpe5x24kkdcxvjvonlipgnvzhar6zqapefq "
                }
                alt={" MetaversePro "}
                css={{
                  border: "2px solid white",
                }}
              />
            </Flex>

            <Box p={6}>
              <Stack spacing={0} align={"center"} mb={5}>
                <Heading fontSize={"2xl"} fontWeight={500} fontFamily={"body"}>
                  Designer Thirt
                </Heading>
                <Text color={"gray.500"}> MetaversePro Designer 13 </Text>
              </Stack>
              <Stack direction={"row"} justify={"center"} spacing={6}>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    All-time XP
                  </Text>
                </Stack>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Seasonal XP
                  </Text>
                </Stack>
              </Stack>
              <Button
                w={"full"}
                mt={8}
                bg={useColorModeValue("#001975", "gray.900")}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
              >
                Web3 Handshake
              </Button>
            </Box>
          </Box>
        </Center>

        <Center py={6}>
          <Box
            p={6}
            maxW={"270px"}
            w={"full"}
            bg={useColorModeValue("silver", "gray.800")}
            boxShadow={"2xl"}
            rounded={"md"}
            overflow={"hidden"}
          >
            <Image
              h={"80px"}
              w={"full"}
              src={
                " https://bafybeigg7gtgk7pdzpgazmb5bgqcewosazv7v73t6gcyj7g52xp7bfkcky"
              }
              objectFit={"cover"}
            />
            <Flex justify={"center"} mt={-6}>
              <Avatar
                size={"xl"}
                src={
                  " https://bafybeihb465w6sxhpiqntihkpe5x24kkdcxvjvonlipgnvzhar6zqapefq/ "
                }
                alt={" MetaversePro "}
                css={{
                  border: "2px solid white",
                }}
              />
            </Flex>

            <Box p={6}>
              <Stack spacing={0} align={"center"} mb={5}>
                <Heading fontSize={"2xl"} fontWeight={500} fontFamily={"body"}>
                  Designer Fourt
                </Heading>
                <Text color={"gray.500"}> MetaversePro Designer 14 </Text>
              </Stack>
              <Stack direction={"row"} justify={"center"} spacing={6}>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    All-time XP
                  </Text>
                </Stack>
                <Stack spacing={0} align={"center"}>
                  <Text fontWeight={600}> 777 </Text>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    Seasonal XP
                  </Text>
                </Stack>
              </Stack>
              <Button
                w={"full"}
                mt={8}
                bg={useColorModeValue("#001975", "gray.900")}
                color={"white"}
                rounded={"md"}
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
              >
                Web3 Handshake
              </Button>
            </Box>
          </Box>
        </Center>

      </HStack>
    </div>
  );
}
export default CardContact1;
