import React from "react";
import { Box, Image, SimpleGrid, Badge, Link } from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons'
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";
// import { ImStarFull } from "react-icons/im";
function GridLayout6() {




































































































































const token100016 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmXzcvA6zKYF5bSeAQNJSbgnuD6wyg8PP6tbyLwwKmpFvq ",
imageAlt: " Garment Texture 100016 ",
skills: 1,
guilds: 1,
title: "ABigNeonGlitter",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100017 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQRToAfrwEbbGFMNr2FJpCnwgjbLTvPEaNBHcyyHfQGaG ",
imageAlt: " Garment Texture 100017 ",
skills: 1,
guilds: 1,
title: "Fabeeo Breen",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100018 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmf3Pth951Tji8oJX75N8dKvsQkEoTQx4LLcR7nrzwrJGS ",
imageAlt: " Garment Texture 100018 ",
skills: 1,
guilds: 1,
title: "porka_X",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};






















const token100021 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmYV3bPu5X5EonKhKNocSkyjAFWjjGwfwbCyuuhD7LcRh9 ",
imageAlt: " Garment Texture 100021 ",
skills: 1,
guilds: 1,
title: "JESSICA JAMES",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100022 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZG36k36r1NFStkMfpZnkdMmbxjsZE414CrhtEZuWxZ5y ",
imageAlt: " Garment Texture 100022 ",
skills: 1,
guilds: 1,
title: "Jasmine Beane",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100023 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmShUk6iHG9C3zNH2dFKL9m2bGNzC38k2oU8A2dWz1MsFA ",
imageAlt: " Garment Texture 100023 ",
skills: 1,
guilds: 1,
title: "Aisha",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100025 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWHDYne32wn7CKDGBX7B9g659pYisLvxPEjmRF4FzBryE ",
imageAlt: " Garment Texture 100025 ",
skills: 1,
guilds: 1,
title: "Majestic Apparell",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100026 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmeB3uFtwemr6piAe5AguNBr3DFSqDrPyWVr8wu1UhSPDk ",
imageAlt: " Garment Texture 100026 ",
skills: 1,
guilds: 1,
title: "Majestic Apparell",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100027 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmX5Ti4zYg4F4YiaGekkkQJjET43sh4W6omvHEUt7hdh7W ",
imageAlt: " Garment Texture 100027 ",
skills: 1,
guilds: 1,
title: "Stella Achenbach",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100029 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTus31onDLVG1nyyhZEXYQQy6STJ9wYuLeUesWBUpVhWm ",
imageAlt: " Garment Texture 100029 ",
skills: 1,
guilds: 1,
title: "Maria Pulido TISL",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100030 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTua8DxWBU74nkKrGyeeNgSpfXEBn74jGaTywLVSFeEvx ",
imageAlt: " Garment Texture 100030 ",
skills: 1,
guilds: 1,
title: "A R E Z",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100031 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQXZvNkp2bsazHXQ53fNyWjQSE47B9wftjh8mC9VjiyRr ",
imageAlt: " Garment Texture 100031 ",
skills: 1,
guilds: 1,
title: "A R E Z",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100032 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmRdC22e1ntxRd33FXCZaoVAUn1JidCaJnqVaknsio5FWU ",
imageAlt: " Garment Texture 100032 ",
skills: 1,
guilds: 1,
title: "Stan Mclygin",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100033 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUZfBZUWJnJZ7J65pVRR4o1ZphDCX6BS7GTHjZoccsdWy ",
imageAlt: " Garment Texture 100033 ",
skills: 1,
guilds: 1,
title: "Stan Mclygin",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100034 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQxPyzEyqCNTKJdevNXBe8kR2GS8aiyKiJd2MF2Dxeh3v ",
imageAlt: " Garment Texture 100034 ",
skills: 1,
guilds: 1,
title: "ABigNeonGlitter",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};

































const token100038 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmX3zp65nw32zeTinc7mA3oKrPSECKQn5TeZwC2UZ36e1Z ",
imageAlt: " Garment Texture 100038 ",
skills: 1,
guilds: 1,
title: "Marcel",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100039 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmdVZtunXdtXzMR7GN2iBzxFUxmZEvzqxRoE2uqtmGDgmD ",
imageAlt: " Garment Texture 100039 ",
skills: 1,
guilds: 1,
title: "Antonio Lopez",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100041 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZmVzJU82vHB2djYeXgLdLcxJx8WpZqTwu5eR7disoV1U ",
imageAlt: " Garment Texture 100041 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100042 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmdHVHN7RqjqdoyN9nndfeFV6ozLo56KXKNodhoXpg2GW3 ",
imageAlt: " Garment Texture 100042 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100043 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZUbzZxJD1RzgPBFd5ckymwqye6YST96DWPwotcBXKEyE ",
imageAlt: " Garment Texture 100043 ",
skills: 1,
guilds: 1,
title: "Rozaviy",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100044 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZNi54zGyUtGK6aDAeYZhfo16i7xoHviWacghEKusX4nt ",
imageAlt: " Garment Texture 100044 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100048 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmcVKMzQ6NnaqwpLNLZNRJEoq6mT5uoRKDht6xHp3AReya ",
imageAlt: " Garment Texture 100048 ",
skills: 1,
guilds: 1,
title: "Paola Olaguivel",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100049 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmf6T5KTEkBHh439jGjKYNb8KFVZKb21PVDStdjFTUt1iH ",
imageAlt: " Garment Texture 100049 ",
skills: 1,
guilds: 1,
title: "Mutani.io",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100050 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQK5B2QM2uxyui9sFG1idLx7rKWd6yTnrqDFJqkfigGzG ",
imageAlt: " Garment Texture 100050 ",
skills: 1,
guilds: 1,
title: "Mutani.io",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100051 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmcpSPXvAu3ca4AVerLtCPQtqZYpmpnmrYUr3B3HhB3MXh ",
imageAlt: " Garment Texture 100051 ",
skills: 1,
guilds: 1,
title: "Mutani.io",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100052 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZxAckLBXzo2Kf1LYm8D99oHTzF83xtCRbN562dsC6mFZ ",
imageAlt: " Garment Texture 100052 ",
skills: 1,
guilds: 1,
title: "Mutani.io",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100053 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTrR5zB8kE6Be6sMQFHMqwh4PUrtYmMCP6RjQcmJRj9eR ",
imageAlt: " Garment Texture 100053 ",
skills: 1,
guilds: 1,
title: "Mutani.io",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100054 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmXUSQURq7MZF54c2EYvX57TzKWmdf4q1xBMHxHThh9DBU ",
imageAlt: " Garment Texture 100054 ",
skills: 1,
guilds: 1,
title: "Mutani.io",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100055 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmYAY1YS26r8VnTEzmVnBFwQjU3TWm7YL4T4FSEKYjf76d ",
imageAlt: " Garment Texture 100055 ",
skills: 1,
guilds: 1,
title: "Mutani.io",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100056 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWSxqh1fCZ9EGwBcrwBBbZ6dwAeWYSRC9kkBPhumk1eYC ",
imageAlt: " Garment Texture 100056 ",
skills: 1,
guilds: 1,
title: "Paola Olaguivel",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100057 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmc97vjkJZZtmcmzaBAKXYyczHGHNS73DtqNnGWQ7z7DG1 ",
imageAlt: " Garment Texture 100057 ",
skills: 1,
guilds: 1,
title: "Ycesh",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100058 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWQeZYNCVTahVUAqnJ2zDoPGHXxj9DUKNPDuSdQ41aQ7K ",
imageAlt: " Garment Texture 100058 ",
skills: 1,
guilds: 1,
title: "ABigNeonGlitter",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100059 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUYzHEY6Hwg4DShZP3N1EeNAkD9dM14VyMhGYq8jZRyU6 ",
imageAlt: " Garment Texture 100059 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100060 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmeH5NNv7NiyN4myW5yCebPN6ua4i1Vjn9qyV8QFQ7Wmtu ",
imageAlt: " Garment Texture 100060 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100061 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUZk5uz5cpFSEyd2Jqe9ZBTzfCdWvKHFDnyASgJ2aXiDs ",
imageAlt: " Garment Texture 100061 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100062 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmdKKjcvFmYPCtef2svLqFBSjoa1HY2773iPWc8rohpbQt ",
imageAlt: " Garment Texture 100062 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100063 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmaipvHF15QT84p9syNyjDhVpif32wP7pwrLTgwKLRZGTA ",
imageAlt: " Garment Texture 100063 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100064 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmcctq9i5SN1t4mYGdTH8YRjC6uurDoPEZMhTbSfMj527N ",
imageAlt: " Garment Texture 100064 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100065 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSrzgCnPu2ahcF56mYfKSJMsqm5QCzWgvoL8WpMFJkDXr ",
imageAlt: " Garment Texture 100065 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100066 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSfSWFcrdHxCGtJHvRcWBeVZP5PJiKqF39CL3EambiQQA ",
imageAlt: " Garment Texture 100066 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100067 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZ8wTNcB2RQdKvC4nErjXupd7AAYo8UTEwnodvWkF3jYV ",
imageAlt: " Garment Texture 100067 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100068 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWQ9HwtKrfHzQG1uUxMoTDUA9JNLQDHPFzcfZgxeLas5a ",
imageAlt: " Garment Texture 100068 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100069 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmRmsmXb7PRzKwvtujL8xQfAgXEJZm5rUHZGmu4UmtwSn5 ",
imageAlt: " Garment Texture 100069 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100070 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmcfHJxBj6XMbrEqNYf7Dd48PTSMqWMejYRkitnecq9dyZ ",
imageAlt: " Garment Texture 100070 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100071 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSdEo5NQELK6wzty5mUu3nahaX5bzKyPS1fUE9EJtn7WV ",
imageAlt: " Garment Texture 100071 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100072 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmbNLFAzZYEHNSziTrskqa4itAR9aLTEBU5EsHZ239M4E2 ",
imageAlt: " Garment Texture 100072 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100073 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQRwXGFZXKHaXL7C3xnX8bXWLHuhPCQx4zfoPpgX1NKjb ",
imageAlt: " Garment Texture 100073 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100074 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVbGwzPcyaXbtvNhbGu9xGzUu3SuXkFMDhxnTW5qqNeKe ",
imageAlt: " Garment Texture 100074 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100075 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPY5dQ346MijqusDXRvRZxcB8foYBvy4bqRruWBWFGGMj ",
imageAlt: " Garment Texture 100075 ",
skills: 1,
guilds: 1,
title: "pr.esc",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100076 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSHkqHbNi9yckbAEMRky4LmTHw7jRYw9WDHHabAr8NWrJ ",
imageAlt: " Garment Texture 100076 ",
skills: 1,
guilds: 1,
title: "pr.esc",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100077 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmS5Jg4o9CLgGtGZitu5uQye7MfvrR1nyJMEJkzjUypN3Z ",
imageAlt: " Garment Texture 100077 ",
skills: 1,
guilds: 1,
title: "pr.esc",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100078 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmbW3Xjdfx1jgSGE35Gh1fPKft29Dw5VFYPmanBtyXZnDr ",
imageAlt: " Garment Texture 100078 ",
skills: 1,
guilds: 1,
title: "pr.esc",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};












































const token100083 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmaARqM2byqxBS3XBqGzkgYJcRSwsaLaAWQoHiavoAMhBP ",
imageAlt: " Garment Texture 100083 ",
skills: 1,
guilds: 1,
title: "Mo'Casso",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100085 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmY8NgmDkYDbyGYFPZuUgKNyjxxhNZ3qQ9Bue6VbxSrMb3 ",
imageAlt: " Garment Texture 100085 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100086 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmS3HygSeBvSWE722sxUCVtaT6D8bHQBCWCaU65QxArwYW ",
imageAlt: " Garment Texture 100086 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100087 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVRGFbSKqEm8vPsuxrncDjvso1cmUrGAdWUHWdxGX4aSK ",
imageAlt: " Garment Texture 100087 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100088 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmdAkRwiBqhsTGyK3EB8YcCLUrwWYxdXrN2WzUGPe2GKau ",
imageAlt: " Garment Texture 100088 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100089 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmNb1AEPBqQd63okpcrnn6nseduQdPXxZkue8joLv52L8t ",
imageAlt: " Garment Texture 100089 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100090 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmbNuTrDG51CAq2sByEYoQpX5LBrMy1MWeikDCUcDNRLN4 ",
imageAlt: " Garment Texture 100090 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100091 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVEoZxT9KajRw4JQ1c7fNveEJdGSUqf7YAmi7URQo6E91 ",
imageAlt: " Garment Texture 100091 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100092 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQjo33MpnBi7maToftCEqpq1nRxqrZvLRCeUz7ty5LEVn ",
imageAlt: " Garment Texture 100092 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100093 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQgftFAPXkmoLPNJHBoTsJbkDZSs5mt8aygoWBwkwEjKi ",
imageAlt: " Garment Texture 100093 ",
skills: 1,
guilds: 1,
title: "Marcel",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100094 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmY723GdbVrqKLmAuZvwat5BsQFv6WD89a9NqUEYFaFyNJ ",
imageAlt: " Garment Texture 100094 ",
skills: 1,
guilds: 1,
title: "LORENA BELLO",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};






















const token100097 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qme4FFScaiPihuYrP7NB3hzrShkshF2WeVXhYQDvoVw59d ",
imageAlt: " Garment Texture 100097 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100098 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZ5GVx8F4awXf4adMQVQmaLWAi1WZbph6qVmj3fpoDh7T ",
imageAlt: " Garment Texture 100098 ",
skills: 1,
guilds: 1,
title: "LORENA BELLO ",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100099 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQWAnn1x1Y3n1uiQnRA7VWJbECdx1ktDZxJJBfC4PNd29 ",
imageAlt: " Garment Texture 100099 ",
skills: 1,
guilds: 1,
title: "Cryptsie",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100100 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmNPAiw3tYkFfYBPWJP1s1uyFdnd7Lb1bPF7PZezN8jLFB ",
imageAlt: " Garment Texture 100100 ",
skills: 1,
guilds: 1,
title: "Cryptsie",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100101 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmd2RtnVx2qTL43KUkTrWxjNdbcH3TQDeBwMLx8STF56Zh ",
imageAlt: " Garment Texture 100101 ",
skills: 1,
guilds: 1,
title: "Cryptsie",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100102 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmcs1W3SLsocsRTsrA6vcvPQoQ7UvncHWng8bNdkQiKUMt ",
imageAlt: " Garment Texture 100102 ",
skills: 1,
guilds: 1,
title: "Stella Achenbach",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100103 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmeYep5bfwTritimjqtcc8YbTC3kt8mUN8psezgZwL9iwp ",
imageAlt: " Garment Texture 100103 ",
skills: 1,
guilds: 1,
title: "Stella Achenbach",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100104 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZQVTBRdmJtpCXvjHtNhz8ToXNWtWKtMtFrUhxT1ZJPFH ",
imageAlt: " Garment Texture 100104 ",
skills: 1,
guilds: 1,
title: "Stella Achenbach",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100105 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmU6NrZXFpzsFejWpw69AH8e7egVNMLcVTsCVJ9uu4WFTs ",
imageAlt: " Garment Texture 100105 ",
skills: 1,
guilds: 1,
title: "Cryptsie",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100106 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmURbsps33WxpuTCKr5CqNr1rhgzfVy8Tb2pjQHFcKVokz ",
imageAlt: " Garment Texture 100106 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100107 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWBoQnaxtqg24k4Age1FxsYjGxFbvY6AwMLRtYSwtQa9c ",
imageAlt: " Garment Texture 100107 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100108 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmREZRkEEw49e3UnTMtfjNMHzw6zE7Sh7dV7zbw2zMTT7p ",
imageAlt: " Garment Texture 100108 ",
skills: 1,
guilds: 1,
title: "Vicke Jones",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100109 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmXDENZdeK5AspUGmuvZV3Cb9H9rwScuaB51P72aUe3Bxe ",
imageAlt: " Garment Texture 100109 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100110 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qme3sWquSpVC3KcqGVj7FvRcXUHe7DuBMyQVmpTBzx5z8V ",
imageAlt: " Garment Texture 100110 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100111 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUmsmjiJ8hBGtz4GH1qUhKpLk3J6otDspD2zmSRtcYHgR ",
imageAlt: " Garment Texture 100111 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100112 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmViMsDP2birKWphzY7xeUwXftGKLkfhAjTVkTUUVfanf7 ",
imageAlt: " Garment Texture 100112 ",
skills: 1,
guilds: 1,
title: "Vicke Jones",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100113 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmW2o4KdiEuGKi4TDwvRL3BGqfW3muDFRUmKYFHJS9RakS ",
imageAlt: " Garment Texture 100113 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100114 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmcGD2VDCdnDspX2ZG98rNZAFmz1WNxRYLgcq1cN3Q6A1c ",
imageAlt: " Garment Texture 100114 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100115 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmRP761i6Uum55fcEAegckYcRyFBHtXn7WKw1cxG5PB33z ",
imageAlt: " Garment Texture 100115 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100116 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWAYtVvDMKFxqwfA9M2t6dVdX8n58Kn3eMaSYA8WpUJTn ",
imageAlt: " Garment Texture 100116 ",
skills: 1,
guilds: 1,
title: "Vicke Jones",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100117 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmcqxLieAQTVCHVjuKNTpz8UbbB4xvHwF3Q6oD1B8UgNxC ",
imageAlt: " Garment Texture 100117 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100118 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmV3mGEMH1YT8X2eKXw8jPdDJYUjSJDGj5jKrX9UerLywt ",
imageAlt: " Garment Texture 100118 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100119 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmbXeVLQg9YBMCMBJjdPrw7LoCqXdfR2ZeBW6QcMjYk3hN ",
imageAlt: " Garment Texture 100119 ",
skills: 1,
guilds: 1,
title: "Vicke Jones",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100120 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmdU8U15XD7hCLrGuU7ubmVsJuUeLU39aPyXhd97SG2gTb ",
imageAlt: " Garment Texture 100120 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100121 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmXw6bFM8pzb7wRBLkq326iTgWU5Deh7qRTdatGxks2DQj ",
imageAlt: " Garment Texture 100121 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100122 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qme33RMw37hqXKkgpgxufh7iCULGL8XQde2oMURgMeDkbb ",
imageAlt: " Garment Texture 100122 ",
skills: 1,
guilds: 1,
title: "Vicke Jones",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100123 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmReC1YNLA93K86wFwYg839aqP75oYh4eaPHd22wyoR6RX ",
imageAlt: " Garment Texture 100123 ",
skills: 1,
guilds: 1,
title: "Vicke Jones",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100125 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUhf5AreuxaUYHb3LwgadZF1zq7RsggxcDoMXo5ALjnhK ",
imageAlt: " Garment Texture 100125 ",
skills: 1,
guilds: 1,
title: "Vicke Jones",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};






















const token100128 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmW5kqS9NGAwcvuQFVPvW1s6MWZPqY61LB5cVnhbUUaKW2 ",
imageAlt: " Garment Texture 100128 ",
skills: 1,
guilds: 1,
title: "Vicke Jones",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100129 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQdEtd6FT2uhq79FfeU8yfdoD21htUsY6PMkZcnSzE7Xt ",
imageAlt: " Garment Texture 100129 ",
skills: 1,
guilds: 1,
title: "Cryptsie",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100130 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZZKezF4zAA6huTFnsGFRLUVxx7iRGMoPgCN5zpAKiXFX ",
imageAlt: " Garment Texture 100130 ",
skills: 1,
guilds: 1,
title: "Vicke Jones",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};






















const token100133 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmbZ9cCKHhwYWjjRgzgCkGWj5XDW68ttwXCwLUdXWuanA1 ",
imageAlt: " Garment Texture 100133 ",
skills: 1,
guilds: 1,
title: "Domingo Bette Encour",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100135 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmNcMyPj3WemY7pZuGw1LREhRiKsZui7ksjqVDQtb5Gk33 ",
imageAlt: " Garment Texture 100135 ",
skills: 1,
guilds: 1,
title: "Paola Olaguivel",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100136 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmaZwC68mbK8rdmhQkGUcQtf9yUsdJ8Twk7BadRNyPjemY ",
imageAlt: " Garment Texture 100136 ",
skills: 1,
guilds: 1,
title: "Paola Olaguivel",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100137 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmcn7xKF2xAwk6Uez5GPpc7Y6ASYLsd3evAWVsYjMMzTVe ",
imageAlt: " Garment Texture 100137 ",
skills: 1,
guilds: 1,
title: "Mar Guixa Studio",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100138 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUybhXGdcd7FmrjyvHtqoNPcyTk8YxZb5WA8Md2Vin2Uu ",
imageAlt: " Garment Texture 100138 ",
skills: 1,
guilds: 1,
title: "Keaton",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100139 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmNi7474bka4F2kdk2S5EtFx8Qtvbu7xQheucr66hKV6ku ",
imageAlt: " Garment Texture 100139 ",
skills: 1,
guilds: 1,
title: "Keaton",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100140 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZhS3eEwEgVhT86dFWom9wTNK68uRwfP3V4M3K4Sp47A1 ",
imageAlt: " Garment Texture 100140 ",
skills: 1,
guilds: 1,
title: "Domingo Bette Encour",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100141 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmYCFdqj6MtB3nVN4JJAQERejVuUDASbtqhnxmdXhEHBTA ",
imageAlt: " Garment Texture 100141 ",
skills: 1,
guilds: 1,
title: "Alyona Briukhanova",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100142 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmc38yj3X28L83tEGyR2XvkuQAy43UiERNJXhbdBiKqzuF ",
imageAlt: " Garment Texture 100142 ",
skills: 1,
guilds: 1,
title: "_slow_crypto",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100143 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTYMUiGHRF5kPj67PFMi5Y616tNYSid6wFtTwNdLamgwx ",
imageAlt: " Garment Texture 100143 ",
skills: 1,
guilds: 1,
title: "_slow_crypto",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100144 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmRmzecBDTGQCnzK8MV3A26CZuDfSdJCcKMb65j7xUTH24 ",
imageAlt: " Garment Texture 100144 ",
skills: 1,
guilds: 1,
title: "_slow_crypto",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100145 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmYuH9Rqd3FczHpkwRe1mNQXs6xfEAEidoY7wsx4oxXyvk ",
imageAlt: " Garment Texture 100145 ",
skills: 1,
guilds: 1,
title: "_slow_crypto",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100146 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZ9KpRrDVNK9FTa6yAKWm5QaNkX4jrE3nEWcso4pQXVDQ ",
imageAlt: " Garment Texture 100146 ",
skills: 1,
guilds: 1,
title: "Domingo Bette Encour",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100147 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQoK2m2oHMM3Sk9UaMG1KmvAE5rK85P5NAk5fzEoNx1P2 ",
imageAlt: " Garment Texture 100147 ",
skills: 1,
guilds: 1,
title: "Keaton",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100148 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmNZtaD5A5D7t1SVXYAmrPsbnj4Q7Sdh2Pigfuo3oHwGJ1 ",
imageAlt: " Garment Texture 100148 ",
skills: 1,
guilds: 1,
title: "ABigNeonGlitter",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100149 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZtYx4hwKuYWHYhubShj1J6yqntfQtQbqFkQjSZQNxFUy ",
imageAlt: " Garment Texture 100149 ",
skills: 1,
guilds: 1,
title: "Ycesh",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100150 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmdfCpY2oUAxkrhxaU5XgYnftmiszNo2BAXt5rTHjrLJmy ",
imageAlt: " Garment Texture 100150 ",
skills: 1,
guilds: 1,
title: "Aisha",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100151 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qma2PuWPvE7NZZWkt3QJ8dfgUVAgafKgBsRrxZXRK2m6Ky ",
imageAlt: " Garment Texture 100151 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100152 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmeRc8fgNvagZda4og3o6htbhg9wgu8deSB9ak7EkRbvT6 ",
imageAlt: " Garment Texture 100152 ",
skills: 1,
guilds: 1,
title: "KALAU",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100153 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmfWLoYe48xDTpZ2WdUjrqiv6t1Crkj7vASAYnmQ53kbQw ",
imageAlt: " Garment Texture 100153 ",
skills: 1,
guilds: 1,
title: "KALAU",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100154 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmbghBywALQ6xqvtf4orGzV4ZBvn7bjbVVNb1sP6Y7XL6p ",
imageAlt: " Garment Texture 100154 ",
skills: 1,
guilds: 1,
title: "KALAU",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100155 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmULrhfNDp2fnVPEtcptaQeBdpW1n5qRSjR6kSt8GhHrXm ",
imageAlt: " Garment Texture 100155 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100156 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmNVYr25V8xzTYPG1czLB7H8sTeMiDMzuma9C4UdbuMa8k ",
imageAlt: " Garment Texture 100156 ",
skills: 1,
guilds: 1,
title: "1hary1",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100157 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmbnq3399M7NR8AZ95Z6FuNzoaBuCowcgrAb6WUZYPKdQQ ",
imageAlt: " Garment Texture 100157 ",
skills: 1,
guilds: 1,
title: "1hary1",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100158 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmf3FhHY5qpqj9pmvqfYBPzRusS9LnBPX2iE5hwwY5gRQu ",
imageAlt: " Garment Texture 100158 ",
skills: 1,
guilds: 1,
title: "KALAU",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100159 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmdLvM8XjngyDb1PDtcabx2pjnXBDAXKWSTfdQgpbBFqSU ",
imageAlt: " Garment Texture 100159 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100160 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWLVYRFvSmnvCRskyDJLTc8xmiMKJkZe5H5Vu7CPeL25Q ",
imageAlt: " Garment Texture 100160 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100161 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmST72JwHiadYw2HKQHEqwkgtLedKCppDeBCJhFJ4pmRwm ",
imageAlt: " Garment Texture 100161 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100162 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmaQtx5QqgQeJGmKHLoJbGiFXqiMdGawzkA15T2MD7tSJF ",
imageAlt: " Garment Texture 100162 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100163 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmY1PXTxkfNfVs4u7p8yGYzq1jCFPcrsYCpA1aWYG2nWsU ",
imageAlt: " Garment Texture 100163 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100164 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTHzsnxSUBb8oXsihEC2pTDuaDYvvt2fZVFUe6HRkmACC ",
imageAlt: " Garment Texture 100164 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100165 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWqQMCjSe3BhhbbYbxgN38QuJHJ368Epq15r2Rvj1a1WV ",
imageAlt: " Garment Texture 100165 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100166 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmNTUYLBFT7m4FDSaGJJQoiSSYtz8pUaZkFXh5rcdpbBnv ",
imageAlt: " Garment Texture 100166 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100167 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQGNTe5dViXpK1hxnWawR7do2GHMJHff7YReCi3MoNZhZ ",
imageAlt: " Garment Texture 100167 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100168 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmfTmiCfMKMYqyvdKBxt4Exbe8zfVhL2j4eAbhxbWfMovU ",
imageAlt: " Garment Texture 100168 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100169 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmeMmMMTAevVGx3v5o3yGQof2N5S3YEXjGXc2xJptz5tM8 ",
imageAlt: " Garment Texture 100169 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100170 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmRrA6TmAgoZrBKe9C655xnWov79qWLbaxrzmxkKKqESQA ",
imageAlt: " Garment Texture 100170 ",
skills: 1,
guilds: 1,
title: "PATTERNBASE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100171 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUgDsx57d1weRVbyfQChk8T7bckTMwHU59ASNS44yPPFb ",
imageAlt: " Garment Texture 100171 ",
skills: 1,
guilds: 1,
title: "Anthony Ibegbulem",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100172 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmeZciFZrRtJiW1717oQdFRekekoamgCMvGQKSo6VufyU2 ",
imageAlt: " Garment Texture 100172 ",
skills: 1,
guilds: 1,
title: "Anthony Ibegbulem",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};






















const token100175 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmbgtHjH5RWJofzexdW7vWjBf8nBFoM3qhetBsoXtcAohm ",
imageAlt: " Garment Texture 100175 ",
skills: 1,
guilds: 1,
title: "McMistrzYT",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100176 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWv8xDFcNAAr1cV9XCL1GfhBZTZMjW5neY15CWqTFmfot ",
imageAlt: " Garment Texture 100176 ",
skills: 1,
guilds: 1,
title: "Ica & Kostika",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100177 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmbgFvX1ojaS1dCBoGTUBh9Mdkgew5eYT1TpyYFNNnX4Cd ",
imageAlt: " Garment Texture 100177 ",
skills: 1,
guilds: 1,
title: "Ica & Kostika",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100178 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQnr6UbaxRo6NYWxN2RThJYrmR2MP15J5Ny9cvJsTvDpy ",
imageAlt: " Garment Texture 100178 ",
skills: 1,
guilds: 1,
title: "Ica & Kostika",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100179 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmNtt4V64hdPTjNgZ3CMEFK6zUpsovGkV7RfbJNRYm7qbo ",
imageAlt: " Garment Texture 100179 ",
skills: 1,
guilds: 1,
title: "Ica & Kostika",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100180 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmcXikSzx43BBNjCVQKxWeZFiauxmBhQ5wY9pXQPDQeSMJ ",
imageAlt: " Garment Texture 100180 ",
skills: 1,
guilds: 1,
title: "Ica & Kostika",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100181 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQkXBWRZyqq7GK1Eey3wDdjFkqfVWTUn9pvkE58fH5zpb ",
imageAlt: " Garment Texture 100181 ",
skills: 1,
guilds: 1,
title: "Ica & Kostika",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100182 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQSFXJkyfCC2eaeFqD5dmqXv5HnbLuYhyfNEmmcV8ZQJ2 ",
imageAlt: " Garment Texture 100182 ",
skills: 1,
guilds: 1,
title: "Ica & Kostika",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100183 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVCvq1YEavXxgMwR9Wn579ndRJ2msvdB8oovs54CHVZFt ",
imageAlt: " Garment Texture 100183 ",
skills: 1,
guilds: 1,
title: "kima_jak3d",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100184 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSi85RsC5Qh6z3y4sChgd9xpgiiSkmHo5TndRD7Axqhqb ",
imageAlt: " Garment Texture 100184 ",
skills: 1,
guilds: 1,
title: "Blade Runway",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100185 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPBrqUCxAu22yLxRzRfu3JnKYL2Yi53vXDEGdWMws3LE5 ",
imageAlt: " Garment Texture 100185 ",
skills: 1,
guilds: 1,
title: "Ica & Kostika",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100186 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmb4pT2YvqPZSCAJ9CZ6CcjhXwUB7ndmSW4jF3YxFLaXek ",
imageAlt: " Garment Texture 100186 ",
skills: 1,
guilds: 1,
title: "Ica & Kostika",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100187 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPr6sfnn2K5mq1b6e27LynZxd41AZzPcYL9ov4ekbA4VX ",
imageAlt: " Garment Texture 100187 ",
skills: 1,
guilds: 1,
title: "Ica & Kostika",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100188 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmdnKLFU822eRn44f3bbBZkSjRdgESDaLDR2fkk6d6Ydpw ",
imageAlt: " Garment Texture 100188 ",
skills: 1,
guilds: 1,
title: "Lorena Bello ",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};

































const token100192 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmRaFas9rqBy1aHtMmHGQ6n8dVUxGPpxkkpmaL9KsYk8Nf ",
imageAlt: " Garment Texture 100192 ",
skills: 1,
guilds: 1,
title: "Yekaterina Burmatnova",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100193 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmV66JPtf3rHGKHc7Y82cGN2HeqHWsyxZmA6XGTz1mEAtK ",
imageAlt: " Garment Texture 100193 ",
skills: 1,
guilds: 1,
title: "Empyror",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100194 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmRbHGE68G8iPTRqQ4N6LjcGFFdp6Zgziii3ePXNUM6aHF ",
imageAlt: " Garment Texture 100194 ",
skills: 1,
guilds: 1,
title: "HADEEART",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100195 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSVfZANocRWvpxgAgxKCdGLiG3D5wkUrRYajeLkd6xdKL ",
imageAlt: " Garment Texture 100195 ",
skills: 1,
guilds: 1,
title: "puppet",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100196 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmbhKdySHUXD1oC4RGAMtTF1c5oZ156oKEoZyQ3emEmGBr ",
imageAlt: " Garment Texture 100196 ",
skills: 1,
guilds: 1,
title: "Sourav Agarwala ",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};












































const token100201 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQb2ftusTMZhYnjtBGVdR82VTvK76N65AgkMCUUdvPQb3 ",
imageAlt: " Garment Texture 100201 ",
skills: 1,
guilds: 1,
title: "AVA3d",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100202 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSeBpfhrxQEeBaucg5MK5XKbCBpctm5XGUcneGm1nHR8p ",
imageAlt: " Garment Texture 100202 ",
skills: 1,
guilds: 1,
title: "AVA3d",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100203 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmcEAvKpvw1wnhp8sVdKeKqb76qNDo5eKZkW8gcxXS62ET ",
imageAlt: " Garment Texture 100203 ",
skills: 1,
guilds: 1,
title: "AVA3d",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};






















const token100206 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmR8Eg5W1pzmDSQqMZG1SnNkPWsRuu94oq2D7omncvg5BY ",
imageAlt: " Garment Texture 100206 ",
skills: 1,
guilds: 1,
title: "AVA3d",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100207 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmc3H6C73RieZMfKSA8j913HZHYsoWUHQVp2RSrP7qSVBW ",
imageAlt: " Garment Texture 100207 ",
skills: 1,
guilds: 1,
title: "Myse Cunha",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100208 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZQv1cyjRgAHXhxRr2wrHsfXyif2aGvS9UrJvuLRNJ5r6 ",
imageAlt: " Garment Texture 100208 ",
skills: 1,
guilds: 1,
title: "Myse Cunha",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100209 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmauVj2vQ485FJck2QDLXbyJerdFsCpY3LDvy1Bp62WxW6 ",
imageAlt: " Garment Texture 100209 ",
skills: 1,
guilds: 1,
title: "Msistema",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100210 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmNSDaAoSQXFLZ8ASjGaBh93vnVhYmDneg2mh8RkfxpuYn ",
imageAlt: " Garment Texture 100210 ",
skills: 1,
guilds: 1,
title: "TAYLORVADE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100211 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmY4no8tKKZ3ud5CXdbCVSLMyaoG6LDpPhGdxz3Fb2FLBc ",
imageAlt: " Garment Texture 100211 ",
skills: 1,
guilds: 1,
title: "Meta Mike",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100212 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmbLRxDvLGo9x8PdC68PPcGqUJ4UqfvVj2pLhRQKZyPwBM ",
imageAlt: " Garment Texture 100212 ",
skills: 1,
guilds: 1,
title: "Meta Mike",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100213 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmbjpq3fVzTAd5T9mHBNJXegVfcjjbDDP6nLw3h81uZ3sr ",
imageAlt: " Garment Texture 100213 ",
skills: 1,
guilds: 1,
title: "Meta Mike",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100214 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTVhfjssKFMeCTZFXdFdc1McoQ9sb3DaBvS6g3paHHgj1 ",
imageAlt: " Garment Texture 100214 ",
skills: 1,
guilds: 1,
title: "Meta Mike",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100215 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPCy2nJNxNaL7DdqCjfP3gX3q989UUvUrRz7vQx5iaLQ3 ",
imageAlt: " Garment Texture 100215 ",
skills: 1,
guilds: 1,
title: "Meta Mike",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100216 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmc7a9ke3TFzS3qRfirALLpbUoaRpVgV9dtqBtvpbBf4Xo ",
imageAlt: " Garment Texture 100216 ",
skills: 1,
guilds: 1,
title: "Meta Mike",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100217 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmdi3fbCQdVu12mAycwj5ZpJEwiT2y9UqPaNrTnT9PDm7r ",
imageAlt: " Garment Texture 100217 ",
skills: 1,
guilds: 1,
title: "Meta Mike",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100218 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmcb2kpNfWGg7FVHHGAUmmWT1mhFGhSjDz7pBbF9pvXwgH ",
imageAlt: " Garment Texture 100218 ",
skills: 1,
guilds: 1,
title: "Meta Mike",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100219 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmcZB6E9N12B3PqZrFyvPoXi8ye7U1CdmVgcctEV9D77wH ",
imageAlt: " Garment Texture 100219 ",
skills: 1,
guilds: 1,
title: "Meta Mike",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100220 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQsZEo8VmaQKbX6B39HjB7BhY8fSvA3o5nxNuxdGBTifF ",
imageAlt: " Garment Texture 100220 ",
skills: 1,
guilds: 1,
title: "Meta Mike",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100221 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmXyz6QTeu6wRRfvDEGdQTLv9V1xGWDfkU8HmNwVfSD1UB ",
imageAlt: " Garment Texture 100221 ",
skills: 1,
guilds: 1,
title: "Meta Mike",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100222 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmNVQH2RQqo6vVgahQyVuTLwstqFgKQpnzu7URfQgvmGpM ",
imageAlt: " Garment Texture 100222 ",
skills: 1,
guilds: 1,
title: "Meta Mike",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100224 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWXzeS6se97w3iiM9ChaFmVCfRqZLErc6SpMpYuikyBP5 ",
imageAlt: " Garment Texture 100224 ",
skills: 1,
guilds: 1,
title: "diuniverse",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100225 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmRhYtqUJnP5rhf8x9KeUEXF5uci4QyTEPz6JuTvYxFpSk ",
imageAlt: " Garment Texture 100225 ",
skills: 1,
guilds: 1,
title: "Tania Viegas",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100226 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmd346kT6w1WPHirksufGWNNB7BeBSUWi29zmCHBYePFzC ",
imageAlt: " Garment Texture 100226 ",
skills: 1,
guilds: 1,
title: "Tania Viegas",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100227 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmXMBng4KkMDjcCYxozCBeCun9XHeH5MAVB7s4VyYyYAJ6 ",
imageAlt: " Garment Texture 100227 ",
skills: 1,
guilds: 1,
title: "Eretikos 2030",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100229 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSaqneCo62GTr5sBWK9oWuU73fevjD2ZXSEVzwc3rmfFe ",
imageAlt: " Garment Texture 100229 ",
skills: 1,
guilds: 1,
title: "DomingoBeta",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100230 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmcpJF1CWMUTMPAGKonTDdMFQ9sxoEygDWrsJAofAY4cJ7 ",
imageAlt: " Garment Texture 100230 ",
skills: 1,
guilds: 1,
title: "DomingoBeta",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100231 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUu5XL9Yu5bh9Kax8RnNLEPGJHyuD3Xp9kLVNsMMoa6be ",
imageAlt: " Garment Texture 100231 ",
skills: 1,
guilds: 1,
title: "ONE O ONE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100232 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmaELvvftoVmRxdgZxiwYah7DBoR9jdNtb8oYKDVZXt58x ",
imageAlt: " Garment Texture 100232 ",
skills: 1,
guilds: 1,
title: "ONE O ONE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100233 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPwrzi6kLruuovD4K1g5QLfKEdMBohc9hvmGJJXAKwPS6 ",
imageAlt: " Garment Texture 100233 ",
skills: 1,
guilds: 1,
title: "ONE O ONE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100234 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmYV3mphx3t5dqxbufKNx2nYiy9bqfBUXpkAZax6AU3aU9 ",
imageAlt: " Garment Texture 100234 ",
skills: 1,
guilds: 1,
title: "ONE O ONE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100235 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmaVzoGT9iL96RPvwqaQe838je5FB86M4kTD3itFHgJ11h ",
imageAlt: " Garment Texture 100235 ",
skills: 1,
guilds: 1,
title: "ONE O ONE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100236 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPHjNf3zDtqr26beAiBYRP5jNiq3o3hB7x2yoo2MfuGE8 ",
imageAlt: " Garment Texture 100236 ",
skills: 1,
guilds: 1,
title: "ONE O ONE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100237 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUbfnJeXue2e4hzEahZnWNFUTKScPDCzbgHwHDgpeGm24 ",
imageAlt: " Garment Texture 100237 ",
skills: 1,
guilds: 1,
title: "ONE O ONE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100238 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmRkpkr4Km2xVpbNe6HzrSH7XHPpsCgbgzZnN71LsEWuZr ",
imageAlt: " Garment Texture 100238 ",
skills: 1,
guilds: 1,
title: "ONE O ONE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100239 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZoudbiBnnSRVHmH1kAH3kyrveJwGGYnjfWeM77kXQ57b ",
imageAlt: " Garment Texture 100239 ",
skills: 1,
guilds: 1,
title: "ONE O ONE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100240 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmdjJPrJHZKjgXFKzJLvdkJUgqkzJetHHY4DaaMAoUYVvq ",
imageAlt: " Garment Texture 100240 ",
skills: 1,
guilds: 1,
title: "ONE O ONE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100241 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmd5x7qsUA4oCES5LzkXfQT12nHdbrxrr2wLpmhkSxH3BS ",
imageAlt: " Garment Texture 100241 ",
skills: 1,
guilds: 1,
title: "HADEEART",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100242 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmRkQhvacvFbftHMNiEbpdxtT2diyUXKNJCc4XrcTdDbGu ",
imageAlt: " Garment Texture 100242 ",
skills: 1,
guilds: 1,
title: "HADEEART",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100244 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUxb2osmcYFd3wh6rbr267CnUWXvuMimtCRsNyMyU3ZQX ",
imageAlt: " Garment Texture 100244 ",
skills: 1,
guilds: 1,
title: "ava3d",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100245 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmfHrrStD3bf1VZJ2vsS3YLiiPeaYtS8XastJ3u1wzss3a ",
imageAlt: " Garment Texture 100245 ",
skills: 1,
guilds: 1,
title: "ava3d",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100246 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmaUUeTnfxZhabW9cnC2nmyJGscdNxbCkARkuUusrW3HXw ",
imageAlt: " Garment Texture 100246 ",
skills: 1,
guilds: 1,
title: "ava3d",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100247 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQ3dRXwpLAaLoSMPo3ZWXmWcoWGJknPhVetPZixbGoXFB ",
imageAlt: " Garment Texture 100247 ",
skills: 1,
guilds: 1,
title: "ava3d",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100249 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmeLQAUVjZEzzTGU58DHKE3mfgtUUBqTkYLXHvpMBhw9e8 ",
imageAlt: " Garment Texture 100249 ",
skills: 1,
guilds: 1,
title: "ava3d",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100250 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUMJ4nvJR2NbbtxNzAKk11FCCKrgpMHoeKTGHDMcjuqxm ",
imageAlt: " Garment Texture 100250 ",
skills: 1,
guilds: 1,
title: "ava3d",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100251 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWrmh1jTmXuorYgjrKjTKZkfK32nGJRxHWtnGBJGc7Cop ",
imageAlt: " Garment Texture 100251 ",
skills: 1,
guilds: 1,
title: "ONEOONE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100252 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmefTYjivHqcqMNJzMLhtxTRjBxhaJL5AaN2dDq5nTDgAM ",
imageAlt: " Garment Texture 100252 ",
skills: 1,
guilds: 1,
title: "ONEOONE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100254 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPwrzi6kLruuovD4K1g5QLfKEdMBohc9hvmGJJXAKwPS6 ",
imageAlt: " Garment Texture 100254 ",
skills: 1,
guilds: 1,
title: "ONEOONE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100255 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmXthuQgFne51UsYZAe7zDrw5DtPFeA59ogpw5dPm2FeQN ",
imageAlt: " Garment Texture 100255 ",
skills: 1,
guilds: 1,
title: "ONEOONE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100256 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmcqTKCJ7qosgKdp63QDWCcPvXot1SrXCSdXhNFpqMXshQ ",
imageAlt: " Garment Texture 100256 ",
skills: 1,
guilds: 1,
title: "ONEOONE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100257 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmULf9WjEgxPrk2fYCDJpQhzSBkXMR2ymrmEBFnG4Ucimi ",
imageAlt: " Garment Texture 100257 ",
skills: 1,
guilds: 1,
title: "ONEOONE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100258 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmR57x8uBi7YUpKEYVcdcWFotiQjDnHgQ1P6Mgr5STFY5M ",
imageAlt: " Garment Texture 100258 ",
skills: 1,
guilds: 1,
title: "ONEOONE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100259 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZoudbiBnnSRVHmH1kAH3kyrveJwGGYnjfWeM77kXQ57b ",
imageAlt: " Garment Texture 100259 ",
skills: 1,
guilds: 1,
title: "ONEOONE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100260 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWUb7pCA1C9NyDNR53sRJmWCqWokYmuNiw9NkgPHSy7oF ",
imageAlt: " Garment Texture 100260 ",
skills: 1,
guilds: 1,
title: "ONEOONE",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100294 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSRSuJmCMaiS3uFJFC34ugkJJKeLYywo4zYB6XzvJ4k5b ",
imageAlt: " Garment Texture 100294 ",
skills: 1,
guilds: 1,
title: "diuniverse",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100295 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmaxqvfL5a56bi6qYKmsWKR4pSJ9LXnNhTgxKfN6tmzJ2X ",
imageAlt: " Garment Texture 100295 ",
skills: 1,
guilds: 1,
title: "Peezy",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100296 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmeD8WwKakgzAG4h9WG1niMRGTrPMe3F8eb5P7jeMToX41 ",
imageAlt: " Garment Texture 100296 ",
skills: 1,
guilds: 1,
title: "Lucii",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};














































































































const token100307 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPauhDkUMecJsk6NrRFHV8aNRuYZVUTH3aZELdkLukLJP ",
imageAlt: " Garment Texture 100307 ",
skills: 1,
guilds: 1,
title: "LUCII",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100308 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmdEUEJqfcEDrGaHv5PwecnfUpuc36RdsL1Tksv7gdhY5a ",
imageAlt: " Garment Texture 100308 ",
skills: 1,
guilds: 1,
title: "DIGITALAX",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100309 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmc5z2RigpSppAwsvZ4XkPQuZdpDsBy8hJ9EjkY3oUTYrE ",
imageAlt: " Garment Texture 100309 ",
skills: 1,
guilds: 1,
title: "Emma-Jane Mackinnon-Lee ",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100310 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmaAv7PAfbire3pEwWaAouYrurYEP1pp9Du8KcF1ePHDDK ",
imageAlt: " Garment Texture 100310 ",
skills: 1,
guilds: 1,
title: "Emma-Jane MacKinnon-Lee ",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100311 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUhuKrq1TRdLAbw8UF2vciy3aQRxu6sMzNocmhjZRTtyw ",
imageAlt: " Garment Texture 100311 ",
skills: 1,
guilds: 1,
title: "Emma-Jane MacKinnon-Lee ",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100312 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmX3QNoMozuMnSp2ZKECbpjsofdzC66oit9ueKEtWCvXBz ",
imageAlt: " Garment Texture 100312 ",
skills: 1,
guilds: 1,
title: "Emma-Jane MacKinnon-Lee ",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100313 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWvjJLRiJUBDjYXJa8mNBHvxeJXoHij18BN1ddFH8LPty ",
imageAlt: " Garment Texture 100313 ",
skills: 1,
guilds: 1,
title: "Emma-Jane MacKinnon-Lee ",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100314 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTry9D6Ctz2g1iGppxA3TwkLmA2TUPA29mS6tYHBtj9im ",
imageAlt: " Garment Texture 100314 ",
skills: 1,
guilds: 1,
title: "Emma-Jane MacKinnon-Lee ",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100315 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmRaJpg52WJgtLx9eRkj5xcA2jKhDE9ARUwzaD5EDGBoM7 ",
imageAlt: " Garment Texture 100315 ",
skills: 1,
guilds: 1,
title: "Emma-Jane MacKinnon-Lee ",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100316 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVSHeJJv4X6DcMR9iDSy3nsPCDCWqrLkgBi5jgmNS9q8i ",
imageAlt: " Garment Texture 100316 ",
skills: 1,
guilds: 1,
title: "Emma-Jane MacKinnon-Lee ",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100317 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQBnGJy4etQbcvnxw13JNwxeZhgZM4UyWQHS97V7gW672 ",
imageAlt: " Garment Texture 100317 ",
skills: 1,
guilds: 1,
title: "Emma-Jane MacKinnon-Lee ",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100318 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVT8t7xMCBK7XTGXjha6AvANT58NtJxmsBswaLTKi3qgE ",
imageAlt: " Garment Texture 100318 ",
skills: 1,
guilds: 1,
title: "Stella Achenbach",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};

































const token100322 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmNtVQmangJVXEMpU9MeCocF2mAKfYtB3ieTgRUag5zKG7 ",
imageAlt: " Garment Texture 100322 ",
skills: 1,
guilds: 1,
title: "Stella Achenbach",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100323 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmXuGSBASbyhKFMsyj7k9pcviVDkQMbm4k8edbvJTcTtEf ",
imageAlt: " Garment Texture 100323 ",
skills: 1,
guilds: 1,
title: "Stella Achenbach",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100324 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmaZPNsLQTzddy7S5cS7soDzswBwPUDDaSM6G37hpS9uoD ",
imageAlt: " Garment Texture 100324 ",
skills: 1,
guilds: 1,
title: "Stella Achenbach",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100325 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmcRMjAR4g2sJeYBfhSFoSNWEVcqEkmSCVyRhBYk5g3hRB ",
imageAlt: " Garment Texture 100325 ",
skills: 1,
guilds: 1,
title: "Stella Achenbach",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100326 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmYrsHqG6g47W7bQffrr5VuEdSyeAuKAeRX8mB9F91Z38f ",
imageAlt: " Garment Texture 100326 ",
skills: 1,
guilds: 1,
title: "Stella Achenbach",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100327 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSGMvU8G8eXUuuSP6SCB6tg4b3xxt3NgTosx7omKvPmJA ",
imageAlt: " Garment Texture 100327 ",
skills: 1,
guilds: 1,
title: "Mutani.io",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};

































const token100331 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmX37TkDxyzUGCXb3Coa3xxoA89U514cjjAvobqJ4Bs7nC ",
imageAlt: " Garment Texture 100331 ",
skills: 1,
guilds: 1,
title: "Camiltoe",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100332 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTKZ6NGsEifsUwAHjMQGDU8vN2SYEsSzZzH5RpuWGcRAn ",
imageAlt: " Garment Texture 100332 ",
skills: 1,
guilds: 1,
title: "Camiltoe",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100333 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVEFr31YvoPmtzxSShPjpkHXncXhDTzJsbAZF9igQk2NS ",
imageAlt: " Garment Texture 100333 ",
skills: 1,
guilds: 1,
title: "Camiltoe",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100334 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZhH8VUjUYqXBopEg1qMxr9nsiJVfoqWY2bmQjMwwAv4g ",
imageAlt: " Garment Texture 100334 ",
skills: 1,
guilds: 1,
title: "Camiltoe",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100335 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmRVqZX6JToVnEPut5g3cGkBYRyX7YLhxgEoz4oaVBy5K9 ",
imageAlt: " Garment Texture 100335 ",
skills: 1,
guilds: 1,
title: "Camiltoe",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100336 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVmUxDrcLvJJBJgqwBs14nP89MnqQU73vgjeyriHDEdsT ",
imageAlt: " Garment Texture 100336 ",
skills: 1,
guilds: 1,
title: "Camiltoe",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100337 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmX1VgYEGBKfe6veBDncP9i875eQ5WmPuW4AmtYBC34mbe ",
imageAlt: " Garment Texture 100337 ",
skills: 1,
guilds: 1,
title: "Emma-Jane MacKinnon-Lee ",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100338 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qma8R4Ju6wrWniPPVHtDTAwH2qFWisoFQ3e6ft1PQDgJ8R ",
imageAlt: " Garment Texture 100338 ",
skills: 1,
guilds: 1,
title: "Ycesh",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100340 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmNTovEAbztRykzGCBjQjRjdNf2sKYpY8sdgPTGN6cZqwL ",
imageAlt: " Garment Texture 100340 ",
skills: 1,
guilds: 1,
title: "Ycesh",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100341 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQZKDuaVjtKLJteW724yLixsh5RBsXt8z6NsWRMcfYDHv ",
imageAlt: " Garment Texture 100341 ",
skills: 1,
guilds: 1,
title: "Paola Olaguivel",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100342 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQZKDuaVjtKLJteW724yLixsh5RBsXt8z6NsWRMcfYDHv ",
imageAlt: " Garment Texture 100342 ",
skills: 1,
guilds: 1,
title: "Paola Olaguivel",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100343 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmX8eXcVVZKCw3sVSBVFThPWiGhvrWXDzBseTCYKc2iSKY ",
imageAlt: " Garment Texture 100343 ",
skills: 1,
guilds: 1,
title: "Paola Olaguivel",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};












































const token100348 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVQXeoS76HN4XCsK2XPkPfyJxrqDh3EGyWqFc4QtngC37 ",
imageAlt: " Garment Texture 100348 ",
skills: 1,
guilds: 1,
title: "Paola Olaguivel",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100349 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWK7gCJ98pcWJqNhB6Qv3CBabYq8BY8GgkwZ1haS8LXQe ",
imageAlt: " Garment Texture 100349 ",
skills: 1,
guilds: 1,
title: "Stan Mclygin",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100351 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZfL5J35SrxrNZLHKH79LXpXHL9WrmueSZKb8RtLmsEsD ",
imageAlt: " Garment Texture 100351 ",
skills: 1,
guilds: 1,
title: "Mirth",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100352 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmXWn2cMip4uRusWqRw7pVRrW5RmqbqXz4rEk9mhoXgFru ",
imageAlt: " Garment Texture 100352 ",
skills: 1,
guilds: 1,
title: "Mirth",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100353 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVnbiGHoRhJ7kaMK9TTkhoZHxSUpFErGZg92nh8oLefTt ",
imageAlt: " Garment Texture 100353 ",
skills: 1,
guilds: 1,
title: "Mirth",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100354 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmdFzscXN7zMr7jBYxCxh9HN3YiB7tLEzy8aDitfVqnvEZ ",
imageAlt: " Garment Texture 100354 ",
skills: 1,
guilds: 1,
title: "Paola Olaguivel",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100356 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmRQ78FTz35vFZenTvn4LrKY41qZQt8WAJAqHM45BeqZpw ",
imageAlt: " Garment Texture 100356 ",
skills: 1,
guilds: 1,
title: "Aisha",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100357 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPjY7PyHXuCEtwrTUNNXXCeJHsiZou4sc7PbZyWqXBu2h ",
imageAlt: " Garment Texture 100357 ",
skills: 1,
guilds: 1,
title: "Paola Olaguivel",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100358 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmfZR5kEa2KCCAvoWDoiTuqZeGNqVEheSoAicb3TttadU1 ",
imageAlt: " Garment Texture 100358 ",
skills: 1,
guilds: 1,
title: "pr.esc",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100359 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZNG39ggMKdsXhJbgRAxsYZ1tgQqeQtBY8kdELHz5Sk2e ",
imageAlt: " Garment Texture 100359 ",
skills: 1,
guilds: 1,
title: "Mo'Casso",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100360 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmbuzTJ3bromv5qyNiHnz2AgbBgKKVHVN5DEwJ3bjyrGEH ",
imageAlt: " Garment Texture 100360 ",
skills: 1,
guilds: 1,
title: "Mo'Casso",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100361 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQFaxjewKxWkXfpJuBWSA1sj5nsJMsJuu3GUtJi4CFhg8 ",
imageAlt: " Garment Texture 100361 ",
skills: 1,
guilds: 1,
title: "Mo'Casso",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100362 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmagEeNhzsV28vGm6bnDabqnQZvFUkZobMPm9hvdfrn1V6 ",
imageAlt: " Garment Texture 100362 ",
skills: 1,
guilds: 1,
title: "Mo'Casso",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100364 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSRBsiYQzvTgcByxxxnyqYHGSKD6Zhha5byDFmQkwhnBu ",
imageAlt: " Garment Texture 100364 ",
skills: 1,
guilds: 1,
title: "Mo'Casso",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100365 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmXon1VuR6wncqRW8bppp2v7HyKumMDSe2EnjsMP31Fef4 ",
imageAlt: " Garment Texture 100365 ",
skills: 1,
guilds: 1,
title: "Mo'Casso",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100366 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmcnbrJ8TnLtGQr2bgWN8jtKuBtNVB9MsMoaKoQZPnYWFW ",
imageAlt: " Garment Texture 100366 ",
skills: 1,
guilds: 1,
title: "Mo'Casso",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100368 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTmpnBmHJxTxxawMs3uYMTPDrstVrKUE5LDNTF6NMG554 ",
imageAlt: " Garment Texture 100368 ",
skills: 1,
guilds: 1,
title: "Stan Mclygin",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100370 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmYsgevdhcdTMSFwrYGXWvBtuUbBuNCgr9RTgnAt6M6cAi ",
imageAlt: " Garment Texture 100370 ",
skills: 1,
guilds: 1,
title: "Mo'Casso",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100371 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSxjXvB5rhKz5zMqos6MaheecdPtE3n4ux9w3EJBHbxsr ",
imageAlt: " Garment Texture 100371 ",
skills: 1,
guilds: 1,
title: "Stan Mclygin",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100372 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTr1JXeVKwncQwgb32mi9QyavRwSbcvq58rCd6HnTZULF ",
imageAlt: " Garment Texture 100372 ",
skills: 1,
guilds: 1,
title: "Paola Olaguivel",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100374 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVFqgWViLyfuWSqm1tAi7Ub7Jjt69HcHJV5ijkwnPjbNT ",
imageAlt: " Garment Texture 100374 ",
skills: 1,
guilds: 1,
title: "Stan Mclygin",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100375 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmP99NRqj7dAqb2SWq57ZCMVdUZWvFbdAMi3t9eb2HEtGS ",
imageAlt: " Garment Texture 100375 ",
skills: 1,
guilds: 1,
title: "ENKI",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100376 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qma3sptWnR5Cgtza32w4zxV49CDRVe2tkKiuzMXUWYHmbg ",
imageAlt: " Garment Texture 100376 ",
skills: 1,
guilds: 1,
title: "ENKI",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100377 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVh7CTi7Y37WjAVuV4FtJ2AwJV1PSBPnieWapF66FFfhE ",
imageAlt: " Garment Texture 100377 ",
skills: 1,
guilds: 1,
title: "ENKI",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100378 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmf4MeVJddfhrEnsZxZeqX84qbo91jE2a6VUGMBinp4uQM ",
imageAlt: " Garment Texture 100378 ",
skills: 1,
guilds: 1,
title: "ENKI",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100379 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTEn3zDfHXysixNkAwgJpMCxjnSPPtiS1gKB3we4MJiAC ",
imageAlt: " Garment Texture 100379 ",
skills: 1,
guilds: 1,
title: "ENKI",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100380 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmW4Lo5t26p8xR4Czn2tBa8hP7Y1aigiGTF5ZneVMCjLgY ",
imageAlt: " Garment Texture 100380 ",
skills: 1,
guilds: 1,
title: "ENKI",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100381 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmcSNxPM4U52Bgy82d7KoNYTd5YUy4BMkY5BunQ6nHjNoM ",
imageAlt: " Garment Texture 100381 ",
skills: 1,
guilds: 1,
title: "wyhygh",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100382 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWpiQMyZiWBFatVJAoGG4454G4m4ja8geDMevdU8RAHZF ",
imageAlt: " Garment Texture 100382 ",
skills: 1,
guilds: 1,
title: "Emma-Jane MacKinnon-Lee ",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100383 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmT9LfugDkGMzKSPbGQ7rnMoTNoicN8Ccr6TAfQ3MkLenc ",
imageAlt: " Garment Texture 100383 ",
skills: 1,
guilds: 1,
title: "Emma-Jane MacKinnon-Lee ",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100384 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQYXs3CBYCgrUBhwLCuv46dHwtQDoHDUdwLCyaQYFFgoW ",
imageAlt: " Garment Texture 100384 ",
skills: 1,
guilds: 1,
title: "Infinito",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100385 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmakobw3JgftLzBr3An13JDXNgBHEfZRGPhpiEizLAGXcs ",
imageAlt: " Garment Texture 100385 ",
skills: 1,
guilds: 1,
title: "Stan Mclygin",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100386 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmcgqnkmTruvZym976ZgcgrvcECRmAnGjdBftzpaHr9tTd ",
imageAlt: " Garment Texture 100386 ",
skills: 1,
guilds: 1,
title: "Stan Mclygin",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100387 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmaigow6NdN2FFREY7LBRUtxFmCknE2VpAkmY6eQ5QXYr6 ",
imageAlt: " Garment Texture 100387 ",
skills: 1,
guilds: 1,
title: "Tania MV Studio",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100388 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUfmTaBDv5kXcMfx5G4ZFTo4qWLwGTgxZw1xE9LUGpuTA ",
imageAlt: " Garment Texture 100388 ",
skills: 1,
guilds: 1,
title: "Tania MV Studio",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100390 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZDXNPPh7k7Kyfi4As22a24fvCx87JrXgFAMdEw7ZC8DM ",
imageAlt: " Garment Texture 100390 ",
skills: 1,
guilds: 1,
title: "Tania MV Studio",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100391 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVcu6yZ3Li8MxSdnxwraiS4uKrgGxnjVE7KsakoDXbopj ",
imageAlt: " Garment Texture 100391 ",
skills: 1,
guilds: 1,
title: "Tania MV Studio",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100392 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmYByd6GfQch5UHpJwmZukJhreHsTX4MkjEeGRWAAnRsC5 ",
imageAlt: " Garment Texture 100392 ",
skills: 1,
guilds: 1,
title: "Tania MV Studio",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100393 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmYYYmKYpFDs78e9oKXQZ8xhSQqcBXCUH8BWHq9ZsHJNab ",
imageAlt: " Garment Texture 100393 ",
skills: 1,
guilds: 1,
title: "Tania MV Studio",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100395 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTjgBmgKYLDi7ZHJAsAosNkwvZDPpCn3pvSbb7xmxDf2t ",
imageAlt: " Garment Texture 100395 ",
skills: 1,
guilds: 1,
title: "Tania MV Studio",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100396 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmaSM32g7VbVkDXVCkTEsQ79UnBUPwP3y9A8euBp63C3xJ ",
imageAlt: " Garment Texture 100396 ",
skills: 1,
guilds: 1,
title: "ENKI digital fashion",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100397 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQQiukttyaTouZNgfeL1Q2H8X5u4Tz8otSiVYa5DER4Ls ",
imageAlt: " Garment Texture 100397 ",
skills: 1,
guilds: 1,
title: "ENKI digital fashion",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100398 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWW3N8XMLPPDVJGBfccbeSSHhBuFaocNCGgrPEbk4J2ZF ",
imageAlt: " Garment Texture 100398 ",
skills: 1,
guilds: 1,
title: "ENKI digital fashion",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100399 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWY7GiiBusiqDaSP4mBYbggvfTixHxsqt5EoqxwMabH1T ",
imageAlt: " Garment Texture 100399 ",
skills: 1,
guilds: 1,
title: "ENKI digital fashion",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100400 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmNT9K9eNEzYLYW5pVLFoSZ7f5CmwecBfTXLrUgbvnE6ZQ ",
imageAlt: " Garment Texture 100400 ",
skills: 1,
guilds: 1,
title: "ENKI digital fashion",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100401 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUogMeuwwNtgtpbEH72w1EQQrVCcVhWpHGyx9HmNYNw7F ",
imageAlt: " Garment Texture 100401 ",
skills: 1,
guilds: 1,
title: "ENKI digital fashion",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100402 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmbfGZNUWJjWsqSwN4oQPyJRATDrVTiCxUQM224QJk8M8k ",
imageAlt: " Garment Texture 100402 ",
skills: 1,
guilds: 1,
title: "ENKI digital fashion",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100403 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZRSGtAXh3eJemQjA3y2xjBzNbeaTx4nMXLh51P5xYTAm ",
imageAlt: " Garment Texture 100403 ",
skills: 1,
guilds: 1,
title: "Tania MV Studio",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100404 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVwq6kdjWmZutPPgnuGhghsBkS4uTLNv2RoAQFgS2gVS4 ",
imageAlt: " Garment Texture 100404 ",
skills: 1,
guilds: 1,
title: "Tania MV Studio",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100405 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZAxyfCkZqMd5bjp32LLpajMeyEei1akiVtnLZnfynQMn ",
imageAlt: " Garment Texture 100405 ",
skills: 1,
guilds: 1,
title: "Tania MV Studio",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100406 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPywtFQjDz9chfgRV5QRZqGpYMat84z5cjFs7jS8ZiT5x ",
imageAlt: " Garment Texture 100406 ",
skills: 1,
guilds: 1,
title: "Tania MV Studio",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100407 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmbjgYP6agWbfyx3vNhhqoi6gXX9BfsvSFCHgbuMK3Uy3E ",
imageAlt: " Garment Texture 100407 ",
skills: 1,
guilds: 1,
title: "saso",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100408 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmcWARwyzY2FeCWcchaErtgkgP7piEaMjKoxmBVd1yPadq ",
imageAlt: " Garment Texture 100408 ",
skills: 1,
guilds: 1,
title: "ORDO",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100409 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmcXWEw3S27TmH9p1DLGMAE4oQ5Y6WZ7pVQHL5nAwxR9U1 ",
imageAlt: " Garment Texture 100409 ",
skills: 1,
guilds: 1,
title: "ORDO",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100410 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmfLofdqmSNFd7xynNuirTALL2GdU8YSNh1HWPS2nbKMb4 ",
imageAlt: " Garment Texture 100410 ",
skills: 1,
guilds: 1,
title: "ORDO",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100411 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTNf9ve6K1xVwUnP5sj9193vauARUVx8ah2ix9w23W2fv ",
imageAlt: " Garment Texture 100411 ",
skills: 1,
guilds: 1,
title: "1hary1",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100412 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWTXH2gt24Hvsep3V2rcHGKt8gcAQ6LDfhzHeg2nSygjo ",
imageAlt: " Garment Texture 100412 ",
skills: 1,
guilds: 1,
title: "Tania MV Studio",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100413 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmamrLzTYQADN7B7MaTy1WuxckEpuNfmGjUDciGmk7EUAj ",
imageAlt: " Garment Texture 100413 ",
skills: 1,
guilds: 1,
title: "clothes_3d",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100414 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWKFaawqVKbqKiPZhJQ5mBWRipRhRky1pcih5bmjXKFSA ",
imageAlt: " Garment Texture 100414 ",
skills: 1,
guilds: 1,
title: "Midwest Misfit",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100416 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmRnHN6urEswFkoXDe6fPXYrJB2e2FSDM8SqFspqgxT5Jq ",
imageAlt: " Garment Texture 100416 ",
skills: 1,
guilds: 1,
title: "Midwest Misfit",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100417 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSwSeZZJ2E1wAgKy2c84WzH9tTRk7JxTjeb5WkEae8S9z ",
imageAlt: " Garment Texture 100417 ",
skills: 1,
guilds: 1,
title: "Midwest Misfit",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};























































const token100423 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmfTevRWMuBbBN6uBigeboxfUj5GasnRmNsQGP6rC5S7dL ",
imageAlt: " Garment Texture 100423 ",
skills: 1,
guilds: 1,
title: "ENKI Digital Fashion",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100424 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmYMAWe7Jwb3PAxZe3Dpu2vQpY2g6euQDCibsZjdo5M8Qi ",
imageAlt: " Garment Texture 100424 ",
skills: 1,
guilds: 1,
title: "ENKI Digital Fashion",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100425 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmczZVNYkXs9k1zN8sSLsBJ1UPCsQKG214rBPpDiGBbQGL ",
imageAlt: " Garment Texture 100425 ",
skills: 1,
guilds: 1,
title: "ENKI Digital Fashion",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100426 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPrRcSUfHcqQh559XXrhvHggsbBknM3XXSMHdDNtr9rzV ",
imageAlt: " Garment Texture 100426 ",
skills: 1,
guilds: 1,
title: "DomingoBeta",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100427 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWvb5ooQWSkrXMwfQeAYfykJMrtcoFiVBruq6tJ7V6rov ",
imageAlt: " Garment Texture 100427 ",
skills: 1,
guilds: 1,
title: "Stan Mclygin",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100428 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmXqHHHHbdq7BYiknkQKaw6ifrMnAhbGsAJh8J9NYPDnur ",
imageAlt: " Garment Texture 100428 ",
skills: 1,
guilds: 1,
title: "Midwest Misfit",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100429 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmRav1me7zHCGBJqhfeYXtGdGdz7B4FZ6ktWbdSDrFWvaK ",
imageAlt: " Garment Texture 100429 ",
skills: 1,
guilds: 1,
title: "Midwest Misfit",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100430 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVpZJn8SRF4is3yCbDD9ByqhjMRMyJeZXwWJcGBVre784 ",
imageAlt: " Garment Texture 100430 ",
skills: 1,
guilds: 1,
title: "Midwest Misfit",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100431 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSj6ytSzDSD4LsLsP4YThvhwzCnXuZAinaqJapTJz31JS ",
imageAlt: " Garment Texture 100431 ",
skills: 1,
guilds: 1,
title: "Midwest Misfit",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100432 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTj4JJV9DXbTD4tHWew9nTxXZe9DJ5FimeEvvYeXaZdFf ",
imageAlt: " Garment Texture 100432 ",
skills: 1,
guilds: 1,
title: "Midwest Misfit",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100433 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZYGgCez5hULvirccXfph1sW3UzTY6tMAffBZimydwuSJ ",
imageAlt: " Garment Texture 100433 ",
skills: 1,
guilds: 1,
title: "Mirth",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100434 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmamrLzTYQADN7B7MaTy1WuxckEpuNfmGjUDciGmk7EUAj ",
imageAlt: " Garment Texture 100434 ",
skills: 1,
guilds: 1,
title: "clothes_3d",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100435 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVyCiPpeR8Hmz8ZVFmC2HwFhYbPnZLYRzxEMtYoEexQ6r ",
imageAlt: " Garment Texture 100435 ",
skills: 1,
guilds: 1,
title: "Oli3D",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100436 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmXFFWUVY9hSWhgzzVLzHBnnWAbxUFJ39PnWoZEBDSNA6u ",
imageAlt: " Garment Texture 100436 ",
skills: 1,
guilds: 1,
title: "Oli3D",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100437 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmRX6KinSwo4iDq48aAYWQPBfFNarTPZ578jRQPie6zLP2 ",
imageAlt: " Garment Texture 100437 ",
skills: 1,
guilds: 1,
title: "Oli3D",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100438 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVc9kkZpqEczg3zMmXXcC1iRkVHN1TmoqtrtTE6MJRCAV ",
imageAlt: " Garment Texture 100438 ",
skills: 1,
guilds: 1,
title: "stan mclygin",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100439 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmToxRFo2q2XNrYpzvPkRYcVgwQQby5cwqxiYd6auDt8TH ",
imageAlt: " Garment Texture 100439 ",
skills: 1,
guilds: 1,
title: "stan mclygin ",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100440 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmbFUNtrciR1F5FWK1W3etua6GrpvaMJq1KFzzwBcPTQBE ",
imageAlt: " Garment Texture 100440 ",
skills: 1,
guilds: 1,
title: "stan mclygin",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100441 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmXQErES94XiULpJU8Masv9Ctdpdsu7zmE5eXyNNwgVMr9 ",
imageAlt: " Garment Texture 100441 ",
skills: 1,
guilds: 1,
title: "stan mclygin",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100442 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSUqm65vGAbnRtwdHxzP79QiSXCq5DzaTA4VArGHwfn4k ",
imageAlt: " Garment Texture 100442 ",
skills: 1,
guilds: 1,
title: "stan mclygin",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100443 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmT42C6sHSoRTYXToUo6oNpUcq6k6nGJPUfydqpVYhAiT3 ",
imageAlt: " Garment Texture 100443 ",
skills: 1,
guilds: 1,
title: "stan mclygin",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100444 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmREWW9Kfj9udJsCHrEWSVcogpJ3KZFeoEXsSzSjWbU5oV ",
imageAlt: " Garment Texture 100444 ",
skills: 1,
guilds: 1,
title: "stan mclygin",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100445 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZMUfG8SZgkY5cb6dVq1fFrnKxboHQXTa8xa4xWsQeruK ",
imageAlt: " Garment Texture 100445 ",
skills: 1,
guilds: 1,
title: "stan mclygin",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100446 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmfTjyH21mknHwD7w84JnzGXmtGtKFNKStedE22pWAEu8J ",
imageAlt: " Garment Texture 100446 ",
skills: 1,
guilds: 1,
title: "stan mclygin",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100447 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTCMAd6AjQtMYYzqsJH1dnL2BnfqtDFBBXzkHL8wkzr4m ",
imageAlt: " Garment Texture 100447 ",
skills: 1,
guilds: 1,
title: "stan mclygin",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100448 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmXxwm53azQveVeqmQx43mXiDMtugK8GzFss2dtP9uewEQ ",
imageAlt: " Garment Texture 100448 ",
skills: 1,
guilds: 1,
title: "Oli3D",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100449 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTDcJWsN2rE7VmAA6bR9vxSyzKJseCnoKvRDyV1y7NxmR ",
imageAlt: " Garment Texture 100449 ",
skills: 1,
guilds: 1,
title: "Oli3D",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100450 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmfLqj5pmRr2bGLnAoE8opwcyYz2hEZX5L6tPveT11tKEi ",
imageAlt: " Garment Texture 100450 ",
skills: 1,
guilds: 1,
title: "Oli3D",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100451 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUmRHiWex7utUaNgrCmR5yFkpb2E4fUGoY15Smw5JZTw9 ",
imageAlt: " Garment Texture 100451 ",
skills: 1,
guilds: 1,
title: "Deep Thought",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100452 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmfYFcXCFSdCAmrc4CsmdmMi1aRn8wca59rWiPGFcpRd4c ",
imageAlt: " Garment Texture 100452 ",
skills: 1,
guilds: 1,
title: "Deep Thought",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100454 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVP8T7bmEazTu9MXpKhb6usDJdoMzLtAbyWgXnerSNE6x ",
imageAlt: " Garment Texture 100454 ",
skills: 1,
guilds: 1,
title: "Deep Thought",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100456 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmT2jaoCQZzE2fE1nKrtim5Ydkf4a6xy45uTcRwBP9NY9g ",
imageAlt: " Garment Texture 100456 ",
skills: 1,
guilds: 1,
title: "Deep Thought",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100457 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPVHhNnVfaihDsjvEZpbZxL5ceFnsR6Ybnpy9UydM8Jiw ",
imageAlt: " Garment Texture 100457 ",
skills: 1,
guilds: 1,
title: "Deep Thought",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100458 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPVrVzJAWi5T5uQfbLhqe3jiGzFGAD77Xn2Db1rYGTJy2 ",
imageAlt: " Garment Texture 100458 ",
skills: 1,
guilds: 1,
title: "Deep Thought",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100459 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmYxh7AHnaY7aea3FpHecViZdK5cbURZ5tthtmGsF4PXTM ",
imageAlt: " Garment Texture 100459 ",
skills: 1,
guilds: 1,
title: "Deep Thought",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100460 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUHbXrsqFQ9kgHGCurLL4GUKi8PnNc86otKri16ya92nk ",
imageAlt: " Garment Texture 100460 ",
skills: 1,
guilds: 1,
title: "Deep Thought",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100461 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmY1pcHhs18GHWWTdwgwyQ44zgx5hfyFNaNR8NW3scbNXP ",
imageAlt: " Garment Texture 100461 ",
skills: 1,
guilds: 1,
title: "Deep Thought",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100462 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmR29kbKDf5qB9aNygX7PaYix1F5UhLSfykWD9v6C9D8e9 ",
imageAlt: " Garment Texture 100462 ",
skills: 1,
guilds: 1,
title: "Deep Thought",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100463 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSL8r8PDZTLDK1aatJSSxGW8vD8WuPAwAePqaTpzKE7kk ",
imageAlt: " Garment Texture 100463 ",
skills: 1,
guilds: 1,
title: "Yauheni Kolbasko",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100464 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTPpX8aoCEXcAsv67bZbNTY81ka1KjPGa36usVDSpnUsM ",
imageAlt: " Garment Texture 100464 ",
skills: 1,
guilds: 1,
title: "Mae",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100465 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWUvwTk7myAHBZ9AmjGmfnKujQaTSkARZW6zoMPhSeAEE ",
imageAlt: " Garment Texture 100465 ",
skills: 1,
guilds: 1,
title: "Mae",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100466 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUVZuAfoeiukobT44xUAH5wTFNFGJRbE5zDYRLz8YPyyf ",
imageAlt: " Garment Texture 100466 ",
skills: 1,
guilds: 1,
title: "Mae",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100467 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWtWHoo9PyZXxcGN8aBeMwXB7EAqfViKXxoW93F6jW34P ",
imageAlt: " Garment Texture 100467 ",
skills: 1,
guilds: 1,
title: "Mae",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100469 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTTr9FKf1ViwasNe2aaDTi9ECE9emhxrqJNcEtpJG5BsP ",
imageAlt: " Garment Texture 100469 ",
skills: 1,
guilds: 1,
title: "Mae",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100470 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmX6U7UhmCiBJz8oMLSC9W5kBKCX3GCSxuG1imHx9VhP1c ",
imageAlt: " Garment Texture 100470 ",
skills: 1,
guilds: 1,
title: "Keepingdistance",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100471 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWTFC8rydMMHULwmR2VC1NjZYpZqXnaJGRMZXPBYubvvZ ",
imageAlt: " Garment Texture 100471 ",
skills: 1,
guilds: 1,
title: "Keepingdistance",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100473 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVEbaz6xWXQC2wPo4euW2Rc5pooBygnEHce5zqpHXXHw8 ",
imageAlt: " Garment Texture 100473 ",
skills: 1,
guilds: 1,
title: "Keepingdistance",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100474 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmbSxvQtVvBerUKX91yJXVE3jRzdS6LHNs4ownwrKyoPc2 ",
imageAlt: " Garment Texture 100474 ",
skills: 1,
guilds: 1,
title: "Keepingdistance",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100475 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmYBe4x5UTYueLWPjCfrHBDbigygjCDTFD95dHuaz8wkeP ",
imageAlt: " Garment Texture 100475 ",
skills: 1,
guilds: 1,
title: "Keepingdistance",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100476 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmdrZpSwpeq4v6jT4CJpzLqVDgXxHsLkSm55Jafh7pAEdX ",
imageAlt: " Garment Texture 100476 ",
skills: 1,
guilds: 1,
title: "Keepingdistance",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100478 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmNisZ271uBUPZdWCoboh9dhuC3Mm8fh8rTGTrKFJ14hX2 ",
imageAlt: " Garment Texture 100478 ",
skills: 1,
guilds: 1,
title: "Mo'Casso",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100479 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQ4P8ayVV2WmR6XivLwPmRgPaGRbt3dKwAhDyvpkmNVPG ",
imageAlt: " Garment Texture 100479 ",
skills: 1,
guilds: 1,
title: "Stella Achenbach",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100480 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmdxD9vMyeUR84cw1FCqEKy1VQFSQTuS9V1t5NkfM4JUUp ",
imageAlt: " Garment Texture 100480 ",
skills: 1,
guilds: 1,
title: "Stella Achenbach",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100481 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTuSHZASnBWyDw1htV8LizLcV6rx23LmKnBSyTGfXA6dU ",
imageAlt: " Garment Texture 100481 ",
skills: 1,
guilds: 1,
title: "underinsight",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100483 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmY2GuYFeqRGFKeRTeSFtMivspLbWRo95GBZAGSe4Usgxe ",
imageAlt: " Garment Texture 100483 ",
skills: 1,
guilds: 1,
title: "underinsight",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100484 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmRJniA1drLxhMiYkdgFgM1t3VaTSpReYDS6HkNJq1uUmR ",
imageAlt: " Garment Texture 100484 ",
skills: 1,
guilds: 1,
title: "Stella Achenbach",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100485 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmd6HRnFWjBf3i8Hdo2pmWf66WthrJLq2ZYb9w7MTNptAu ",
imageAlt: " Garment Texture 100485 ",
skills: 1,
guilds: 1,
title: "Stella Achenbach",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100486 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmbNA6EUM8gk1vmSTJBjfx1A3JX6ZTwsfYSWkxrmfqGM97 ",
imageAlt: " Garment Texture 100486 ",
skills: 1,
guilds: 1,
title: "metadesign",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100487 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTPcDNc2xdT3vsDKkr8ceYeqfRikmaCdtHBfJZYNNngiA ",
imageAlt: " Garment Texture 100487 ",
skills: 1,
guilds: 1,
title: "metadesign",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100488 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmYam9MzXapUghNGkBwrrdrCL9KX4i1kTAT3qN9rD7R2UH ",
imageAlt: " Garment Texture 100488 ",
skills: 1,
guilds: 1,
title: "metadesign",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100489 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPtPoAjmoSK2a5cCU6eTJyP49MFee1TMXfAEBpfm3UgQ2 ",
imageAlt: " Garment Texture 100489 ",
skills: 1,
guilds: 1,
title: "metadesign",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100490 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTJtFggCCeYKpSmFJBYNYGrhQ32vYycUaUSV8fsqAuCw9 ",
imageAlt: " Garment Texture 100490 ",
skills: 1,
guilds: 1,
title: "metadesign",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100491 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZm7zYLH5N13eAVNrrfVTLRRUX5w9ceftMSmdLrsCVR3T ",
imageAlt: " Garment Texture 100491 ",
skills: 1,
guilds: 1,
title: "metadesign",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100492 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPbZpW1oybhrCofBZqbCewjuyZvAW9rQLDeHU8Cpg4FXD ",
imageAlt: " Garment Texture 100492 ",
skills: 1,
guilds: 1,
title: "metadesign",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100493 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmY1Y3zni2LvYULKCxr95WLaxnmTyEpjhuiaTDkVUyV3zk ",
imageAlt: " Garment Texture 100493 ",
skills: 1,
guilds: 1,
title: "metadesign",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100494 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWPcSMAbffn3wYTYu5ZaJtYXwK7PTee8Fnv8rJL9CoEcu ",
imageAlt: " Garment Texture 100494 ",
skills: 1,
guilds: 1,
title: "Alanatheweb3girl",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100495 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmX8ZZqRYA5155iJHtiZj4THpscBhipiEycUG3pEUpwZ5w ",
imageAlt: " Garment Texture 100495 ",
skills: 1,
guilds: 1,
title: "Alanatheweb3girl",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100496 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSJg8SfgPsvim5xE1Hyr5wE71yopKo5oSFizHqs9sKt1h ",
imageAlt: " Garment Texture 100496 ",
skills: 1,
guilds: 1,
title: "osumikat",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100498 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmdt6TAQS2a4ZnZTeW15y3PGvUsFV8pv4LFH7DLGJFb3Lc ",
imageAlt: " Garment Texture 100498 ",
skills: 1,
guilds: 1,
title: "osumikat",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100499 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVM79MEeeH7yaLUavkmBfRV36EUpUYcEEmx6rHpUKDpeo ",
imageAlt: " Garment Texture 100499 ",
skills: 1,
guilds: 1,
title: "metadesign",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100500 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmf7V6whJC464vjT7knCoU5rcDPtdgDz2QoMqTqSvJkHW7 ",
imageAlt: " Garment Texture 100500 ",
skills: 1,
guilds: 1,
title: "metadesign",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100501 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmbzhnACArUwzS2hPk4wGh93FnSVokQWgS8dbgpbC7ZsSB ",
imageAlt: " Garment Texture 100501 ",
skills: 1,
guilds: 1,
title: "metadesign",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100502 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmXDiMNkZy8iwbBLEDjza8eokgbca9yXNUiHcEKvV41mYs ",
imageAlt: " Garment Texture 100502 ",
skills: 1,
guilds: 1,
title: "metadesign",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100503 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmfLzuWn2nbaD5TVzgQ6D1CZa7aqAPNU9KA7oWyukwQUw2 ",
imageAlt: " Garment Texture 100503 ",
skills: 1,
guilds: 1,
title: "metadesign",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100504 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmbzhnACArUwzS2hPk4wGh93FnSVokQWgS8dbgpbC7ZsSB ",
imageAlt: " Garment Texture 100504 ",
skills: 1,
guilds: 1,
title: "metadesign",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100505 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmNuCAekcZTgyTrqyy9aoxt4vbhSdpw2gEK7HF3vJ3thza ",
imageAlt: " Garment Texture 100505 ",
skills: 1,
guilds: 1,
title: "metadesign",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100506 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmRiVmVqKcdWoBvSVYtErkJDGJeTJ6pMgQXGa6e3QAqtrn ",
imageAlt: " Garment Texture 100506 ",
skills: 1,
guilds: 1,
title: "metadesign",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100507 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUqzRNUSzHhr3e7jJtsUr6d3nyjkrHqDmZDWpCv8HcLQe ",
imageAlt: " Garment Texture 100507 ",
skills: 1,
guilds: 1,
title: "metadesign",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100508 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVmCGatx7w6WARP4KnkaFLaH85mmxmEfunibuKC1Hm7tR ",
imageAlt: " Garment Texture 100508 ",
skills: 1,
guilds: 1,
title: "metadesign",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100509 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWuehzN34nKBWKSNUAkF5SHgK3FDmmzvahhXzB1PA5vy7 ",
imageAlt: " Garment Texture 100509 ",
skills: 1,
guilds: 1,
title: "metadesign",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};












































const token100514 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVUPvdQDu9Zm5kXqrPL4ttoVrobpPhsEmGBWBm4SMTzCr ",
imageAlt: " Garment Texture 100514 ",
skills: 1,
guilds: 1,
title: "Oli3D",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100515 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZaArA8G6by2FkiJ7NqrXEvVEUDG7LU1aAK2jaL8mzpvC ",
imageAlt: " Garment Texture 100515 ",
skills: 1,
guilds: 1,
title: "Oli3D",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100516 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmaX8mGMYTLMEQbK3g2LNSZBDGgFjrZtTEpVp776veYoP5 ",
imageAlt: " Garment Texture 100516 ",
skills: 1,
guilds: 1,
title: "DGTL_stylist",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100517 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmaYUGjFvLmFo4nnc4mv79QXNwH8q6DeLfTPjrUq12ULLh ",
imageAlt: " Garment Texture 100517 ",
skills: 1,
guilds: 1,
title: "DGTL_stylist",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100518 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPismu8nCuURuXNSdSFqHJd3S9qgcKKLiLT1Fv75e6cWT ",
imageAlt: " Garment Texture 100518 ",
skills: 1,
guilds: 1,
title: "DGTL_stylist",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100520 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmaD697usnpXSCNABgHFHLpfws1BRANJ1vFMwxdBLhJN65 ",
imageAlt: " Garment Texture 100520 ",
skills: 1,
guilds: 1,
title: "Mar Guixa Studio",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100521 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZczMkt5od9NverZv8hB1G1kfKbnfTH8aCNBc6VVeAjbq ",
imageAlt: " Garment Texture 100521 ",
skills: 1,
guilds: 1,
title: "Keeping Distance",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100522 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmXob2MFW4qf2ma9xuUNkukhZ2b1xqhw1RYkdcFRHTVQNT ",
imageAlt: " Garment Texture 100522 ",
skills: 1,
guilds: 1,
title: "Keeping Distance",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100523 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmczswHewUPC43s3itgvx9b8udii1DqrMx4qjvvh5he5DK ",
imageAlt: " Garment Texture 100523 ",
skills: 1,
guilds: 1,
title: "Keeping Distance",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100524 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmdkoxWzTMVtueg117ddq441m7kBtWNHeXhVsWuFZVgCDJ ",
imageAlt: " Garment Texture 100524 ",
skills: 1,
guilds: 1,
title: "Keeping Distance",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};























































const token100530 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZFAmcTSHM97AXUcynjyQPDmvokwoNGLKVASDFTZptciJ ",
imageAlt: " Garment Texture 100530 ",
skills: 1,
guilds: 1,
title: "Keeping Distance",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100531 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWWR2w91jUYD3snB8xsNk7Y9tTzPUf1rAgb6ZpsDv93qN ",
imageAlt: " Garment Texture 100531 ",
skills: 1,
guilds: 1,
title: "Oli3D",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100532 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmeyKaeMco7cKG6JWrTScoYPsrX9ztPECF6mLDi83MN62A ",
imageAlt: " Garment Texture 100532 ",
skills: 1,
guilds: 1,
title: "Oli3D",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100533 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmdf9YzvbiqxmZPszFHEP7TNNkDRpDkiXz1hpAWVcHMBEw ",
imageAlt: " Garment Texture 100533 ",
skills: 1,
guilds: 1,
title: "Oli3D",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100534 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmYC3GGuBR2jUUS6d2gUuRCHurY7wrhubFBG9oWCh6dwXy ",
imageAlt: " Garment Texture 100534 ",
skills: 1,
guilds: 1,
title: "Oli3D",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100538 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmbcPt72M7gMFSLQADTYjCv3Q8UgXMdNyEfgDLpaCg2hAp ",
imageAlt: " Garment Texture 100538 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100539 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmYfvuPpGNZdpEThKUDoJPQLd7s1drfzi9T1jYgAHCNC7W ",
imageAlt: " Garment Texture 100539 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100540 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmP89wVf7GdJEsMgRdHZ915ugHd1ta6CUWoSRRFPETeAot ",
imageAlt: " Garment Texture 100540 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100541 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSeyxnP1EzrgHcbRAufSVjSp4NuE9ahQ9m7KUK3Hoa584 ",
imageAlt: " Garment Texture 100541 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100542 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmP5qCexTFDWNRCGB7NEki1A33raZyVN4QGzadPPb6C5cE ",
imageAlt: " Garment Texture 100542 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100543 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQe8PndVQ5BWzWJ8rmJm6JNn5JH7Xx76t3ZrrkaPyQaDi ",
imageAlt: " Garment Texture 100543 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100544 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTakQcDv32k3pZeHPusxqt64RGbDa7kDCJr7RQmz5TNN5 ",
imageAlt: " Garment Texture 100544 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100545 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWhd3CP1CmqZ3MxrdfKHSg85BdarjjVtsbQnytqTrus3L ",
imageAlt: " Garment Texture 100545 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100546 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZBEoVnEUY9RjF9i59Uy3P7RuQwp4HyMHByhekq7qoTzs ",
imageAlt: " Garment Texture 100546 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100547 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmcZQfhYSu1pU1dVJzEkKvBi5AiLGebmc2u7K6E2Ado2Df ",
imageAlt: " Garment Texture 100547 ",
skills: 1,
guilds: 1,
title: "LivingSparks",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100548 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmbhA76R6WnAceDLGup8CwpTMfr3Ga8amsMNuMeL6bxzBN ",
imageAlt: " Garment Texture 100548 ",
skills: 1,
guilds: 1,
title: "Oli3D",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};

































const token100552 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVz6MhUNa93YUmWU5879XQH21vVVUhvRZxZTGREzH63jR ",
imageAlt: " Garment Texture 100552 ",
skills: 1,
guilds: 1,
title: "Garcia Galleries",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100553 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmP88cr8Fe6ZGmtvBVjxbMi3RAYDt6wL5e8eV4nNqvdGRC ",
imageAlt: " Garment Texture 100553 ",
skills: 1,
guilds: 1,
title: "Oli3D",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100568 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmNqbgBqo3YyR6rtShpJ974qktJkWmTyzGvaS13sEnvA6s ",
imageAlt: " Garment Texture 100568 ",
skills: 1,
guilds: 1,
title: "Oli3D",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100569 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmb3sJaoy4g41xBZ65sEz8ENfj5v5cX9ing7gYx6psyZvZ ",
imageAlt: " Garment Texture 100569 ",
skills: 1,
guilds: 1,
title: "Tiny Terror",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100570 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmcEZUd6tBsT4w8vhiJ9BTAct8H4vS39S4kaSuhqT7SN8v ",
imageAlt: " Garment Texture 100570 ",
skills: 1,
guilds: 1,
title: "Stella Achenbach",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100571 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmRaHkRw8kFVBmeDCvZk1v8ZX3cx6CDLd55PyEzaEr3AXd ",
imageAlt: " Garment Texture 100571 ",
skills: 1,
guilds: 1,
title: "Stella Achenbach",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100572 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSPSBR37jrzdPxv9XJL9M8WGnqjYmriZu6VPGQVMioMHu ",
imageAlt: " Garment Texture 100572 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100573 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTaf5t5uVGrWVi2RFXrnPSE4R7Bhidg5BzcGE5XEUKPZS ",
imageAlt: " Garment Texture 100573 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100575 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSw2E6PvK3gR3RXBvm4LsW8pUHgFcTw7dkpTfPZ1ovvNr ",
imageAlt: " Garment Texture 100575 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100576 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmRXnYk9RawDd6DAh5zdUNh8kaj73kBEjSR4DELzYM7y5k ",
imageAlt: " Garment Texture 100576 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100577 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUoPdvgG3aKtg8f5Re48he5gWMAY6Jc7wRehYMBKFE9CQ ",
imageAlt: " Garment Texture 100577 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100578 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUXJpagT5CfsNU6Qmxhy99vAFNv2RJ6r4dNi47HQxNcjS ",
imageAlt: " Garment Texture 100578 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100579 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPoQHL6mhRgAavGcXUihaPArLCXZhcjxCEJta1WMvVhum ",
imageAlt: " Garment Texture 100579 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100580 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qme2RphcZ4cssWivAcfH8F9Z7GNQ68SkUNW76B1y6M3qjk ",
imageAlt: " Garment Texture 100580 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100581 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSYM61yRi1qdshC5QwwHaXtzXXh2c8GsXQBTf2VGSSUaM ",
imageAlt: " Garment Texture 100581 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100582 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUKi1RcLpfMzJChbPshNrGXRzawkWt7MHpwAJwHztQFZ8 ",
imageAlt: " Garment Texture 100582 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100583 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTfS3S99nKhUWMgt7ufPhV7CKWekMFBg7mXak2S24eZx5 ",
imageAlt: " Garment Texture 100583 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100584 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmew65QekSQEg61niesCw87YLvSCpy1WRBiHaDp6FepWiF ",
imageAlt: " Garment Texture 100584 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100585 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmP73UmcwjCsk6SPLg3ALqetHYmLZhsuapdMskmUTZWp77 ",
imageAlt: " Garment Texture 100585 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100586 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmbsNpALcXqqPb6d1qkvNbMqobpD7duUFoPNpUtKMRrvmv ",
imageAlt: " Garment Texture 100586 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100587 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmcdAJ1BDLTV1CTfnaX7Aj2hdsfrkG41uLUKwHg2takW3i ",
imageAlt: " Garment Texture 100587 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100588 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSxfSWnCPC4wReGGNw45k14KpDwCj32mYLWf1b8Ke3gsH ",
imageAlt: " Garment Texture 100588 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100589 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmXVGGm1uUpuABAZa8ooPpufizNTRcgQ9iECMtGvbgMSPY ",
imageAlt: " Garment Texture 100589 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100590 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmdHdSY4hhukrwfjpMyaSznQMgEKE2Yz2ie1rgppNtnnRo ",
imageAlt: " Garment Texture 100590 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100591 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWWMvRBUZFiKisxuhXBgrcmRUZd3od8trjSmPKb3wBDAW ",
imageAlt: " Garment Texture 100591 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100592 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZMftkcTxMdqG4NfCCdu5vwKxGgvYXmm5H4RrSucWgynf ",
imageAlt: " Garment Texture 100592 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100593 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSYifUgcTskiZKhzL38Ut4fWsoompd2QsQyetdJgxfG9Y ",
imageAlt: " Garment Texture 100593 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100594 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmavuk2gMuMCgaR5bafKwwVs2yCgzUUU5Roc2HXmxa2Jb3 ",
imageAlt: " Garment Texture 100594 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100595 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmagiw8h63Bk8uvwV2qH2GraEXdXf8nscnY3g2mXAmFRvB ",
imageAlt: " Garment Texture 100595 ",
skills: 1,
guilds: 1,
title: "Phoebe Heess",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100596 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZzDgj9AZb8RAhqCWwhH7NjrNw6FWTJYaZCemLjd8p2PM ",
imageAlt: " Garment Texture 100596 ",
skills: 1,
guilds: 1,
title: "Tiny Terror",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100597 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmTTyrYKW2F8XdVeeEfxeV7m6PBsUnzBGxR5xfqCsxBTFd ",
imageAlt: " Garment Texture 100597 ",
skills: 1,
guilds: 1,
title: "Tiny Terror",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100598 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmaQvftT77cQxfTJxUgrCvZd7mhVUZQWH5FBBeszyMtUQs ",
imageAlt: " Garment Texture 100598 ",
skills: 1,
guilds: 1,
title: "Tiny Terror",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100599 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmYy4nNBm5RjsuXhrHqtFrRGiR23w1kQHnS5fpwQz3VPFq ",
imageAlt: " Garment Texture 100599 ",
skills: 1,
guilds: 1,
title: "Tiny Terror",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100600 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmW4iBTdJ7eq54ePVUbygd5AwP8DXgybErSsjJssUJcpRw ",
imageAlt: " Garment Texture 100600 ",
skills: 1,
guilds: 1,
title: "Tiny Terror",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100601 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmY5uLEZwgZSUDsDiQML91AKYBAfu8Dm3bkaTMHfmksZRo ",
imageAlt: " Garment Texture 100601 ",
skills: 1,
guilds: 1,
title: "Oli3D",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100602 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZ2pqxz7qgbfaaE9VLCCjc7Pub9ptPRAVFJCEmZ6dwo5T ",
imageAlt: " Garment Texture 100602 ",
skills: 1,
guilds: 1,
title: "DomingoBeta",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100603 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmV227yoaHKi3YJ8fjD1uW8apUtD8ktUeVhGJupDv1GnYH ",
imageAlt: " Garment Texture 100603 ",
skills: 1,
guilds: 1,
title: "DomingoBeta",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100604 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWzHonntkUxYajmNUVGoGGdikJxvA3voHSj9nmeSjtTue ",
imageAlt: " Garment Texture 100604 ",
skills: 1,
guilds: 1,
title: "DomingoBeta",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100605 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmWZovXUTFDWyYb6saFfU72fKcdoE2VQVWyYucuYR56gns ",
imageAlt: " Garment Texture 100605 ",
skills: 1,
guilds: 1,
title: "DomingoBeta",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100606 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPPMAsgQCJ1A9gVcx6svhDDmwfXhai8sHsxGF1Geqou4g ",
imageAlt: " Garment Texture 100606 ",
skills: 1,
guilds: 1,
title: "DomingoBeta",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100607 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVau8bKs5iQowofdXKxhRi53eLGEutQue3rbArHugrrjo ",
imageAlt: " Garment Texture 100607 ",
skills: 1,
guilds: 1,
title: "DomingoBeta",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100608 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmeqLsjs73BLW46foeXDNtDQhM5NoquPoJM4cQYzJvgozx ",
imageAlt: " Garment Texture 100608 ",
skills: 1,
guilds: 1,
title: "DomingoBeta",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100609 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmapSfZg1EAJHsgHZv1XsWzxy2mnoBSAk77ysGTiFggVt2 ",
imageAlt: " Garment Texture 100609 ",
skills: 1,
guilds: 1,
title: "DomingoBeta",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100610 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qmc3dJruoveDbGTcBmTrwqFGXkJU9QrNBH752YiCEN1xX1 ",
imageAlt: " Garment Texture 100610 ",
skills: 1,
guilds: 1,
title: "DomingoBeta",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100611 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmNnpvUoYmkT1qap2FdsHmewMAM6TWY922eZyGPWSgu2kP ",
imageAlt: " Garment Texture 100611 ",
skills: 1,
guilds: 1,
title: "DomingoBeta",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100621 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmcUSXeATfQRzpias9aAkcwFapYFKcnGdmmhTBvBMKyWhW ",
imageAlt: " Garment Texture 100621 ",
skills: 1,
guilds: 1,
title: "Mo'Casso",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100622 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSHjnjJxyPDZBruM6REsfXVhgYG8SzqEfNNdHgg2jXY6N ",
imageAlt: " Garment Texture 100622 ",
skills: 1,
guilds: 1,
title: "Mo'Casso",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100623 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPyDCUdcFt44MTqx3sWc4jD8YUHSRRTGHa5P85ZwFSmfv ",
imageAlt: " Garment Texture 100623 ",
skills: 1,
guilds: 1,
title: "Mo'Casso",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100624 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmazRKHpCcvqu5djGo72ygdBbknJPFxg9kpmiwSMbXixXe ",
imageAlt: " Garment Texture 100624 ",
skills: 1,
guilds: 1,
title: "Mo'Casso",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100625 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmeaF2xcy3Zc9b6qJhWYwxuoFvMuKHqLzxMmjGcS7qSDgy ",
imageAlt: " Garment Texture 100625 ",
skills: 1,
guilds: 1,
title: "Mo'Casso",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100626 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQfJeHzyLiNAj2PsyT5V7spna9um91u2Nk7e1betx8oiq ",
imageAlt: " Garment Texture 100626 ",
skills: 1,
guilds: 1,
title: "Mo'Casso",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100627 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVxzWurbpVEiopsa6XevQnjSgmtXtNtMZAcMVQAARkka3 ",
imageAlt: " Garment Texture 100627 ",
skills: 1,
guilds: 1,
title: "Mo'Casso",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100628 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUe6WrCpHZsqP6BfW4junPZdvM2nfyUQT5uVV8ieczNQP ",
imageAlt: " Garment Texture 100628 ",
skills: 1,
guilds: 1,
title: "Mo'Casso",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100629 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmQUrpLEi8mmWaCSUAVCQjeihZyN2GgbKrk2EDWmEg9ViL ",
imageAlt: " Garment Texture 100629 ",
skills: 1,
guilds: 1,
title: "Mo'Casso",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};

































const token100633 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmT2tRM6UhBc86YqvEWsUJUA2AX9t6msshDvCu4ZDzVBgf ",
imageAlt: " Garment Texture 100633 ",
skills: 1,
guilds: 1,
title: "ceejay",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100634 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVnK1TESFuTCPQepL4dNUJqf18fUYME5mAXnGW8g55N1J ",
imageAlt: " Garment Texture 100634 ",
skills: 1,
guilds: 1,
title: "ceejay",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100635 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qme2963KcBrEejGB35Pcbbro6oQHm38CFg37Xb6WDoZxoy ",
imageAlt: " Garment Texture 100635 ",
skills: 1,
guilds: 1,
title: "ceejay",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100636 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmeUMLsPCpAVeAn8FeB9qNvxSPokJnQ7MFQK7UtqWbawbj ",
imageAlt: " Garment Texture 100636 ",
skills: 1,
guilds: 1,
title: "ceejay",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100637 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPMeaqwhXLxZahgkdFi1ZTugK4Cx4wxmBA2foQi4yduUq ",
imageAlt: " Garment Texture 100637 ",
skills: 1,
guilds: 1,
title: "ceejay",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100638 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmXXHxXYqgjTAchTAFokQWEUGXC6H1ze8whdvdrWqjJ9k6 ",
imageAlt: " Garment Texture 100638 ",
skills: 1,
guilds: 1,
title: "ceejay",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100639 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmUpxkbkQY3orNLsYxeipYtza1nvzf8aNQjnGQPtQ4gPYa ",
imageAlt: " Garment Texture 100639 ",
skills: 1,
guilds: 1,
title: "amy3designer",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100640 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPs2UuywDaWJCMiUfPeifCfX5iGFt8BHAMu1X41LyxeU2 ",
imageAlt: " Garment Texture 100640 ",
skills: 1,
guilds: 1,
title: "amy3designer",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};






















const token100643 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSA8BJ6cAVwKkc9LpqF1t5btmzuVC3G8FRz9kCk34Y9yC ",
imageAlt: " Garment Texture 100643 ",
skills: 1,
guilds: 1,
title: "Zevenchy5",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100644 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmXH6v6o1iJrvXjcdx1nkvZMBz2CunL3UmuAYqBH7nzek5 ",
imageAlt: " Garment Texture 100644 ",
skills: 1,
guilds: 1,
title: "Zevenchy5",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100645 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmXFRba2VvtpoTEuyhND2VpRJUyDZfQRC3Zeb4mnq8z2bu ",
imageAlt: " Garment Texture 100645 ",
skills: 1,
guilds: 1,
title: "Zevenchy5",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100646 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSsPnkJUbESFWvCXwX7HKnwQLq8mRtF9t5aXmHwJRcraS ",
imageAlt: " Garment Texture 100646 ",
skills: 1,
guilds: 1,
title: "amy3designer",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100647 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVKaQyDHqKTrfVm1c2xLMS1Yy7kMPu4rR3UjNk2ETgPD9 ",
imageAlt: " Garment Texture 100647 ",
skills: 1,
guilds: 1,
title: "amy3designer",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100648 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmbZFMVVScqT5pxqV6KnfUDWjrg7CPC6YJkhzWksdFJNey ",
imageAlt: " Garment Texture 100648 ",
skills: 1,
guilds: 1,
title: "amy3designer",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100649 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVxUQTHxhqWpqePnrQ3mEnYFpqB4y9oyReEQweowKkVwA ",
imageAlt: " Garment Texture 100649 ",
skills: 1,
guilds: 1,
title: "amy3designer",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100650 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmNrKNqPHRxA4pzWHE51gCKanxELREtMbtRcdrcsyARNKs ",
imageAlt: " Garment Texture 100650 ",
skills: 1,
guilds: 1,
title: "amy3designer",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100651 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmULsgzPT3ZQEs81pVQBRtdArhzqc7aEpCWQWK58qsiUu4 ",
imageAlt: " Garment Texture 100651 ",
skills: 1,
guilds: 1,
title: "amy3designer",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100652 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmNwCjQgh2EBpcindt3ZrjYkWKmc71MGpsqdCiAY8UAAt6 ",
imageAlt: " Garment Texture 100652 ",
skills: 1,
guilds: 1,
title: "ALMAKS",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100653 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmVYDCw9A68ZzzS5qxfXW2p63dC6px8Upqqo4y7LwnLUqs ",
imageAlt: " Garment Texture 100653 ",
skills: 1,
guilds: 1,
title: "XRtelier",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100654 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmRY3CG2fSmc7Fjx36CNhZk12vGsNQCcQkxh7dgE5yH3mn ",
imageAlt: " Garment Texture 100654 ",
skills: 1,
guilds: 1,
title: "Sabimage",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100655 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmcvMEMt2jGfQjkTzBHyMqUU2qbs9nvHGHvogu7qQuP5Kk ",
imageAlt: " Garment Texture 100655 ",
skills: 1,
guilds: 1,
title: "Sabimage",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100656 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZBXQpG4dVp67EmSQib3ttrVMhm9HHe9Qs1o2JoRzh8dc ",
imageAlt: " Garment Texture 100656 ",
skills: 1,
guilds: 1,
title: "Sabimage",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100657 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmfKri5MgmEz6DT3kKZTzb3oNfP2KGhSd3BKKtWprKZxwA ",
imageAlt: " Garment Texture 100657 ",
skills: 1,
guilds: 1,
title: "Sabimage",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100658 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmSjewBSnnD4hcpLY33A8s8LFRzZAYcvLtMKYaDwaA4L2P ",
imageAlt: " Garment Texture 100658 ",
skills: 1,
guilds: 1,
title: "Mocasso",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100659 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPc4vjx5pbyudWxKcma5MxgRj2R4RYY12J7KrZPffMnFH ",
imageAlt: " Garment Texture 100659 ",
skills: 1,
guilds: 1,
title: "Mocasso",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100660 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmdESET4f8Y2a1pmTSRDB1Zn6ARAesNYfTRj86nj99Drid ",
imageAlt: " Garment Texture 100660 ",
skills: 1,
guilds: 1,
title: "Garcia Galleries",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100661 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmewTHT2jrgsdBVNrjKa7VyypRF6dNR8QUzVhQByA3855X ",
imageAlt: " Garment Texture 100661 ",
skills: 1,
guilds: 1,
title: "Garcia Galleries",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100662 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/Qma7LKF64n7yD2DuR9Z7J1tKzFVtWJy9Co4qbGJQHWvFGE ",
imageAlt: " Garment Texture 100662 ",
skills: 1,
guilds: 1,
title: "Garcia Galleries",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100663 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmYBEWJUixFf9wzyHmrY6GuGnv69FWeAskRzAhsJd5Qyp5 ",
imageAlt: " Garment Texture 100663 ",
skills: 1,
guilds: 1,
title: "Garcia Galleries",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100664 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmPUMAY4wocMhALmaz1SGRY5p2n51jmk8TMB2KPEnkcmX9 ",
imageAlt: " Garment Texture 100664 ",
skills: 1,
guilds: 1,
title: "Garcia Galleries",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100665 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmXCh8TT3yAmwz3Cc1ngcjSkCZ69uuhEUtdedWq7wiQjx7 ",
imageAlt: " Garment Texture 100665 ",
skills: 1,
guilds: 1,
title: "XRtelier",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};











const token100667 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmcHLTYSHfWa37pcPAMwBpS4ciXVk3mCdFACXNpxjkns1C ",
imageAlt: " Garment Texture 100667 ",
skills: 1,
guilds: 1,
title: "Iana van Aken",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100668 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmS5DJBvn9rQ6TewRnqLefVbvx8rjFVdbq6NPkXZPY67bV ",
imageAlt: " Garment Texture 100668 ",
skills: 1,
guilds: 1,
title: "underinsight",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};
const token100669 = {
imageUrl:
" https://freeweb3.infura-ipfs.io/ipfs/QmZwBnU9umuHtALWjwDKMX48pzWpiCD2TrmkM4tb4RwaqG ",
imageAlt: " Garment Texture 100669 ",
skills: 1,
guilds: 1,
title: "underinsight",
formattedPrice: " MONA ",
reviewCount: 10,
rating: 4,
};















































































































































































































































































































































































































































































































return (
<div style={{ backgroundImage: `url(${background})` }}>
<SimpleGrid minChildWidth="200px" mr="5" spacing="10px">




































































































































































































































































































































































































































































































































































































































































































































































































































































































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100016.imageUrl} alt={token100016.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100016
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100016.skills} skills &bull; {token100016.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100016.title}
</Box>
<Box color="yellow.500">
{token100016.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100016.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100016
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmdCFd6fb9gJ5LaMtMuZZ7RSy13oaCzKytXjro9KQ87BnK' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100017.imageUrl} alt={token100017.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100017
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100017.skills} skills &bull; {token100017.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100017.title}
</Box>
<Box color="yellow.500">
{token100017.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100017.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100017
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmfL8j18Lyd5xVMmJbefwovBfvX4aBWUb4QBT9MZKeNGdx' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100018.imageUrl} alt={token100018.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100018
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100018.skills} skills &bull; {token100018.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100018.title}
</Box>
<Box color="yellow.500">
{token100018.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100018.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100018
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmT178U2vDesEaaDVFCJno9wEaLMDF5ed8ZkbZpAqsPtWm' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>






















































































































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100021.imageUrl} alt={token100021.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100021
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100021.skills} skills &bull; {token100021.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100021.title}
</Box>
<Box color="yellow.500">
{token100021.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100021.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100021
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmW9JXvF4DJbgD4EoWCWcLKYgdymjFWqQvwThDx3eSzB49' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100022.imageUrl} alt={token100022.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100022
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100022.skills} skills &bull; {token100022.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100022.title}
</Box>
<Box color="yellow.500">
{token100022.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100022.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100022
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTfAunpGVf8MUW93RSpeDQAimPEBAbaw6fM2PQ7A933g5' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100023.imageUrl} alt={token100023.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100023
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100023.skills} skills &bull; {token100023.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100023.title}
</Box>
<Box color="yellow.500">
{token100023.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100023.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100023
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWy8QhyVhAp3VRYknxySY8jFNU7EGYoV9Zzh9DyKQMaXr' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100025.imageUrl} alt={token100025.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100025
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100025.skills} skills &bull; {token100025.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100025.title}
</Box>
<Box color="yellow.500">
{token100025.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100025.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100025
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmcuewbSbsVnoLRSeMobjdFWWXC4q26rzBwQRdL2u7rp4J' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100026.imageUrl} alt={token100026.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100026
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100026.skills} skills &bull; {token100026.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100026.title}
</Box>
<Box color="yellow.500">
{token100026.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100026.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100026
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmVgZUuqkGTZhCwyhmSsUWfr3AnT9e5Y3EEEYZUDM9B3t3' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100027.imageUrl} alt={token100027.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100027
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100027.skills} skills &bull; {token100027.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100027.title}
</Box>
<Box color="yellow.500">
{token100027.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100027.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100027
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmcJjcjNAVffq4kd9eRtnbWSTCbPJ9ZQhCcoyFgERV9Kcr' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100029.imageUrl} alt={token100029.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100029
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100029.skills} skills &bull; {token100029.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100029.title}
</Box>
<Box color="yellow.500">
{token100029.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100029.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100029
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmUbw2TNrjnRDaSnBi7EqUg6EQBaez79XzUe22UUBWP4NY' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100030.imageUrl} alt={token100030.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100030
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100030.skills} skills &bull; {token100030.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100030.title}
</Box>
<Box color="yellow.500">
{token100030.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100030.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100030
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmUYYv3xtvqvfyvBvojPTc8WfViGmbcVXNAVichHAP2QKL' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100031.imageUrl} alt={token100031.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100031
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100031.skills} skills &bull; {token100031.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100031.title}
</Box>
<Box color="yellow.500">
{token100031.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100031.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100031
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmcu5mvqtMAjwkeGWvySR38YAmn99dxd1pVurUavnymwwc' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100032.imageUrl} alt={token100032.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100032
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100032.skills} skills &bull; {token100032.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100032.title}
</Box>
<Box color="yellow.500">
{token100032.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100032.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100032
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmUKyy8nWHzmzwu2q94opc4mfDBRhrSiZUdtyvxJuhd2EY' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100033.imageUrl} alt={token100033.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100033
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100033.skills} skills &bull; {token100033.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100033.title}
</Box>
<Box color="yellow.500">
{token100033.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100033.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100033
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSyX2braurpehtAq4BudjBC6q3J8bbMwfAbrTapw6nv6X' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100034.imageUrl} alt={token100034.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100034
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100034.skills} skills &bull; {token100034.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100034.title}
</Box>
<Box color="yellow.500">
{token100034.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100034.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100034
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYXjeR5UyVsCM4nYukktCbcy3puBYZVSGgKD1aEkyJqU7' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>

































































































































































































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100038.imageUrl} alt={token100038.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100038
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100038.skills} skills &bull; {token100038.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100038.title}
</Box>
<Box color="yellow.500">
{token100038.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100038.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100038
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmUhix1SdJUZF44ekyV79AsA13M8M8G87LV1RTvtuM4rtF' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100039.imageUrl} alt={token100039.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100039
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100039.skills} skills &bull; {token100039.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100039.title}
</Box>
<Box color="yellow.500">
{token100039.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100039.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100039
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmVbqhqEixX8GKUJynwVyZekcC45J4KYQZog2vwPsdvatP' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100041.imageUrl} alt={token100041.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100041
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100041.skills} skills &bull; {token100041.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100041.title}
</Box>
<Box color="yellow.500">
{token100041.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100041.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100041
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmXzxzu282VDURLnhpdj9dHL8Tb1WWKuW9Q8eiJ7GhqPHe' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100042.imageUrl} alt={token100042.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100042
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100042.skills} skills &bull; {token100042.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100042.title}
</Box>
<Box color="yellow.500">
{token100042.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100042.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100042
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZtDYxJKzp3DAfBi59Axo12it835W6PBmfty1S7jpRvYH' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100043.imageUrl} alt={token100043.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100043
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100043.skills} skills &bull; {token100043.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100043.title}
</Box>
<Box color="yellow.500">
{token100043.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100043.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100043
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmbqh6mKNuquVe9e5S8YhJGSvv1SMqsEhB859PyTDoVyKM' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100044.imageUrl} alt={token100044.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100044
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100044.skills} skills &bull; {token100044.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100044.title}
</Box>
<Box color="yellow.500">
{token100044.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100044.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100044
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYzdhZrmkZvfjeKN12yRSVXkhiNMzSRJBCj45QnHVqdfy' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100048.imageUrl} alt={token100048.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100048
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100048.skills} skills &bull; {token100048.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100048.title}
</Box>
<Box color="yellow.500">
{token100048.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100048.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100048
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmee3BZnfU3rGnzWtBtEN9MMFa9PhKsSSMGGYSMnu3Xy9K' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100049.imageUrl} alt={token100049.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100049
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100049.skills} skills &bull; {token100049.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100049.title}
</Box>
<Box color="yellow.500">
{token100049.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100049.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100049
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYF9V1eWbkJjhW38SJUShhYdV6TG2xo7Vmw5i5KPJmok9' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100050.imageUrl} alt={token100050.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100050
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100050.skills} skills &bull; {token100050.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100050.title}
</Box>
<Box color="yellow.500">
{token100050.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100050.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100050
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmc3GKjCSWDsTeEn3kJeNUi34JUZwuLPCwQou3uMBBxYyM' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100051.imageUrl} alt={token100051.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100051
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100051.skills} skills &bull; {token100051.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100051.title}
</Box>
<Box color="yellow.500">
{token100051.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100051.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100051
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTHhEECrWptMwDykQtHea2JRxB7kGCfVAbjpumaxuYTRW' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100052.imageUrl} alt={token100052.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100052
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100052.skills} skills &bull; {token100052.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100052.title}
</Box>
<Box color="yellow.500">
{token100052.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100052.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100052
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQxfDNsxQNApBpCYjPgJak6CtxWo7r1dXV18VfPbkiZhK' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100053.imageUrl} alt={token100053.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100053
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100053.skills} skills &bull; {token100053.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100053.title}
</Box>
<Box color="yellow.500">
{token100053.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100053.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100053
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmU1A5EKy7HD6ASiCvU4cJLCpWZjjXgyNgxrsNTT8XKRhx' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100054.imageUrl} alt={token100054.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100054
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100054.skills} skills &bull; {token100054.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100054.title}
</Box>
<Box color="yellow.500">
{token100054.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100054.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100054
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qma2WMaH6AvG83Y8RBY8kBtcNq64MY6gz7gsxJGJXk7NiA' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100055.imageUrl} alt={token100055.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100055
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100055.skills} skills &bull; {token100055.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100055.title}
</Box>
<Box color="yellow.500">
{token100055.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100055.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100055
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmaaJ9DoevuKb5tXj61FgrsucZ43WvTktVri86zZkuQj4n' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100056.imageUrl} alt={token100056.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100056
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100056.skills} skills &bull; {token100056.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100056.title}
</Box>
<Box color="yellow.500">
{token100056.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100056.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100056
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSEVmZpFys5kFhoSdsqz1scqcYugWpJFcpUDLGxta1cPN' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100057.imageUrl} alt={token100057.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100057
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100057.skills} skills &bull; {token100057.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100057.title}
</Box>
<Box color="yellow.500">
{token100057.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100057.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100057
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmdcF5d4Bb2t7GUDbq4pMvjkHpn5QZrCnRB9SoCnCKGsRo' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100058.imageUrl} alt={token100058.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100058
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100058.skills} skills &bull; {token100058.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100058.title}
</Box>
<Box color="yellow.500">
{token100058.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100058.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100058
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmUe4cYXHRSKEMcYoeX85jepnRX1zdy6pBsZdKM6add2gV' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100059.imageUrl} alt={token100059.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100059
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100059.skills} skills &bull; {token100059.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100059.title}
</Box>
<Box color="yellow.500">
{token100059.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100059.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100059
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmcPc9q9AcBrqHny2pqKd7Lat9tB7XUdMWLnMXiZ1PHGqd' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100060.imageUrl} alt={token100060.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100060
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100060.skills} skills &bull; {token100060.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100060.title}
</Box>
<Box color="yellow.500">
{token100060.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100060.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100060
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmXL3U7shCX38T1owymB8SVgF3PDxcDkDD5AzUhiESHxmW' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100061.imageUrl} alt={token100061.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100061
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100061.skills} skills &bull; {token100061.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100061.title}
</Box>
<Box color="yellow.500">
{token100061.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100061.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100061
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQ19kaQq6XkUFwLT4JAgTreWLawkRNtG8FXxR1f78came' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100062.imageUrl} alt={token100062.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100062
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100062.skills} skills &bull; {token100062.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100062.title}
</Box>
<Box color="yellow.500">
{token100062.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100062.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100062
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmduScQGR1WoKysb7cyTckBxB7DB2UnBc7ABG5XgDD6gmt' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100063.imageUrl} alt={token100063.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100063
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100063.skills} skills &bull; {token100063.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100063.title}
</Box>
<Box color="yellow.500">
{token100063.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100063.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100063
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmaaH7rhStTPHuVZzhKTTc3VvFFZm2jiGBsFJLmvKs7FiF' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100064.imageUrl} alt={token100064.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100064
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100064.skills} skills &bull; {token100064.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100064.title}
</Box>
<Box color="yellow.500">
{token100064.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100064.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100064
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZhoFtghnaiiB3ax6eRnismXYMoUJWtLqt2XpnfqWKCUT' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100065.imageUrl} alt={token100065.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100065
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100065.skills} skills &bull; {token100065.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100065.title}
</Box>
<Box color="yellow.500">
{token100065.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100065.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100065
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWTv1JMaSBw4VJQJ1eTafSTvCVzE3ZfGwoFFc6qCUeGUk' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100066.imageUrl} alt={token100066.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100066
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100066.skills} skills &bull; {token100066.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100066.title}
</Box>
<Box color="yellow.500">
{token100066.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100066.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100066
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTC5U29avqPfTRStQhUdBhoqfKEaAj5Rh3kNuhuCoP2dp' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100067.imageUrl} alt={token100067.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100067
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100067.skills} skills &bull; {token100067.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100067.title}
</Box>
<Box color="yellow.500">
{token100067.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100067.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100067
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmP7MwX9bU3fFuikJPKdVu2imLL9MavYNdRfUvMiJ23gjd' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100068.imageUrl} alt={token100068.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100068
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100068.skills} skills &bull; {token100068.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100068.title}
</Box>
<Box color="yellow.500">
{token100068.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100068.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100068
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYCe1PVWH1m7xZSoYFqroAnhmRbTrxSykUugQPjQuGfEs' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100069.imageUrl} alt={token100069.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100069
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100069.skills} skills &bull; {token100069.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100069.title}
</Box>
<Box color="yellow.500">
{token100069.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100069.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100069
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmeVeK4wGv2AhZZtFaXCpoAbjCv7CC71yooTbjEgA1FWGJ' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100070.imageUrl} alt={token100070.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100070
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100070.skills} skills &bull; {token100070.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100070.title}
</Box>
<Box color="yellow.500">
{token100070.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100070.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100070
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYgsC1BeoqWXjwVryKSHhi7YdMf2q66CCMyDGAE15TyX8' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100071.imageUrl} alt={token100071.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100071
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100071.skills} skills &bull; {token100071.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100071.title}
</Box>
<Box color="yellow.500">
{token100071.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100071.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100071
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmafKSe78EY4PtapxpoD3oZA8Ye44cqnzzYY48UFhsm7tb' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100072.imageUrl} alt={token100072.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100072
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100072.skills} skills &bull; {token100072.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100072.title}
</Box>
<Box color="yellow.500">
{token100072.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100072.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100072
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQFsL2w4yB6pJMLsbhnpAN8ns9v8UL2RhAAjSQzaQw4aJ' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100073.imageUrl} alt={token100073.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100073
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100073.skills} skills &bull; {token100073.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100073.title}
</Box>
<Box color="yellow.500">
{token100073.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100073.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100073
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmXpwHAqRV9BsLUngauNWzhMx8ndLLw6nzbHNyKc97Jm7g' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100074.imageUrl} alt={token100074.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100074
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100074.skills} skills &bull; {token100074.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100074.title}
</Box>
<Box color="yellow.500">
{token100074.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100074.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100074
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmaGQYumgszdxHMzhf2X7GqQPrTW9TpMCpfi5xJR3LRku6' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100075.imageUrl} alt={token100075.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100075
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100075.skills} skills &bull; {token100075.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100075.title}
</Box>
<Box color="yellow.500">
{token100075.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100075.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100075
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZHHBEnWCY5jaqaWkkaF9MJGZgoBdH8ZBNbvgNanFEVie' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100076.imageUrl} alt={token100076.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100076
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100076.skills} skills &bull; {token100076.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100076.title}
</Box>
<Box color="yellow.500">
{token100076.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100076.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100076
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSWghs7kiks7AGZu1wvyYE9F7CpaFBLK15gyqNahCs5BK' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100077.imageUrl} alt={token100077.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100077
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100077.skills} skills &bull; {token100077.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100077.title}
</Box>
<Box color="yellow.500">
{token100077.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100077.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100077
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTYCKcZC95DeqsU7juHaFR9LQnSsAmpL5KnYePtx1ujKZ' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100078.imageUrl} alt={token100078.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100078
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100078.skills} skills &bull; {token100078.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100078.title}
</Box>
<Box color="yellow.500">
{token100078.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100078.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100078
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmUfZiMH1oCEQmaALaek5bYcsnB1s3VhSMU2uTw4PRpwtR' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>












































































































































































































































































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100083.imageUrl} alt={token100083.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100083
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100083.skills} skills &bull; {token100083.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100083.title}
</Box>
<Box color="yellow.500">
{token100083.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100083.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100083
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmXLnEiWWrJkmygjq7oV4r4tkK3ejZ4C14nhLU6zGqPYSa' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100085.imageUrl} alt={token100085.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100085
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100085.skills} skills &bull; {token100085.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100085.title}
</Box>
<Box color="yellow.500">
{token100085.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100085.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100085
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTVRS3kcvjYQhbwitepHQrNEkxmfKwPSPRURuU2qnjtA6' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100086.imageUrl} alt={token100086.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100086
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100086.skills} skills &bull; {token100086.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100086.title}
</Box>
<Box color="yellow.500">
{token100086.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100086.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100086
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmUr1NHDa2hanx3HkQLQMm4iRa1shJN3brumeDCyy769c7' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100087.imageUrl} alt={token100087.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100087
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100087.skills} skills &bull; {token100087.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100087.title}
</Box>
<Box color="yellow.500">
{token100087.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100087.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100087
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSHxvpedQKr1LpyC5tgxyoWYhEY38Nm5y1Ze7H8fbNDFr' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100088.imageUrl} alt={token100088.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100088
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100088.skills} skills &bull; {token100088.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100088.title}
</Box>
<Box color="yellow.500">
{token100088.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100088.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100088
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmPeANRqG9nb8UJBpux953nrKSrvTPV23pMftn8QiEpAQU' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100089.imageUrl} alt={token100089.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100089
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100089.skills} skills &bull; {token100089.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100089.title}
</Box>
<Box color="yellow.500">
{token100089.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100089.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100089
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmemK9i2xCLEH9DcXvphnumMCNqvD4WkH9apcSUNVUcd1j' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100090.imageUrl} alt={token100090.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100090
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100090.skills} skills &bull; {token100090.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100090.title}
</Box>
<Box color="yellow.500">
{token100090.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100090.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100090
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmNT4PhRPcndUXwgLqCaSLYK8DR14X9bLx2544TFhy1KSX' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100091.imageUrl} alt={token100091.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100091
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100091.skills} skills &bull; {token100091.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100091.title}
</Box>
<Box color="yellow.500">
{token100091.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100091.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100091
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmagodgyfUFKCpe3i41wqSeP7TQzSGh8t7UBvVw3yZzSkA' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100092.imageUrl} alt={token100092.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100092
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100092.skills} skills &bull; {token100092.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100092.title}
</Box>
<Box color="yellow.500">
{token100092.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100092.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100092
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSxj5DCYeDmiedZhH1c5gbAZv16wXsGazEibJ22Pi5uDv' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100093.imageUrl} alt={token100093.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100093
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100093.skills} skills &bull; {token100093.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100093.title}
</Box>
<Box color="yellow.500">
{token100093.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100093.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100093
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZUeDmG5rjwjBxCuqDhyh9vDEi6ei7CiAHcGc1bJNH7Lj' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100094.imageUrl} alt={token100094.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100094
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100094.skills} skills &bull; {token100094.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100094.title}
</Box>
<Box color="yellow.500">
{token100094.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100094.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100094
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZhEkdhL75WdRu7dnJZNf2m8BTck3UM2XokL49R3ibBJg' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>






















































































































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100097.imageUrl} alt={token100097.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100097
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100097.skills} skills &bull; {token100097.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100097.title}
</Box>
<Box color="yellow.500">
{token100097.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100097.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100097
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQRfyv2iTpHW3mvUmeEqM9swJuWm69G7XzVp52to1wPng' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100098.imageUrl} alt={token100098.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100098
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100098.skills} skills &bull; {token100098.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100098.title}
</Box>
<Box color="yellow.500">
{token100098.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100098.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100098
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTAXUdorQs4QrZLjhJT5Bgq4XmpepK82Bk5FiaDhi61oG' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100099.imageUrl} alt={token100099.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100099
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100099.skills} skills &bull; {token100099.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100099.title}
</Box>
<Box color="yellow.500">
{token100099.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100099.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100099
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmNhmwvaDMr4WgNdmisk2qKU6gmVzF88ZJTmsjkvGAJC9Z' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100100.imageUrl} alt={token100100.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100100
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100100.skills} skills &bull; {token100100.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100100.title}
</Box>
<Box color="yellow.500">
{token100100.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100100.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100100
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmcxMyWWHaBFsiv41yWJPTfNq2jNAS3wWmz3J4EEEMCvvY' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100101.imageUrl} alt={token100101.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100101
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100101.skills} skills &bull; {token100101.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100101.title}
</Box>
<Box color="yellow.500">
{token100101.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100101.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100101
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYEkyWVSQGPGaLNvaZ2XAEYLWmwym72XEQTAupmv32X6P' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100102.imageUrl} alt={token100102.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100102
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100102.skills} skills &bull; {token100102.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100102.title}
</Box>
<Box color="yellow.500">
{token100102.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100102.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100102
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmfYUY8fdjFvRriA9mkmKqfHEvDb7VxUWBJjAaKctCtFTe' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100103.imageUrl} alt={token100103.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100103
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100103.skills} skills &bull; {token100103.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100103.title}
</Box>
<Box color="yellow.500">
{token100103.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100103.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100103
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRcYvTKQ89WnWRr2mNQz77iAjYHUbYDioRoCBSKESfQ9z' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100104.imageUrl} alt={token100104.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100104
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100104.skills} skills &bull; {token100104.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100104.title}
</Box>
<Box color="yellow.500">
{token100104.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100104.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100104
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmeg5y9jf5KZNaThiZA1wATJ5j2BJp25PRT3uZSSsxkqQL' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100105.imageUrl} alt={token100105.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100105
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100105.skills} skills &bull; {token100105.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100105.title}
</Box>
<Box color="yellow.500">
{token100105.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100105.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100105
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSRLKkywedXgR9NR9JUQB8EJyEsmyRzbPhorNYTKVAPit' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100106.imageUrl} alt={token100106.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100106
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100106.skills} skills &bull; {token100106.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100106.title}
</Box>
<Box color="yellow.500">
{token100106.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100106.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100106
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWntnq9mhnD6BXapwBgdmi2enfQ9wPuRAzJbrDyyZze5L' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100107.imageUrl} alt={token100107.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100107
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100107.skills} skills &bull; {token100107.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100107.title}
</Box>
<Box color="yellow.500">
{token100107.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100107.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100107
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmaqppcVu2a9Np2dXMN8VCv3fSAHgHTcYQBQjMfwx7YPn2' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100108.imageUrl} alt={token100108.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100108
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100108.skills} skills &bull; {token100108.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100108.title}
</Box>
<Box color="yellow.500">
{token100108.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100108.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100108
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSXwSudAN8nXHJKzjR6U8EKEsU6MgUNzVx6kVsAGni4Ts' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100109.imageUrl} alt={token100109.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100109
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100109.skills} skills &bull; {token100109.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100109.title}
</Box>
<Box color="yellow.500">
{token100109.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100109.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100109
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmbNtMgsxuwhBqaJGTwNzZh7qWMZR86JQsuGzTVkMnnwUR' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100110.imageUrl} alt={token100110.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100110
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100110.skills} skills &bull; {token100110.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100110.title}
</Box>
<Box color="yellow.500">
{token100110.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100110.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100110
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qma2ncRSjNFTB6w7to69EGTyHWWaqf6radrT4mfKmR4DGq' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100111.imageUrl} alt={token100111.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100111
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100111.skills} skills &bull; {token100111.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100111.title}
</Box>
<Box color="yellow.500">
{token100111.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100111.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100111
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmU2ddNs2bVyvr4wxWYeVZ9HfQMsEBNxrSkcK8xYyvEC4X' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100112.imageUrl} alt={token100112.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100112
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100112.skills} skills &bull; {token100112.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100112.title}
</Box>
<Box color="yellow.500">
{token100112.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100112.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100112
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSqhmKmdLQDCvHJrULeiBbaZNuPdh3riZfLaUee8XEd7R' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100113.imageUrl} alt={token100113.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100113
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100113.skills} skills &bull; {token100113.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100113.title}
</Box>
<Box color="yellow.500">
{token100113.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100113.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100113
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmT3buXxehpkBUSP1fHLMSJcKb6QWZa2xbDi6Mue3vQAgt' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100114.imageUrl} alt={token100114.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100114
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100114.skills} skills &bull; {token100114.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100114.title}
</Box>
<Box color="yellow.500">
{token100114.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100114.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100114
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQJrUu3c793rpnVNgui6AzsMYRJKQdF6hSxSMNZTnhqXj' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100115.imageUrl} alt={token100115.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100115
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100115.skills} skills &bull; {token100115.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100115.title}
</Box>
<Box color="yellow.500">
{token100115.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100115.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100115
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmNwzLWPQmkpmNUiKjZTiXcQVTaD46WqGn3XoMWhsgytL1' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100116.imageUrl} alt={token100116.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100116
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100116.skills} skills &bull; {token100116.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100116.title}
</Box>
<Box color="yellow.500">
{token100116.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100116.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100116
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmaSpppbKmP9cZD9RWb8dACBBtbmZ9Lx4htcWmNoJGSWWS' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100117.imageUrl} alt={token100117.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100117
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100117.skills} skills &bull; {token100117.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100117.title}
</Box>
<Box color="yellow.500">
{token100117.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100117.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100117
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmdY5n9nxhaq2sWev28XZAiDZhY6gcFa6tg2ixBd4qnod2' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100118.imageUrl} alt={token100118.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100118
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100118.skills} skills &bull; {token100118.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100118.title}
</Box>
<Box color="yellow.500">
{token100118.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100118.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100118
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZeh7PWRYYWqwqkCrVc8W42iSoq2ijBTaDzRdjCfMmFPa' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100119.imageUrl} alt={token100119.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100119
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100119.skills} skills &bull; {token100119.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100119.title}
</Box>
<Box color="yellow.500">
{token100119.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100119.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100119
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qma9fn6hM9XzbLs9bJ4cEyM8cP4kBa3aPc1PtR96Je9KQA' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100120.imageUrl} alt={token100120.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100120
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100120.skills} skills &bull; {token100120.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100120.title}
</Box>
<Box color="yellow.500">
{token100120.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100120.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100120
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmcnFE9P8Sj9TVqfjbsGx8Kf7356sojJ1fqoMA7N7injKL' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100121.imageUrl} alt={token100121.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100121
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100121.skills} skills &bull; {token100121.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100121.title}
</Box>
<Box color="yellow.500">
{token100121.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100121.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100121
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQx88pnLbQv8EsYynT9tH4Rwiiib8esd95EFj6xGvTShm' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100122.imageUrl} alt={token100122.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100122
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100122.skills} skills &bull; {token100122.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100122.title}
</Box>
<Box color="yellow.500">
{token100122.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100122.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100122
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmXvv2fVkcN6qWPRjGKxecvkTdhu8QHBumLRbhavSc7SW7' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100123.imageUrl} alt={token100123.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100123
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100123.skills} skills &bull; {token100123.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100123.title}
</Box>
<Box color="yellow.500">
{token100123.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100123.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100123
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQirMrhYjQhPCAg3Ed4p82FEVqNP4AXkJ7RycLsaz172j' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100125.imageUrl} alt={token100125.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100125
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100125.skills} skills &bull; {token100125.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100125.title}
</Box>
<Box color="yellow.500">
{token100125.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100125.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100125
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWPPKYB2tnMDGbSxZB8Zx2ZcRTAxSUxyFcTj5vk8eoyE2' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>






















































































































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100128.imageUrl} alt={token100128.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100128
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100128.skills} skills &bull; {token100128.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100128.title}
</Box>
<Box color="yellow.500">
{token100128.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100128.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100128
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmUwUq8dyA12Bgamzp34wyh38eBDJevyy6CBM3gvk9DEVK' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100129.imageUrl} alt={token100129.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100129
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100129.skills} skills &bull; {token100129.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100129.title}
</Box>
<Box color="yellow.500">
{token100129.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100129.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100129
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSCwRXTdoiGTAceUn8Ue4TiyKKoydwYZVbvXaPismyYJi' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100130.imageUrl} alt={token100130.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100130
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100130.skills} skills &bull; {token100130.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100130.title}
</Box>
<Box color="yellow.500">
{token100130.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100130.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100130
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRMiLpD2iN7s97pMs8M3BSLcd8znds9cmPvmad7cNyshW' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>






















































































































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100133.imageUrl} alt={token100133.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100133
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100133.skills} skills &bull; {token100133.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100133.title}
</Box>
<Box color="yellow.500">
{token100133.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100133.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100133
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmNRftvuTF74yHFh9Bz816XT4ZnX3CSwynyTDJW2nbvJja' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100135.imageUrl} alt={token100135.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100135
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100135.skills} skills &bull; {token100135.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100135.title}
</Box>
<Box color="yellow.500">
{token100135.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100135.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100135
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmS6HaddHZvfxBgww4eRcjZjA1FcgrrmsTBBH2Gg1M38AX' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100136.imageUrl} alt={token100136.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100136
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100136.skills} skills &bull; {token100136.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100136.title}
</Box>
<Box color="yellow.500">
{token100136.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100136.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100136
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmeJYjAyZmQb66pJtmBEEYs1dDiybde5biLKn6Hi9exnxL' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100137.imageUrl} alt={token100137.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100137
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100137.skills} skills &bull; {token100137.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100137.title}
</Box>
<Box color="yellow.500">
{token100137.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100137.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100137
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZZGdkAsyfQmbpQGESEf5ZndSF3y1SEMRdhCmpCYGhAPr' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100138.imageUrl} alt={token100138.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100138
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100138.skills} skills &bull; {token100138.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100138.title}
</Box>
<Box color="yellow.500">
{token100138.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100138.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100138
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmPofUPVziNzP3tX1yN5fo3ovuesahBcm1vZLcA8o4E3SK' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100139.imageUrl} alt={token100139.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100139
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100139.skills} skills &bull; {token100139.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100139.title}
</Box>
<Box color="yellow.500">
{token100139.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100139.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100139
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmcNiaMCuK8p8ChfmnY81d6n53fNPnXaDEv6bTyhruzxFN' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100140.imageUrl} alt={token100140.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100140
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100140.skills} skills &bull; {token100140.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100140.title}
</Box>
<Box color="yellow.500">
{token100140.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100140.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100140
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmeKKCYJvshwRzoVqBaCSy7YXQFvX6zmyeXcnFP1FmAQpT' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100141.imageUrl} alt={token100141.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100141
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100141.skills} skills &bull; {token100141.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100141.title}
</Box>
<Box color="yellow.500">
{token100141.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100141.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100141
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWZ7yeNehWp34YqASdSeQpknyMTK937Nm6YCceiDoNr3M' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100142.imageUrl} alt={token100142.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100142
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100142.skills} skills &bull; {token100142.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100142.title}
</Box>
<Box color="yellow.500">
{token100142.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100142.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100142
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmf5dAGVoBzcvdtdanFwVWkon5MMZMTZE5uNiBkdF22mWi' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100143.imageUrl} alt={token100143.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100143
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100143.skills} skills &bull; {token100143.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100143.title}
</Box>
<Box color="yellow.500">
{token100143.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100143.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100143
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTuxYowUXTw7pbCS29DeEH8AKu3r8kH9Wxp2vxQsvKx6q' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100144.imageUrl} alt={token100144.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100144
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100144.skills} skills &bull; {token100144.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100144.title}
</Box>
<Box color="yellow.500">
{token100144.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100144.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100144
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmbh96Won2QMxZ9i9j5jrby7PdE4wE6Nqbwm6dmvx8Egnn' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100145.imageUrl} alt={token100145.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100145
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100145.skills} skills &bull; {token100145.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100145.title}
</Box>
<Box color="yellow.500">
{token100145.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100145.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100145
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTB7Gk18ysTQ3dmoKoB2sLYUUKxcKZjRM65VJD1F9pNtA' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100146.imageUrl} alt={token100146.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100146
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100146.skills} skills &bull; {token100146.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100146.title}
</Box>
<Box color="yellow.500">
{token100146.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100146.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100146
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmdgUSimawQ31od171P8WjHw3TyWzqS6ThVSEuddWEqqee' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100147.imageUrl} alt={token100147.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100147
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100147.skills} skills &bull; {token100147.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100147.title}
</Box>
<Box color="yellow.500">
{token100147.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100147.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100147
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmbWFXyNTEkiASKhsg5EYvDbxoXAmFAsH5amareT5G9AVk' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100148.imageUrl} alt={token100148.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100148
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100148.skills} skills &bull; {token100148.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100148.title}
</Box>
<Box color="yellow.500">
{token100148.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100148.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100148
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmdfa1BzmmZCC35w31PvguYGbjonybT4D4xi5WzS7Lu2EW' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100149.imageUrl} alt={token100149.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100149
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100149.skills} skills &bull; {token100149.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100149.title}
</Box>
<Box color="yellow.500">
{token100149.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100149.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100149
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmUUiWpgPuVhPXpuX9M7MNsCrN2ekoyxnupp5r7EMMiiMK' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100150.imageUrl} alt={token100150.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100150
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100150.skills} skills &bull; {token100150.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100150.title}
</Box>
<Box color="yellow.500">
{token100150.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100150.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100150
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmV9N2NirTZLm1438EHPuxzgYRznknZS4BcpZ2vSyvMVXR' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100151.imageUrl} alt={token100151.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100151
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100151.skills} skills &bull; {token100151.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100151.title}
</Box>
<Box color="yellow.500">
{token100151.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100151.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100151
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmUhZBbvMfGJoqLirM1sEtNimKr1XPEPDihdKeZFKGFfoq' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100152.imageUrl} alt={token100152.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100152
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100152.skills} skills &bull; {token100152.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100152.title}
</Box>
<Box color="yellow.500">
{token100152.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100152.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100152
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZfEhGVQve4p714ZfKMaJwaGyDtSxCmbrviKQsopvB5fi' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100153.imageUrl} alt={token100153.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100153
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100153.skills} skills &bull; {token100153.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100153.title}
</Box>
<Box color="yellow.500">
{token100153.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100153.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100153
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYRx41p5Vp2ggSXb6jsFsbtLbU4nykk3vCLHrCHSNsUVw' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100154.imageUrl} alt={token100154.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100154
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100154.skills} skills &bull; {token100154.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100154.title}
</Box>
<Box color="yellow.500">
{token100154.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100154.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100154
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTdkWx43R1G4drpbMSBEP92jx3wHEExaEieZDCCMcQba1' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100155.imageUrl} alt={token100155.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100155
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100155.skills} skills &bull; {token100155.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100155.title}
</Box>
<Box color="yellow.500">
{token100155.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100155.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100155
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmX8VQSnWhnT5fDgdsymsuMhP5VjsVe7Q8P4oqgZcWqb6Y' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100156.imageUrl} alt={token100156.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100156
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100156.skills} skills &bull; {token100156.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100156.title}
</Box>
<Box color="yellow.500">
{token100156.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100156.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100156
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmXFAdDKDE31WHBDMfhiZFpaBwMAmDdJ1XvNZQDsSNkNf2' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100157.imageUrl} alt={token100157.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100157
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100157.skills} skills &bull; {token100157.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100157.title}
</Box>
<Box color="yellow.500">
{token100157.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100157.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100157
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQxa9qzZzypuCnNafSJStq7kS5awkXfqe5kkJZ5W69cch' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100158.imageUrl} alt={token100158.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100158
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100158.skills} skills &bull; {token100158.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100158.title}
</Box>
<Box color="yellow.500">
{token100158.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100158.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100158
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmaAF88m3iyVs4buKxHBWjzgj1DZun2jehXab4k87m1dCp' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100159.imageUrl} alt={token100159.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100159
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100159.skills} skills &bull; {token100159.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100159.title}
</Box>
<Box color="yellow.500">
{token100159.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100159.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100159
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWH6sUYnbHo76ftBFsQ92WxGQPp5DEBLb7JUXVuSMd1D3' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100160.imageUrl} alt={token100160.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100160
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100160.skills} skills &bull; {token100160.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100160.title}
</Box>
<Box color="yellow.500">
{token100160.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100160.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100160
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmcoFh7TCUSRqx4kFsgpv3jHeNoFHpdjFg7a4sQNhLPM59' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100161.imageUrl} alt={token100161.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100161
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100161.skills} skills &bull; {token100161.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100161.title}
</Box>
<Box color="yellow.500">
{token100161.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100161.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100161
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRCdMVsmM2E9Tat2q1qY4pP73scikZTAVDWoXX1RMGwJz' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100162.imageUrl} alt={token100162.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100162
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100162.skills} skills &bull; {token100162.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100162.title}
</Box>
<Box color="yellow.500">
{token100162.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100162.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100162
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmai3cThNYMRsSDNyfYXZS14rG88zoDCxGAWZBd791kafj' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100163.imageUrl} alt={token100163.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100163
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100163.skills} skills &bull; {token100163.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100163.title}
</Box>
<Box color="yellow.500">
{token100163.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100163.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100163
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmf32JykRojzFkwM4Ag7BowZPfpGYHdhadu4iqzqQmF8Yu' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100164.imageUrl} alt={token100164.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100164
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100164.skills} skills &bull; {token100164.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100164.title}
</Box>
<Box color="yellow.500">
{token100164.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100164.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100164
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmX4bYuU6ds3f3dzXtgqx4ewayj64QNqm1bx9hWzRKSP74' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100165.imageUrl} alt={token100165.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100165
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100165.skills} skills &bull; {token100165.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100165.title}
</Box>
<Box color="yellow.500">
{token100165.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100165.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100165
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZN2KWg8obZ1EHxAjjWeRdH6orowUNfYTbzuzQXP1bvXd' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100166.imageUrl} alt={token100166.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100166
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100166.skills} skills &bull; {token100166.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100166.title}
</Box>
<Box color="yellow.500">
{token100166.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100166.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100166
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmX6HeeFTKJ6y4unPci9UabMmg6Y4DmmFnn5JH7tq6b4eE' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100167.imageUrl} alt={token100167.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100167
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100167.skills} skills &bull; {token100167.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100167.title}
</Box>
<Box color="yellow.500">
{token100167.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100167.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100167
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmUXcU5NHa93MrSZ7snYjDdFmQ9LpR4rXZ5mdguBt5yDa2' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100168.imageUrl} alt={token100168.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100168
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100168.skills} skills &bull; {token100168.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100168.title}
</Box>
<Box color="yellow.500">
{token100168.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100168.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100168
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmVGXxXT3X2Td9GtgsiFZRo42jN8G8WPP9CPHE6kECR13n' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100169.imageUrl} alt={token100169.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100169
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100169.skills} skills &bull; {token100169.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100169.title}
</Box>
<Box color="yellow.500">
{token100169.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100169.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100169
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmb5gqtxmokRXbF4uhqh5HCehoPqvqzxsu2Lksdg1qhCm9' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100170.imageUrl} alt={token100170.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100170
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100170.skills} skills &bull; {token100170.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100170.title}
</Box>
<Box color="yellow.500">
{token100170.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100170.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100170
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmV5YguULuFBV2spPyAhxP22xjFKzuaU6MmsTrMAhVj5FB' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100171.imageUrl} alt={token100171.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100171
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100171.skills} skills &bull; {token100171.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100171.title}
</Box>
<Box color="yellow.500">
{token100171.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100171.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100171
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmS57DHr4sMuXMGpJXt8fENxuUD9TgxmXqoEmQmoaLneCY' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100172.imageUrl} alt={token100172.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100172
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100172.skills} skills &bull; {token100172.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100172.title}
</Box>
<Box color="yellow.500">
{token100172.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100172.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100172
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmXxob3PfbL2uUsCtvuvm5ybRW8Qnh2se6fEe7mjAagWjQ' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>






















































































































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100175.imageUrl} alt={token100175.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100175
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100175.skills} skills &bull; {token100175.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100175.title}
</Box>
<Box color="yellow.500">
{token100175.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100175.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100175
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmT42bVX9Stm8aiKwACm1E96eyrNMzZPHEcHWUocPAG1pv' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100176.imageUrl} alt={token100176.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100176
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100176.skills} skills &bull; {token100176.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100176.title}
</Box>
<Box color="yellow.500">
{token100176.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100176.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100176
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmUf56FjXAe3q3rumyRMnZHQK8QAo2zf1eq3Y1nAKWzw2N' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100177.imageUrl} alt={token100177.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100177
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100177.skills} skills &bull; {token100177.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100177.title}
</Box>
<Box color="yellow.500">
{token100177.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100177.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100177
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQmaLjhg8R1jD6uuP8KHpEkhZhS9FF93ABHqypXJBNdh2' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100178.imageUrl} alt={token100178.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100178
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100178.skills} skills &bull; {token100178.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100178.title}
</Box>
<Box color="yellow.500">
{token100178.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100178.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100178
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmeY8ja3YL5QhP2XFbdkx7h9BQ82pD7zmHcLd2j2vpHzb7' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100179.imageUrl} alt={token100179.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100179
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100179.skills} skills &bull; {token100179.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100179.title}
</Box>
<Box color="yellow.500">
{token100179.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100179.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100179
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmaqzhGV2CrizJdDPxVqsVhCMy7dY46Sch6gjZCu2ymNMy' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100180.imageUrl} alt={token100180.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100180
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100180.skills} skills &bull; {token100180.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100180.title}
</Box>
<Box color="yellow.500">
{token100180.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100180.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100180
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmbBLV3vym7QuytEKbi9gMMzcEjAZDJJ3poUjzt1Mp2b5h' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100181.imageUrl} alt={token100181.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100181
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100181.skills} skills &bull; {token100181.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100181.title}
</Box>
<Box color="yellow.500">
{token100181.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100181.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100181
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmUdRZs4P2NpS5MDd97gJ9h7vhDRV39J3sYkg4Jf4DAHwy' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100182.imageUrl} alt={token100182.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100182
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100182.skills} skills &bull; {token100182.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100182.title}
</Box>
<Box color="yellow.500">
{token100182.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100182.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100182
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmdSpgAfpjtPZFcW1Q6a9reZjXrEVSXMdyytmzQiK5jtyr' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100183.imageUrl} alt={token100183.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100183
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100183.skills} skills &bull; {token100183.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100183.title}
</Box>
<Box color="yellow.500">
{token100183.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100183.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100183
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmeHEWPbVv5BEZuiUwpt5YFCGMheUNNZUdmeHmtC7ZwAxS' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100184.imageUrl} alt={token100184.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100184
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100184.skills} skills &bull; {token100184.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100184.title}
</Box>
<Box color="yellow.500">
{token100184.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100184.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100184
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmX4qE6Cf4RyoaM9vsvTk8yFyBbfanmux5nMoHXAa9EyEd' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100185.imageUrl} alt={token100185.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100185
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100185.skills} skills &bull; {token100185.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100185.title}
</Box>
<Box color="yellow.500">
{token100185.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100185.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100185
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYyVDqVZUiag2Vbrn19SiEmyQKrqAmiUN1NQyW7SD98Fr' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100186.imageUrl} alt={token100186.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100186
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100186.skills} skills &bull; {token100186.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100186.title}
</Box>
<Box color="yellow.500">
{token100186.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100186.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100186
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRZJCkVm8XQHkSnt15mqDMcFDLPj6P5FwvtWRruVKZKbh' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100187.imageUrl} alt={token100187.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100187
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100187.skills} skills &bull; {token100187.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100187.title}
</Box>
<Box color="yellow.500">
{token100187.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100187.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100187
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmNX8qfHdBsVbBkLdiF177rE5HvK4fM2CSEroXBG4g8gQA' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100188.imageUrl} alt={token100188.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100188
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100188.skills} skills &bull; {token100188.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100188.title}
</Box>
<Box color="yellow.500">
{token100188.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100188.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100188
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmeeyUZVo6kCkqi4kaZAwgS6Fktg6nWpeAPtADUgUJpMPU' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>

































































































































































































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100192.imageUrl} alt={token100192.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100192
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100192.skills} skills &bull; {token100192.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100192.title}
</Box>
<Box color="yellow.500">
{token100192.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100192.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100192
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmPaN59fNFNnnkUQWqKGqk5Ri5LCSnaPJxK8QqEZUC8jg8' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100193.imageUrl} alt={token100193.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100193
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100193.skills} skills &bull; {token100193.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100193.title}
</Box>
<Box color="yellow.500">
{token100193.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100193.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100193
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmdNrVoLNXRtERbuvipVAoCmekVAMsWRDKf99GiTUec3hG' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100194.imageUrl} alt={token100194.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100194
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100194.skills} skills &bull; {token100194.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100194.title}
</Box>
<Box color="yellow.500">
{token100194.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100194.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100194
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmf1VY2ZKLftuBd4iFXW6pMTAh6B84ZeHZPUXmRAdWqGiV' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100195.imageUrl} alt={token100195.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100195
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100195.skills} skills &bull; {token100195.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100195.title}
</Box>
<Box color="yellow.500">
{token100195.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100195.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100195
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQLtxsLet9wNCn4juaKcuVMYgnU1bX2P2QWNVnmtEj4YV' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100196.imageUrl} alt={token100196.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100196
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100196.skills} skills &bull; {token100196.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100196.title}
</Box>
<Box color="yellow.500">
{token100196.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100196.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100196
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmd6MNurmJbojYUxdug5xSNtKSANhtomGJ8bqqxPSRugUG' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>












































































































































































































































































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100201.imageUrl} alt={token100201.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100201
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100201.skills} skills &bull; {token100201.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100201.title}
</Box>
<Box color="yellow.500">
{token100201.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100201.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100201
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmbCFwLTcqyo1jcemcG1TuLJZ3yvUjjkia9bpbG7kaaBHk' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100202.imageUrl} alt={token100202.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100202
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100202.skills} skills &bull; {token100202.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100202.title}
</Box>
<Box color="yellow.500">
{token100202.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100202.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100202
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmaYULTqZf5o6UFg9gKkCjiYmYMWXvTy6fVdHi4xcwUk8r' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100203.imageUrl} alt={token100203.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100203
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100203.skills} skills &bull; {token100203.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100203.title}
</Box>
<Box color="yellow.500">
{token100203.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100203.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100203
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmd8aQYh4BPDiRhuk89oSVYEErhH6YyyVXbWFjx7uFoZPo' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>






















































































































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100206.imageUrl} alt={token100206.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100206
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100206.skills} skills &bull; {token100206.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100206.title}
</Box>
<Box color="yellow.500">
{token100206.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100206.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100206
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmULJ1KaboPDSbLSLA6HUmVJYCYPgmr5pEdsAn53FjdZS3' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100207.imageUrl} alt={token100207.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100207
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100207.skills} skills &bull; {token100207.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100207.title}
</Box>
<Box color="yellow.500">
{token100207.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100207.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100207
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRxevxRmLB3tE18NNY8z8FQ654qPV5zLTqNbB8oLBLCje' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100208.imageUrl} alt={token100208.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100208
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100208.skills} skills &bull; {token100208.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100208.title}
</Box>
<Box color="yellow.500">
{token100208.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100208.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100208
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQrq1YDPyN1bif9i8dpqEq6kgpTphDF8zS2943rm4aJ6f' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100209.imageUrl} alt={token100209.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100209
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100209.skills} skills &bull; {token100209.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100209.title}
</Box>
<Box color="yellow.500">
{token100209.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100209.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100209
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWxKfE7pfzkRwoZZhWuToQTixwEreB7CMb9HmZzJp9p3A' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100210.imageUrl} alt={token100210.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100210
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100210.skills} skills &bull; {token100210.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100210.title}
</Box>
<Box color="yellow.500">
{token100210.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100210.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100210
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmeEf7XoJpwL2JajBh1PHhybwCGAvSEdJZSbqq1Vv2RJCi' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100211.imageUrl} alt={token100211.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100211
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100211.skills} skills &bull; {token100211.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100211.title}
</Box>
<Box color="yellow.500">
{token100211.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100211.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100211
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmXDbnML9da6XDdWGmrR9deErigEBsoGXbW4xF3mMKXR26' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100212.imageUrl} alt={token100212.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100212
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100212.skills} skills &bull; {token100212.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100212.title}
</Box>
<Box color="yellow.500">
{token100212.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100212.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100212
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmbRM6eJ5piQWCaiNcmGpCCEE5UHNu42xrHUJNsNk4YT1k' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100213.imageUrl} alt={token100213.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100213
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100213.skills} skills &bull; {token100213.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100213.title}
</Box>
<Box color="yellow.500">
{token100213.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100213.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100213
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmVJDfX72q3dcVtAMqsvFAJq7dUCAMesjUAudiWRo2Z5Ez' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100214.imageUrl} alt={token100214.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100214
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100214.skills} skills &bull; {token100214.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100214.title}
</Box>
<Box color="yellow.500">
{token100214.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100214.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100214
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmVQkxExgss7z8RQCCecbBR53SNUtJgP6mZAbqKpEHtWmH' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100215.imageUrl} alt={token100215.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100215
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100215.skills} skills &bull; {token100215.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100215.title}
</Box>
<Box color="yellow.500">
{token100215.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100215.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100215
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmNfbZKc17YN98EA9NU1Qe7KL4Up92NRd8ZJmjPZ271qqu' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100216.imageUrl} alt={token100216.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100216
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100216.skills} skills &bull; {token100216.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100216.title}
</Box>
<Box color="yellow.500">
{token100216.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100216.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100216
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmcMZhEAoDvt7kxrenRCGRA5Kb7E2mcjViojZ5HzfTbQ2Q' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100217.imageUrl} alt={token100217.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100217
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100217.skills} skills &bull; {token100217.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100217.title}
</Box>
<Box color="yellow.500">
{token100217.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100217.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100217
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYSdhb58rpHzv4LjaKg259Nff5EEouHZp6eGE7TxHnun1' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100218.imageUrl} alt={token100218.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100218
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100218.skills} skills &bull; {token100218.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100218.title}
</Box>
<Box color="yellow.500">
{token100218.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100218.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100218
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRygupHF6ztEPnsq2zfa7XUBuDQkq9GMTu83wnZuAZBFE' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100219.imageUrl} alt={token100219.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100219
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100219.skills} skills &bull; {token100219.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100219.title}
</Box>
<Box color="yellow.500">
{token100219.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100219.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100219
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmXAWAz4PS7Qnc4m77Eo8xcJpx6Lzec9EfoBtNNUyCeqqG' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100220.imageUrl} alt={token100220.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100220
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100220.skills} skills &bull; {token100220.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100220.title}
</Box>
<Box color="yellow.500">
{token100220.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100220.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100220
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmeEUHk4avujpyoZ4SiefGutV7YttHn3iYu6bS18RVJHmt' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100221.imageUrl} alt={token100221.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100221
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100221.skills} skills &bull; {token100221.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100221.title}
</Box>
<Box color="yellow.500">
{token100221.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100221.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100221
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmbcdb7Yv1qACebbKkxuEogQhhW5KyM9n9AeWEH7L2gH58' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100222.imageUrl} alt={token100222.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100222
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100222.skills} skills &bull; {token100222.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100222.title}
</Box>
<Box color="yellow.500">
{token100222.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100222.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100222
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmNMjDxReECYEhVW5PFF3mxtN2YsbZMk3wapNwVdXVA2pw' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100224.imageUrl} alt={token100224.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100224
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100224.skills} skills &bull; {token100224.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100224.title}
</Box>
<Box color="yellow.500">
{token100224.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100224.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100224
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYgcygECWio8LLM4Rk5Cz6Ab81SZyNDXDFrSYhmWpDB5g' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100225.imageUrl} alt={token100225.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100225
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100225.skills} skills &bull; {token100225.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100225.title}
</Box>
<Box color="yellow.500">
{token100225.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100225.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100225
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQXYtVnW2fUV2vKhpi2RyVPXNSdXfLDjxMapxKG15tQX6' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100226.imageUrl} alt={token100226.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100226
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100226.skills} skills &bull; {token100226.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100226.title}
</Box>
<Box color="yellow.500">
{token100226.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100226.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100226
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmabfhrn996HorxVXxdNRBGXJFueUbn95DpquAdM9qvXNw' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100227.imageUrl} alt={token100227.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100227
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100227.skills} skills &bull; {token100227.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100227.title}
</Box>
<Box color="yellow.500">
{token100227.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100227.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100227
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQQXrnz99jkGG819RaM5wSzZWPuC6XMGnd8adzvSkvr1P' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100229.imageUrl} alt={token100229.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100229
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100229.skills} skills &bull; {token100229.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100229.title}
</Box>
<Box color="yellow.500">
{token100229.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100229.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100229
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmcvMgHfGVEPHC3Ao17YQFQmTX6jWcAJkJNVJiKUibM7Bf' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100230.imageUrl} alt={token100230.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100230
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100230.skills} skills &bull; {token100230.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100230.title}
</Box>
<Box color="yellow.500">
{token100230.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100230.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100230
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmThyb3AHUaYzKkxmAgXub12WHrsRaLvoFPrnHFSzF5xFm' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100231.imageUrl} alt={token100231.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100231
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100231.skills} skills &bull; {token100231.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100231.title}
</Box>
<Box color="yellow.500">
{token100231.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100231.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100231
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZVuyALXYPow12RHZUPekCMrZcv7SznjikrBLMwLeUaGi' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100232.imageUrl} alt={token100232.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100232
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100232.skills} skills &bull; {token100232.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100232.title}
</Box>
<Box color="yellow.500">
{token100232.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100232.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100232
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmVfG6hNfW6eCcb9Hb75Ezy7upRHALu5UQ8FmrNYHQqqLE' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100233.imageUrl} alt={token100233.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100233
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100233.skills} skills &bull; {token100233.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100233.title}
</Box>
<Box color="yellow.500">
{token100233.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100233.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100233
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmeZeXAMatJGqsxRWwMUDd8aKaBdeTJiPvLEzPjyvyGkNt' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100234.imageUrl} alt={token100234.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100234
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100234.skills} skills &bull; {token100234.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100234.title}
</Box>
<Box color="yellow.500">
{token100234.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100234.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100234
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmcjwRw5tM91HXbdsyRQhRG7bqzEdV4LSn5nxwGHgqNbFg' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100235.imageUrl} alt={token100235.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100235
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100235.skills} skills &bull; {token100235.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100235.title}
</Box>
<Box color="yellow.500">
{token100235.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100235.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100235
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmNsN3PuNDFzCqxYpH8ufjUfrC8cftXB9buBvHYUyRpSG9' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100236.imageUrl} alt={token100236.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100236
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100236.skills} skills &bull; {token100236.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100236.title}
</Box>
<Box color="yellow.500">
{token100236.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100236.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100236
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRpvRF4GaKzwRmr5CZ62BpukQk3483dqaYF5ot3LExXXW' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100237.imageUrl} alt={token100237.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100237
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100237.skills} skills &bull; {token100237.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100237.title}
</Box>
<Box color="yellow.500">
{token100237.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100237.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100237
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmdDZMaMC3rNBQLHa9gycHzL7nhiDUywBXLaus5tmrTMXH' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100238.imageUrl} alt={token100238.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100238
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100238.skills} skills &bull; {token100238.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100238.title}
</Box>
<Box color="yellow.500">
{token100238.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100238.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100238
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmNQZZpcTakbN18Tqxkk3rJjQb8bbeYtM9os3GHB4uvn77' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100239.imageUrl} alt={token100239.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100239
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100239.skills} skills &bull; {token100239.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100239.title}
</Box>
<Box color="yellow.500">
{token100239.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100239.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100239
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmVNvMvpVcFUTiWLmStg5ZnLyVJKXRsnSfDiJFGT76qkgN' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100240.imageUrl} alt={token100240.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100240
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100240.skills} skills &bull; {token100240.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100240.title}
</Box>
<Box color="yellow.500">
{token100240.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100240.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100240
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQVdA9JLCVoA6j8AsbHuSdhCfSRQF4YAGYPw8byydAxJN' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100241.imageUrl} alt={token100241.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100241
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100241.skills} skills &bull; {token100241.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100241.title}
</Box>
<Box color="yellow.500">
{token100241.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100241.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100241
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmReoZF3kJhqatx5nBtbPNC3wfqMhT7JP3trKYi6Ff6UWR' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100242.imageUrl} alt={token100242.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100242
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100242.skills} skills &bull; {token100242.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100242.title}
</Box>
<Box color="yellow.500">
{token100242.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100242.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100242
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmY8n14DwvGKoDfeXWF6MGUkVw2rwhi1FubtA8NK126h6K' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100244.imageUrl} alt={token100244.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100244
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100244.skills} skills &bull; {token100244.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100244.title}
</Box>
<Box color="yellow.500">
{token100244.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100244.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100244
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmcvfMwcxEVBVeJ1KRxhghUPynh1tJheHsENxmP7KTZ5wo' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100245.imageUrl} alt={token100245.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100245
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100245.skills} skills &bull; {token100245.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100245.title}
</Box>
<Box color="yellow.500">
{token100245.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100245.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100245
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTn9Lbfdu81viBVne972hdrf9LmurUM5xMPRgHDjubmFR' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100246.imageUrl} alt={token100246.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100246
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100246.skills} skills &bull; {token100246.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100246.title}
</Box>
<Box color="yellow.500">
{token100246.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100246.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100246
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmcm5ErQGphTp5TDoUqudiPeyDCNyHHGQYrWh1XPGEFVbV' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100247.imageUrl} alt={token100247.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100247
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100247.skills} skills &bull; {token100247.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100247.title}
</Box>
<Box color="yellow.500">
{token100247.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100247.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100247
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWTBMJFVsoAjc4HLXUqPijHbxbmPZZ5w5GrgPwv6rxsPA' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100249.imageUrl} alt={token100249.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100249
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100249.skills} skills &bull; {token100249.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100249.title}
</Box>
<Box color="yellow.500">
{token100249.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100249.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100249
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQEwHttAgb66U9KbWBisBGkR66o5U3EQMRQnhVELSmfmp' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100250.imageUrl} alt={token100250.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100250
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100250.skills} skills &bull; {token100250.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100250.title}
</Box>
<Box color="yellow.500">
{token100250.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100250.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100250
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmcuDznS6CZY1jR6Y7cHJeyqGn26U8WZ8m31YsvcZZ7gLV' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100251.imageUrl} alt={token100251.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100251
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100251.skills} skills &bull; {token100251.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100251.title}
</Box>
<Box color="yellow.500">
{token100251.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100251.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100251
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmfSmJHGLX33K3Qb89xvJ5Z2LKYmaQ9MzReFGwy3Q1rMQ2' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100252.imageUrl} alt={token100252.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100252
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100252.skills} skills &bull; {token100252.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100252.title}
</Box>
<Box color="yellow.500">
{token100252.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100252.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100252
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmST2PwSBD3jtF7JVqKeSRCPf7t6Ms6BgGC2Sq4k3oiCnT' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100254.imageUrl} alt={token100254.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100254
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100254.skills} skills &bull; {token100254.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100254.title}
</Box>
<Box color="yellow.500">
{token100254.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100254.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100254
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmbyDmk79DnnQMsMQPtDjufpnQhv4DsHZGamXEgwUTqZXu' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100255.imageUrl} alt={token100255.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100255
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100255.skills} skills &bull; {token100255.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100255.title}
</Box>
<Box color="yellow.500">
{token100255.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100255.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100255
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSyqw5vSBhKFQa2Pr5SQWpYjJV1aujbKC7KHUSnpdnnRy' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100256.imageUrl} alt={token100256.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100256
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100256.skills} skills &bull; {token100256.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100256.title}
</Box>
<Box color="yellow.500">
{token100256.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100256.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100256
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZXdgUSMJVyD5xHT5tgQwRi5ZVXn3FEguEvgnRJnurL6t' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100257.imageUrl} alt={token100257.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100257
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100257.skills} skills &bull; {token100257.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100257.title}
</Box>
<Box color="yellow.500">
{token100257.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100257.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100257
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmW1JfLzeMeu2uWnqCL8dbzaLaQ7nB7UrSiQLk528HJJS4' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100258.imageUrl} alt={token100258.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100258
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100258.skills} skills &bull; {token100258.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100258.title}
</Box>
<Box color="yellow.500">
{token100258.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100258.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100258
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmPrfUW6Grg1FtFwy7afccdiCQTn3SMMSHSJ48xFyfE6KQ' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100259.imageUrl} alt={token100259.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100259
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100259.skills} skills &bull; {token100259.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100259.title}
</Box>
<Box color="yellow.500">
{token100259.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100259.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100259
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZsi7nn7YoJ2C1GYhW37Vpr1MyTAskMkaDMFQDX6LP3BG' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100260.imageUrl} alt={token100260.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100260
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100260.skills} skills &bull; {token100260.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100260.title}
</Box>
<Box color="yellow.500">
{token100260.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100260.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100260
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTVtD2QKRhCddxmKsTCW6SmhLgfBF7NXjJBSKbGjHaiEG' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100294.imageUrl} alt={token100294.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100294
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100294.skills} skills &bull; {token100294.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100294.title}
</Box>
<Box color="yellow.500">
{token100294.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100294.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100294
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmU2zSbipsamseo63eaMvuW9EVhduA5eiECVeVwdGAE6bS' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100295.imageUrl} alt={token100295.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100295
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100295.skills} skills &bull; {token100295.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100295.title}
</Box>
<Box color="yellow.500">
{token100295.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100295.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100295
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRrZPvWgoxR5boHKKiBrqC2Dypn4LmRLsMqVFmFVTDUix' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100296.imageUrl} alt={token100296.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100296
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100296.skills} skills &bull; {token100296.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100296.title}
</Box>
<Box color="yellow.500">
{token100296.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100296.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100296
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmNrEZHi1CVzsbqoVu9saL5RU338r9kbQeuPPDZ2aK5wM3' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>














































































































































































































































































































































































































































































































































































































































































































































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100307.imageUrl} alt={token100307.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100307
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100307.skills} skills &bull; {token100307.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100307.title}
</Box>
<Box color="yellow.500">
{token100307.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100307.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100307
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQuiiDgDaN4z6nJ9TLud3csBQy1ZJ3FK9cnjjxjY7S2tC' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100308.imageUrl} alt={token100308.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100308
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100308.skills} skills &bull; {token100308.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100308.title}
</Box>
<Box color="yellow.500">
{token100308.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100308.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100308
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmekPaqNhGTb5FfjKtczDjizZskswZEHfKWCNrAX3esPGX' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100309.imageUrl} alt={token100309.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100309
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100309.skills} skills &bull; {token100309.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100309.title}
</Box>
<Box color="yellow.500">
{token100309.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100309.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100309
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYAtadi2DzGyXz4Rio4iN7izvYhaBBWdZZv39Z2Gdk13o' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100310.imageUrl} alt={token100310.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100310
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100310.skills} skills &bull; {token100310.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100310.title}
</Box>
<Box color="yellow.500">
{token100310.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100310.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100310
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmVLRzsrtvaHaT525GkrHfcjupK7LeV8wBv8VS2auoVhGa' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100311.imageUrl} alt={token100311.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100311
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100311.skills} skills &bull; {token100311.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100311.title}
</Box>
<Box color="yellow.500">
{token100311.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100311.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100311
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmd6kFNfJS1NuetfX67EjFopjvQSFSrDdtHuQxUKwqbRjG' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100312.imageUrl} alt={token100312.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100312
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100312.skills} skills &bull; {token100312.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100312.title}
</Box>
<Box color="yellow.500">
{token100312.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100312.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100312
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmbHEjQ2Ab3cMTQi1deZ2Zc8aSuxDd7i4FmrKkeeY31TfN' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100313.imageUrl} alt={token100313.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100313
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100313.skills} skills &bull; {token100313.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100313.title}
</Box>
<Box color="yellow.500">
{token100313.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100313.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100313
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmaYywwWe1GnvTao75vmXjuh1QhdUTPDjqBfTuV1hZBj7n' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100314.imageUrl} alt={token100314.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100314
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100314.skills} skills &bull; {token100314.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100314.title}
</Box>
<Box color="yellow.500">
{token100314.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100314.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100314
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmVkoTFMMnSyZ5w6zPE2tp6NVhRAX1pRN9BBEdKarNu89V' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100315.imageUrl} alt={token100315.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100315
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100315.skills} skills &bull; {token100315.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100315.title}
</Box>
<Box color="yellow.500">
{token100315.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100315.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100315
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTzRz9vfqdLpBykADQZE4JHJMaCDjYgyngWd3LprtubBJ' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100316.imageUrl} alt={token100316.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100316
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100316.skills} skills &bull; {token100316.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100316.title}
</Box>
<Box color="yellow.500">
{token100316.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100316.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100316
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmXSjcn4tangYXv4Y9RCquQpRTtXnAmQEvqXMKM3ypjRDE' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100317.imageUrl} alt={token100317.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100317
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100317.skills} skills &bull; {token100317.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100317.title}
</Box>
<Box color="yellow.500">
{token100317.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100317.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100317
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmT3MzTVmZDfZ5xzd77mkqzJ3e8Hn7tD14P8WuU2bjNuQ4' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100318.imageUrl} alt={token100318.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100318
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100318.skills} skills &bull; {token100318.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100318.title}
</Box>
<Box color="yellow.500">
{token100318.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100318.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100318
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWsCTTdAxwKS1CasfpaF2fg1Lz4M4xa4nzywoezr8qswD' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>

































































































































































































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100322.imageUrl} alt={token100322.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100322
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100322.skills} skills &bull; {token100322.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100322.title}
</Box>
<Box color="yellow.500">
{token100322.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100322.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100322
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmX2ijxx1wcwQC6ezv4ujTnoaUwevtQ9ZNa4g7cBRGABRV' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100323.imageUrl} alt={token100323.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100323
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100323.skills} skills &bull; {token100323.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100323.title}
</Box>
<Box color="yellow.500">
{token100323.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100323.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100323
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmaXjUJjNYBa5QpxJKouGyzT4HRaAqwWPdYKAYn5EZXFAo' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100324.imageUrl} alt={token100324.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100324
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100324.skills} skills &bull; {token100324.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100324.title}
</Box>
<Box color="yellow.500">
{token100324.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100324.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100324
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmW5PMMeMN3mGtEHJL7AZ4D7e5hqvM7EtQMgJBHGky6hg9' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100325.imageUrl} alt={token100325.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100325
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100325.skills} skills &bull; {token100325.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100325.title}
</Box>
<Box color="yellow.500">
{token100325.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100325.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100325
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmaNJw5NBqyiSBNXg1L7525M5CEPDAE3mbKreZsYgp5GWV' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100326.imageUrl} alt={token100326.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100326
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100326.skills} skills &bull; {token100326.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100326.title}
</Box>
<Box color="yellow.500">
{token100326.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100326.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100326
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYW2jGFvYSdDBB3kgqv7dU96Pr5braXKkNWPZQ5e9hNqz' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100327.imageUrl} alt={token100327.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100327
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100327.skills} skills &bull; {token100327.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100327.title}
</Box>
<Box color="yellow.500">
{token100327.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100327.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100327
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmbnPhHqFzYt5DFZx3P4iNDex4Queoy5agQmXPzsQTX1u7' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>

































































































































































































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100331.imageUrl} alt={token100331.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100331
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100331.skills} skills &bull; {token100331.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100331.title}
</Box>
<Box color="yellow.500">
{token100331.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100331.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100331
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmVr6EuHnYnR4n54ubddCF5irkaSyrL5aDMfRiMW6HGTVG' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100332.imageUrl} alt={token100332.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100332
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100332.skills} skills &bull; {token100332.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100332.title}
</Box>
<Box color="yellow.500">
{token100332.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100332.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100332
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmPMxEbMrXmjNDfGiYe715Q6t6jT2z5YbuBjUFeueiR2eY' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100333.imageUrl} alt={token100333.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100333
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100333.skills} skills &bull; {token100333.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100333.title}
</Box>
<Box color="yellow.500">
{token100333.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100333.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100333
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmXc7v6sy68HTbKcTBaG632h4FWqUHrRePf6gxsYkpPLvA' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100334.imageUrl} alt={token100334.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100334
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100334.skills} skills &bull; {token100334.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100334.title}
</Box>
<Box color="yellow.500">
{token100334.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100334.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100334
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmf2o9tTYxoPZt1n3CnRb6ytkaur8vZhfrm4YomufhB6Mz' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100335.imageUrl} alt={token100335.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100335
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100335.skills} skills &bull; {token100335.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100335.title}
</Box>
<Box color="yellow.500">
{token100335.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100335.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100335
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmV934HVHMcciPkjpkapmUadAN6MANhzUkMeBv6CbpyH4s' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100336.imageUrl} alt={token100336.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100336
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100336.skills} skills &bull; {token100336.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100336.title}
</Box>
<Box color="yellow.500">
{token100336.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100336.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100336
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTguGbWuq87QbW4JkrpdrEg2uDFjPsbC9DMcZDXZNCpSf' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100337.imageUrl} alt={token100337.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100337
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100337.skills} skills &bull; {token100337.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100337.title}
</Box>
<Box color="yellow.500">
{token100337.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100337.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100337
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmesLG2PpYLy8s9ZDU3Df7XzrsDDLTY1Mb9DYh1wjK3BYb' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100338.imageUrl} alt={token100338.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100338
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100338.skills} skills &bull; {token100338.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100338.title}
</Box>
<Box color="yellow.500">
{token100338.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100338.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100338
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWeynP28HiT9D8YP9M1Ytnar6EZgG71vPjwbaEhtW9d74' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100340.imageUrl} alt={token100340.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100340
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100340.skills} skills &bull; {token100340.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100340.title}
</Box>
<Box color="yellow.500">
{token100340.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100340.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100340
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYoE7R6Hwj7XipUCfE4XwzfRmECmo5vsZuyoj9ZLFr975' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100341.imageUrl} alt={token100341.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100341
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100341.skills} skills &bull; {token100341.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100341.title}
</Box>
<Box color="yellow.500">
{token100341.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100341.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100341
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQeqmuAQNNxhgHfbGw2qWBBZbG4rZKitZ13yhz4uX9ChF' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100342.imageUrl} alt={token100342.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100342
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100342.skills} skills &bull; {token100342.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100342.title}
</Box>
<Box color="yellow.500">
{token100342.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100342.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100342
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQeqmuAQNNxhgHfbGw2qWBBZbG4rZKitZ13yhz4uX9ChF' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100343.imageUrl} alt={token100343.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100343
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100343.skills} skills &bull; {token100343.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100343.title}
</Box>
<Box color="yellow.500">
{token100343.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100343.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100343
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWHTsW3DJwuFc5XYkqsMQXcS87FdGHcc6NXGyXRb9GGEZ' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>












































































































































































































































































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100348.imageUrl} alt={token100348.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100348
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100348.skills} skills &bull; {token100348.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100348.title}
</Box>
<Box color="yellow.500">
{token100348.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100348.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100348
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRgQH9e6LpUM8vL4VVEVmxbyXnoQDhDqzPxYf9Fc9qMW6' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100349.imageUrl} alt={token100349.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100349
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100349.skills} skills &bull; {token100349.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100349.title}
</Box>
<Box color="yellow.500">
{token100349.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100349.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100349
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmfVHhQaggwE3dfRN7XirQPEhF7nSQy2fxEALr7FAeY8BX' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100351.imageUrl} alt={token100351.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100351
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100351.skills} skills &bull; {token100351.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100351.title}
</Box>
<Box color="yellow.500">
{token100351.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100351.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100351
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmR4KrFKgM7VLR2m5ygPnhHX7M3PjQ141Yw3ty1tq1DM6a' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100352.imageUrl} alt={token100352.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100352
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100352.skills} skills &bull; {token100352.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100352.title}
</Box>
<Box color="yellow.500">
{token100352.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100352.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100352
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSWiLmgxK2T8kcRRLp7GiNagtj3yrmkpuMkq5RFfQqi6v' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100353.imageUrl} alt={token100353.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100353
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100353.skills} skills &bull; {token100353.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100353.title}
</Box>
<Box color="yellow.500">
{token100353.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100353.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100353
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWV4QW1FrsRqBA85hFb4WJrWVpSjwbYBG9KNszYcSA4J3' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100354.imageUrl} alt={token100354.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100354
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100354.skills} skills &bull; {token100354.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100354.title}
</Box>
<Box color="yellow.500">
{token100354.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100354.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100354
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRTnLE6wtuhm5NC8VEXyAfJJJ1ieECKozx48BiZj2GSvT' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100356.imageUrl} alt={token100356.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100356
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100356.skills} skills &bull; {token100356.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100356.title}
</Box>
<Box color="yellow.500">
{token100356.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100356.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100356
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYcG7bzV1aBEEtS1oUtaLqd5Z4QwyHHPeCa1oJpwEoBgX' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100357.imageUrl} alt={token100357.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100357
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100357.skills} skills &bull; {token100357.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100357.title}
</Box>
<Box color="yellow.500">
{token100357.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100357.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100357
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmcrnc8qneikFfq71RXBgLFm2tniHKFsHUEzKpVXQf8QWm' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100358.imageUrl} alt={token100358.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100358
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100358.skills} skills &bull; {token100358.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100358.title}
</Box>
<Box color="yellow.500">
{token100358.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100358.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100358
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmNSVNZ11ZbYxcREhKTFFCsouxNPCsLgRtGkMdp1KdSUmV' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100359.imageUrl} alt={token100359.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100359
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100359.skills} skills &bull; {token100359.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100359.title}
</Box>
<Box color="yellow.500">
{token100359.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100359.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100359
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmf3HfSPREuJUNaDm4Da5kw8xsKpJJx1AdmcxNb1mzxcTn' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100360.imageUrl} alt={token100360.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100360
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100360.skills} skills &bull; {token100360.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100360.title}
</Box>
<Box color="yellow.500">
{token100360.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100360.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100360
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTSkTBGBCxrtwNa7mpHrZQMSNRWTTE4xJNQMknkh7zyun' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100361.imageUrl} alt={token100361.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100361
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100361.skills} skills &bull; {token100361.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100361.title}
</Box>
<Box color="yellow.500">
{token100361.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100361.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100361
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRyZHFxJCy9sHnJN9BE3oP4zB7FzdEYetX8UJf7ND7xoT' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100362.imageUrl} alt={token100362.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100362
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100362.skills} skills &bull; {token100362.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100362.title}
</Box>
<Box color="yellow.500">
{token100362.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100362.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100362
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTgoyb9XmuyJ5wfxRrb9h6Dd3uGvfJwA4vyyY8fJkNz7h' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100364.imageUrl} alt={token100364.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100364
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100364.skills} skills &bull; {token100364.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100364.title}
</Box>
<Box color="yellow.500">
{token100364.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100364.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100364
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSVURar8ivzEGyhXSVM19Q3kUF2cLUsBwUzCqECSxUjUr' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100365.imageUrl} alt={token100365.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100365
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100365.skills} skills &bull; {token100365.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100365.title}
</Box>
<Box color="yellow.500">
{token100365.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100365.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100365
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYDVSwZg3TZy5RbovoFm8fRvb41io1ssvwFiPoS9f6cmH' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100366.imageUrl} alt={token100366.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100366
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100366.skills} skills &bull; {token100366.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100366.title}
</Box>
<Box color="yellow.500">
{token100366.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100366.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100366
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQnFRg698ZpVQrLzqnZPanM74riGvtZqjPKGPWyUAeSfo' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100368.imageUrl} alt={token100368.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100368
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100368.skills} skills &bull; {token100368.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100368.title}
</Box>
<Box color="yellow.500">
{token100368.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100368.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100368
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRbg4Jf7Jfsqo6CDEun3fPwnyv3vCrFkhsFnTM2j6Dtsf' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100370.imageUrl} alt={token100370.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100370
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100370.skills} skills &bull; {token100370.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100370.title}
</Box>
<Box color="yellow.500">
{token100370.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100370.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100370
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmVQA7oDJuxbeHCJXxxxZR54KWwfnwZZVF6fLS98Agh3FH' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100371.imageUrl} alt={token100371.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100371
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100371.skills} skills &bull; {token100371.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100371.title}
</Box>
<Box color="yellow.500">
{token100371.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100371.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100371
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZ1ae7su3hNn2VKChePYwRRGRAyxMBjfZJUAyudmGMwGN' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100372.imageUrl} alt={token100372.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100372
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100372.skills} skills &bull; {token100372.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100372.title}
</Box>
<Box color="yellow.500">
{token100372.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100372.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100372
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWqUd6WyNSf6M3eLtiFFpN3Ktpa6D9P9fajDhCdEGdWzv' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100374.imageUrl} alt={token100374.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100374
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100374.skills} skills &bull; {token100374.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100374.title}
</Box>
<Box color="yellow.500">
{token100374.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100374.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100374
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZSb1phyv1HsV8D2YnthVWXM1winYZqZfn38AnwiNYSe8' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100375.imageUrl} alt={token100375.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100375
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100375.skills} skills &bull; {token100375.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100375.title}
</Box>
<Box color="yellow.500">
{token100375.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100375.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100375
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmeFXd2GASh4uH8eScpsxY7dsKy27NLqH4CTC9MoYQ1Tgx' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100376.imageUrl} alt={token100376.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100376
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100376.skills} skills &bull; {token100376.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100376.title}
</Box>
<Box color="yellow.500">
{token100376.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100376.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100376
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQdtgAvvwvFKXCxdNoXrxKm9q12TLnNnucjr42n3F8did' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100377.imageUrl} alt={token100377.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100377
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100377.skills} skills &bull; {token100377.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100377.title}
</Box>
<Box color="yellow.500">
{token100377.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100377.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100377
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmfNheWzTnk5CZgGwQeoPfvAPGdkcfrzVb2uCjvsKj2jB5' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100378.imageUrl} alt={token100378.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100378
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100378.skills} skills &bull; {token100378.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100378.title}
</Box>
<Box color="yellow.500">
{token100378.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100378.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100378
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmUxTYMyH2b68GnHgMjhL8jsWxQvAcoxThj3bgSmNmAKXf' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100379.imageUrl} alt={token100379.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100379
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100379.skills} skills &bull; {token100379.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100379.title}
</Box>
<Box color="yellow.500">
{token100379.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100379.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100379
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmS7sReKZVbWEf2D7vmXQ6BFMFYTF6Yxg8znuhCRgguWwK' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100380.imageUrl} alt={token100380.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100380
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100380.skills} skills &bull; {token100380.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100380.title}
</Box>
<Box color="yellow.500">
{token100380.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100380.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100380
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmT42U2USxCVACJPizRs1TQ65QJCJ2tBv4rmi3LC1JtzZn' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100381.imageUrl} alt={token100381.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100381
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100381.skills} skills &bull; {token100381.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100381.title}
</Box>
<Box color="yellow.500">
{token100381.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100381.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100381
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYi4fu6XHB5f6Z3vcJ4YXAK6t2vzny8DFUuNgA412Cs99' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100382.imageUrl} alt={token100382.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100382
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100382.skills} skills &bull; {token100382.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100382.title}
</Box>
<Box color="yellow.500">
{token100382.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100382.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100382
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmS5kHvKShxkXxpYQUPc33mRQvHgDTT9qVHXEjRGuMBzD8' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100383.imageUrl} alt={token100383.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100383
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100383.skills} skills &bull; {token100383.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100383.title}
</Box>
<Box color="yellow.500">
{token100383.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100383.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100383
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmNZ8PAN2LNem1oD59gwrDPoF9dbqMRgM8R2jehedcx4LA' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100384.imageUrl} alt={token100384.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100384
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100384.skills} skills &bull; {token100384.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100384.title}
</Box>
<Box color="yellow.500">
{token100384.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100384.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100384
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmdnjmSJqcyySk7bKTjmhMwFaHG8JRQihwMbo9gmtbWvqb' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100385.imageUrl} alt={token100385.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100385
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100385.skills} skills &bull; {token100385.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100385.title}
</Box>
<Box color="yellow.500">
{token100385.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100385.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100385
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmc5sCqdgjTCQ9UVP6LWV69oSpw8bg9jfwiSZG3FKJyRzy' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100386.imageUrl} alt={token100386.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100386
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100386.skills} skills &bull; {token100386.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100386.title}
</Box>
<Box color="yellow.500">
{token100386.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100386.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100386
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmV2xCWjkUyN6eBPY3Y8gKAYUQA4S1RpvgdBUb3JtH6Bxd' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100387.imageUrl} alt={token100387.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100387
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100387.skills} skills &bull; {token100387.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100387.title}
</Box>
<Box color="yellow.500">
{token100387.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100387.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100387
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmeHgznsvnqFRkfABrL1SgQmRL2HGRGn978J7gTYvHUmzX' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100388.imageUrl} alt={token100388.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100388
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100388.skills} skills &bull; {token100388.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100388.title}
</Box>
<Box color="yellow.500">
{token100388.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100388.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100388
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmP5dHqpwUWfmb1TfQbV7oEPv5oMSbidx99x31wWkWf7zc' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100390.imageUrl} alt={token100390.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100390
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100390.skills} skills &bull; {token100390.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100390.title}
</Box>
<Box color="yellow.500">
{token100390.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100390.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100390
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmc1kcGhuAvhSfyBTWNTfioVzqfQz7QYmkufQGPvRXszwv' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100391.imageUrl} alt={token100391.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100391
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100391.skills} skills &bull; {token100391.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100391.title}
</Box>
<Box color="yellow.500">
{token100391.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100391.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100391
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQoQSdUQnTayBtGhJt2vUGq8Nbxj9d2x56Y82mEw9yHJR' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100392.imageUrl} alt={token100392.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100392
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100392.skills} skills &bull; {token100392.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100392.title}
</Box>
<Box color="yellow.500">
{token100392.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100392.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100392
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmUujcDKqijVDbVA1DNrcWRwNZodUNxxqvnV3ZA3qzmEHs' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100393.imageUrl} alt={token100393.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100393
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100393.skills} skills &bull; {token100393.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100393.title}
</Box>
<Box color="yellow.500">
{token100393.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100393.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100393
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmaw1ym6SvUkRPbbQH4wa6SpBTrsaEYposLaX2cZStbosn' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100395.imageUrl} alt={token100395.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100395
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100395.skills} skills &bull; {token100395.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100395.title}
</Box>
<Box color="yellow.500">
{token100395.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100395.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100395
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWAKK7Ddjnpas131ePGonUiSM37gdJCf7NRbLNitPccac' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100396.imageUrl} alt={token100396.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100396
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100396.skills} skills &bull; {token100396.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100396.title}
</Box>
<Box color="yellow.500">
{token100396.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100396.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100396
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSFetmUZUPfgJZHC5RZG9Sgm7tSUhfZSt9QY39LTZA8Nx' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100397.imageUrl} alt={token100397.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100397
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100397.skills} skills &bull; {token100397.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100397.title}
</Box>
<Box color="yellow.500">
{token100397.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100397.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100397
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmfCaKFFmYM1x5hkeKEFbjBe4ounQNphbGFBAq77uFG4RF' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100398.imageUrl} alt={token100398.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100398
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100398.skills} skills &bull; {token100398.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100398.title}
</Box>
<Box color="yellow.500">
{token100398.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100398.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100398
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSnzDkxqjqTELYFAgzMzG9pAneCRue5eyTJXvQ6idnmWM' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100399.imageUrl} alt={token100399.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100399
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100399.skills} skills &bull; {token100399.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100399.title}
</Box>
<Box color="yellow.500">
{token100399.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100399.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100399
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmcVTy7RTGGJEUBBBhPzYeYWCPbjyhSb2ouvEMe2v7KbUv' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100400.imageUrl} alt={token100400.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100400
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100400.skills} skills &bull; {token100400.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100400.title}
</Box>
<Box color="yellow.500">
{token100400.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100400.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100400
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRRd3cia1sXbxKvT8H3Zqy1BU5Cc2cDDZz7B96Dz8arXT' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100401.imageUrl} alt={token100401.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100401
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100401.skills} skills &bull; {token100401.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100401.title}
</Box>
<Box color="yellow.500">
{token100401.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100401.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100401
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWtfdG2C41DP9Hrymeyho7Kqed4fU6qFpsqWoRhoeSMDh' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100402.imageUrl} alt={token100402.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100402
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100402.skills} skills &bull; {token100402.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100402.title}
</Box>
<Box color="yellow.500">
{token100402.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100402.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100402
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qma4W4AoXqQTm9V2x2d4ZP36sfhjSi9PGb4JAj24DSyY7c' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100403.imageUrl} alt={token100403.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100403
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100403.skills} skills &bull; {token100403.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100403.title}
</Box>
<Box color="yellow.500">
{token100403.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100403.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100403
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQ5D7hdiZMQv8EHKMGkLZxK1cBBAHEd3KQkVnQWz57whD' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100404.imageUrl} alt={token100404.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100404
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100404.skills} skills &bull; {token100404.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100404.title}
</Box>
<Box color="yellow.500">
{token100404.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100404.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100404
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmbgVBnpQdTdeQwXGeQmdhtsnUD4JLSedyoqk4QkyYTRVs' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100405.imageUrl} alt={token100405.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100405
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100405.skills} skills &bull; {token100405.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100405.title}
</Box>
<Box color="yellow.500">
{token100405.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100405.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100405
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmeWks67gL6HASUAndMndviDPz2usj8FUGyfGTW1cxr8kP' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100406.imageUrl} alt={token100406.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100406
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100406.skills} skills &bull; {token100406.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100406.title}
</Box>
<Box color="yellow.500">
{token100406.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100406.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100406
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmUqBwSaXDmGSej1r1WQT4MdZUkw6puxTmR8WgGd9mWwmf' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100407.imageUrl} alt={token100407.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100407
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100407.skills} skills &bull; {token100407.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100407.title}
</Box>
<Box color="yellow.500">
{token100407.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100407.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100407
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSfekouFVeFWtVJbuGRnyRyb2bioVGQ2ugiygMFV4TqQt' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100408.imageUrl} alt={token100408.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100408
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100408.skills} skills &bull; {token100408.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100408.title}
</Box>
<Box color="yellow.500">
{token100408.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100408.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100408
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmeT9FBVBMWLZZ36Y5yQMSALYAK9HT9CyMpR95UFAACKkf' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100409.imageUrl} alt={token100409.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100409
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100409.skills} skills &bull; {token100409.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100409.title}
</Box>
<Box color="yellow.500">
{token100409.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100409.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100409
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmdEFudM13pQGpPCPhny3EVQ4Fjmb4ST9HL61PpUgncMc2' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100410.imageUrl} alt={token100410.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100410
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100410.skills} skills &bull; {token100410.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100410.title}
</Box>
<Box color="yellow.500">
{token100410.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100410.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100410
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmVaJGbRoCpvVzRa5yKv7uPqwf7Qiid513WBqVGeKtmtCo' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100411.imageUrl} alt={token100411.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100411
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100411.skills} skills &bull; {token100411.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100411.title}
</Box>
<Box color="yellow.500">
{token100411.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100411.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100411
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmaLUpPURuWbkayr4YgiWfdYuLDJ5MhHnjsFKiiexTaBG2' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100412.imageUrl} alt={token100412.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100412
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100412.skills} skills &bull; {token100412.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100412.title}
</Box>
<Box color="yellow.500">
{token100412.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100412.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100412
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmNZvYvvdqkdWNvdAYJgJ8j7XTmSZzcoTJ9WBNqJG9qFDE' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100413.imageUrl} alt={token100413.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100413
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100413.skills} skills &bull; {token100413.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100413.title}
</Box>
<Box color="yellow.500">
{token100413.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100413.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100413
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRPemSi1UZ9kS7gxVdDojq3SKBY1TkBZEX3x8xNmcoePA' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100414.imageUrl} alt={token100414.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100414
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100414.skills} skills &bull; {token100414.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100414.title}
</Box>
<Box color="yellow.500">
{token100414.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100414.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100414
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmepaP2JpMjt1ykyu5jbttY67QYvmsfRrofS39zf9QKHsB' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100416.imageUrl} alt={token100416.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100416
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100416.skills} skills &bull; {token100416.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100416.title}
</Box>
<Box color="yellow.500">
{token100416.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100416.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100416
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qman2TxEwVVDv9WDRfj6nMTUAQhW2dX6SpXjiHoYfc2iV7' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100417.imageUrl} alt={token100417.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100417
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100417.skills} skills &bull; {token100417.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100417.title}
</Box>
<Box color="yellow.500">
{token100417.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100417.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100417
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmezcyjtDxRuzJBNbQvBGHpsJNthUvwJnDdZJtJGz3v5Hd' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>























































































































































































































































































































































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100423.imageUrl} alt={token100423.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100423
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100423.skills} skills &bull; {token100423.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100423.title}
</Box>
<Box color="yellow.500">
{token100423.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100423.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100423
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmacQ1dLchJXJWGvMwFe264xXzFs2ggkqb4X2s72LWioNp' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100424.imageUrl} alt={token100424.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100424
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100424.skills} skills &bull; {token100424.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100424.title}
</Box>
<Box color="yellow.500">
{token100424.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100424.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100424
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmaccpUZ9TFYFhYe6PkiWGL5NNNdZUDrjjjCfrdwZDhgHo' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100425.imageUrl} alt={token100425.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100425
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100425.skills} skills &bull; {token100425.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100425.title}
</Box>
<Box color="yellow.500">
{token100425.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100425.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100425
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZfWMU2fo1u3fxsQnQgQYibEKNKFF858b3ic8eqkyMp9o' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100426.imageUrl} alt={token100426.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100426
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100426.skills} skills &bull; {token100426.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100426.title}
</Box>
<Box color="yellow.500">
{token100426.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100426.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100426
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmdHuaNyteD5Wi4pACFkQr5Hrevh8L59xapL61KNwvxFdD' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100427.imageUrl} alt={token100427.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100427
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100427.skills} skills &bull; {token100427.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100427.title}
</Box>
<Box color="yellow.500">
{token100427.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100427.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100427
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRpB3HJt3SUWS5gHPn39BxaaFpYyFADdubm8ULMG6do84' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100428.imageUrl} alt={token100428.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100428
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100428.skills} skills &bull; {token100428.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100428.title}
</Box>
<Box color="yellow.500">
{token100428.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100428.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100428
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmb7LTFgPieCwGR99xprWAzwY93z9MGjZF2p5WBzRbtNvc' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100429.imageUrl} alt={token100429.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100429
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100429.skills} skills &bull; {token100429.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100429.title}
</Box>
<Box color="yellow.500">
{token100429.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100429.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100429
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmdRdZZ9dxduiV92HugtAVqmLDybPzAN2Ea8HsdtgUse4G' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100430.imageUrl} alt={token100430.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100430
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100430.skills} skills &bull; {token100430.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100430.title}
</Box>
<Box color="yellow.500">
{token100430.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100430.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100430
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmbGNPGepiJ3gU8VgZRtpbZcuqE5xBWdVtietTWsBActSM' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100431.imageUrl} alt={token100431.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100431
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100431.skills} skills &bull; {token100431.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100431.title}
</Box>
<Box color="yellow.500">
{token100431.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100431.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100431
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTKVXDfgyDAL4REirRtmVRpZ26Qrq45kAW5z28j192G4T' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100432.imageUrl} alt={token100432.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100432
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100432.skills} skills &bull; {token100432.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100432.title}
</Box>
<Box color="yellow.500">
{token100432.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100432.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100432
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYEtBKCbnGW1X7N8GLyF6Bv2zTjLf3WwmUp7XtvnHBXQz' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100433.imageUrl} alt={token100433.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100433
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100433.skills} skills &bull; {token100433.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100433.title}
</Box>
<Box color="yellow.500">
{token100433.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100433.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100433
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQ1H5B1g2qaPQ9JH5YhmTTRvdYwDc5DYrh3MPbFYLE4Ae' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100434.imageUrl} alt={token100434.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100434
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100434.skills} skills &bull; {token100434.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100434.title}
</Box>
<Box color="yellow.500">
{token100434.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100434.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100434
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qme5nq46YeyTYjeAWZ3e3xgK76U4mDcmTDP92RoXdc6SrJ' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100435.imageUrl} alt={token100435.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100435
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100435.skills} skills &bull; {token100435.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100435.title}
</Box>
<Box color="yellow.500">
{token100435.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100435.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100435
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmP3yqY5aw42a9BrE2iTvWgX77HG9b8oCDWA97xq4FP7dq' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100436.imageUrl} alt={token100436.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100436
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100436.skills} skills &bull; {token100436.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100436.title}
</Box>
<Box color="yellow.500">
{token100436.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100436.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100436
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYVZqDvxurE1fzJkn8TGtyCNFu6Ts11gTUh5VKLjHTiNr' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100437.imageUrl} alt={token100437.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100437
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100437.skills} skills &bull; {token100437.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100437.title}
</Box>
<Box color="yellow.500">
{token100437.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100437.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100437
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSw8k7nNgK8dXrzUAgBrN8fbLWnRM6LjxWe3ewswiDvKF' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100438.imageUrl} alt={token100438.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100438
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100438.skills} skills &bull; {token100438.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100438.title}
</Box>
<Box color="yellow.500">
{token100438.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100438.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100438
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSYhibMK5APzVvwc3yF9QnTYz9Uawwiku2HAusgdVxTik' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100439.imageUrl} alt={token100439.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100439
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100439.skills} skills &bull; {token100439.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100439.title}
</Box>
<Box color="yellow.500">
{token100439.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100439.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100439
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmXoqR1m5cRsYPBFtdcy52NqAvrimboankU9oER3w5VtfN' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100440.imageUrl} alt={token100440.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100440
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100440.skills} skills &bull; {token100440.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100440.title}
</Box>
<Box color="yellow.500">
{token100440.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100440.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100440
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmUzEeBgFYvsmgSobFKGkDf9D78Jam4NxsKh5R92rnS1JF' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100441.imageUrl} alt={token100441.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100441
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100441.skills} skills &bull; {token100441.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100441.title}
</Box>
<Box color="yellow.500">
{token100441.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100441.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100441
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmamAYqwPMVanAgpZNRZgE4ithsavBEGymKx15N5gErfKh' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100442.imageUrl} alt={token100442.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100442
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100442.skills} skills &bull; {token100442.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100442.title}
</Box>
<Box color="yellow.500">
{token100442.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100442.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100442
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmdHCwZL79Yvz1Zy6yCEjRUugwP7rnrAjdAc3ZY9oY8Uwj' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100443.imageUrl} alt={token100443.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100443
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100443.skills} skills &bull; {token100443.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100443.title}
</Box>
<Box color="yellow.500">
{token100443.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100443.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100443
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmXwasbr23pkyWL8zjxYtir2EVHeSYwporhv1LFdq6Z5iJ' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100444.imageUrl} alt={token100444.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100444
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100444.skills} skills &bull; {token100444.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100444.title}
</Box>
<Box color="yellow.500">
{token100444.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100444.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100444
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmPjNR1r5SxLFmmVAFesvFKewJKMbPKkjg4y32hAuKqX9T' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100445.imageUrl} alt={token100445.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100445
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100445.skills} skills &bull; {token100445.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100445.title}
</Box>
<Box color="yellow.500">
{token100445.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100445.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100445
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSHvHN6mB1qDez2UkbPcv9JBrStoN7vp7cA795J75Zae3' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100446.imageUrl} alt={token100446.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100446
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100446.skills} skills &bull; {token100446.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100446.title}
</Box>
<Box color="yellow.500">
{token100446.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100446.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100446
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRFkBL8UoW1ZvwAJWknaZZh9wmETbFdr3h98cXRiZQjux' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100447.imageUrl} alt={token100447.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100447
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100447.skills} skills &bull; {token100447.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100447.title}
</Box>
<Box color="yellow.500">
{token100447.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100447.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100447
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmNwQ6RnGRpnARmr64Vijtz8QqHb1b4BiwUFQg1W8ybLRg' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100448.imageUrl} alt={token100448.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100448
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100448.skills} skills &bull; {token100448.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100448.title}
</Box>
<Box color="yellow.500">
{token100448.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100448.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100448
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWN2Hr9pWjufRw4rAc7XoaYEuE8bi7ktNEdV31xMtJrJ1' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100449.imageUrl} alt={token100449.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100449
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100449.skills} skills &bull; {token100449.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100449.title}
</Box>
<Box color="yellow.500">
{token100449.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100449.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100449
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmPXVGPoUoUKgdumijdtafv8UQG5uqzZorkVVtxAasSTpU' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100450.imageUrl} alt={token100450.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100450
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100450.skills} skills &bull; {token100450.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100450.title}
</Box>
<Box color="yellow.500">
{token100450.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100450.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100450
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmT8KLoXHM6eQy7r9ja3rnXVi8gUE9rbisWzF2LYnJpLYp' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100451.imageUrl} alt={token100451.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100451
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100451.skills} skills &bull; {token100451.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100451.title}
</Box>
<Box color="yellow.500">
{token100451.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100451.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100451
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmcPk48zZfA7duNva5RtBuDV4vJLeq4sABnf4m4jaAt9Gd' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100452.imageUrl} alt={token100452.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100452
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100452.skills} skills &bull; {token100452.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100452.title}
</Box>
<Box color="yellow.500">
{token100452.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100452.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100452
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYC4DHkGNSJtwWTEN7wfnoemtTPUNqds4CQadshpkzUeP' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100454.imageUrl} alt={token100454.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100454
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100454.skills} skills &bull; {token100454.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100454.title}
</Box>
<Box color="yellow.500">
{token100454.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100454.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100454
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmcMrxSBbkwiALFusBcqCjPtKqaB7jQsjxYBAEYN8oCB4E' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100456.imageUrl} alt={token100456.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100456
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100456.skills} skills &bull; {token100456.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100456.title}
</Box>
<Box color="yellow.500">
{token100456.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100456.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100456
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmb7nRdgMXSN8vuYb7m4ABu7iPR35DbwUDvQTvubEj9WCe' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100457.imageUrl} alt={token100457.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100457
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100457.skills} skills &bull; {token100457.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100457.title}
</Box>
<Box color="yellow.500">
{token100457.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100457.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100457
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmdmUtVdQ47jG3KtoRn4nRjrWuNcPztVghizjjvZtaMiPJ' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100458.imageUrl} alt={token100458.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100458
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100458.skills} skills &bull; {token100458.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100458.title}
</Box>
<Box color="yellow.500">
{token100458.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100458.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100458
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmY7aTYdUtK8sUkQxdhF1wog7FhewXg9sD169k5vayr3qU' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100459.imageUrl} alt={token100459.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100459
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100459.skills} skills &bull; {token100459.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100459.title}
</Box>
<Box color="yellow.500">
{token100459.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100459.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100459
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQhnx9oocvPAuEd83BvWLRGxDp517BPxC6ge5ZGTM7D6T' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100460.imageUrl} alt={token100460.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100460
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100460.skills} skills &bull; {token100460.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100460.title}
</Box>
<Box color="yellow.500">
{token100460.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100460.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100460
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSe5jJdSgNHUsgkUFX5ymJ9UKwzbzJTqer5sBHCdHBKmy' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100461.imageUrl} alt={token100461.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100461
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100461.skills} skills &bull; {token100461.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100461.title}
</Box>
<Box color="yellow.500">
{token100461.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100461.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100461
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmd11aeBSiUW3RDzdjq74bja31QZ2vCUigxE8Ha6zmcZXQ' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100462.imageUrl} alt={token100462.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100462
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100462.skills} skills &bull; {token100462.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100462.title}
</Box>
<Box color="yellow.500">
{token100462.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100462.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100462
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmckGhFXGtqBtXufzFz4QATAXGrLbQdokGS9C1w1meHiyS' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100463.imageUrl} alt={token100463.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100463
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100463.skills} skills &bull; {token100463.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100463.title}
</Box>
<Box color="yellow.500">
{token100463.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100463.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100463
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmY1zZrGRj7L194tWk4oS3DE2ffykDLimjhoLBwFgKDHyZ' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100464.imageUrl} alt={token100464.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100464
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100464.skills} skills &bull; {token100464.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100464.title}
</Box>
<Box color="yellow.500">
{token100464.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100464.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100464
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQNctFi6UMNbNcEgndH96jMwDTLgUpYotQw5W72Bvf837' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100465.imageUrl} alt={token100465.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100465
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100465.skills} skills &bull; {token100465.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100465.title}
</Box>
<Box color="yellow.500">
{token100465.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100465.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100465
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmY6nHo2HejBhYFwdH1CfvSXTT9pLeu3C8JCzf1ic3UH6D' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100466.imageUrl} alt={token100466.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100466
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100466.skills} skills &bull; {token100466.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100466.title}
</Box>
<Box color="yellow.500">
{token100466.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100466.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100466
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmW3jtDCjtXLhSLA9wp3bvMhmMouUDJXuMPaSFnvgfnoKZ' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100467.imageUrl} alt={token100467.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100467
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100467.skills} skills &bull; {token100467.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100467.title}
</Box>
<Box color="yellow.500">
{token100467.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100467.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100467
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmUVXERin9JYURLvrbHQpC1YEcECnTBGUkuVmZgW6ip4D3' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100469.imageUrl} alt={token100469.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100469
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100469.skills} skills &bull; {token100469.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100469.title}
</Box>
<Box color="yellow.500">
{token100469.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100469.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100469
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmesixJ7D8qPApD936xoVFsX2jxHuQwdcS8BneXcZhrvSd' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100470.imageUrl} alt={token100470.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100470
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100470.skills} skills &bull; {token100470.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100470.title}
</Box>
<Box color="yellow.500">
{token100470.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100470.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100470
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWPfxHmeAmvzeiWMC3UaCiZe7DtpJkeoqV7hgVHRXG3KV' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100471.imageUrl} alt={token100471.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100471
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100471.skills} skills &bull; {token100471.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100471.title}
</Box>
<Box color="yellow.500">
{token100471.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100471.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100471
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qme1arn1oC5rM616ZR4BqiPs9FJcZCQ87fgNYPnJ2bj4ui' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100473.imageUrl} alt={token100473.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100473
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100473.skills} skills &bull; {token100473.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100473.title}
</Box>
<Box color="yellow.500">
{token100473.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100473.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100473
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTUh3RB4jCtouoM59ySCTz8Wijr9jBemrgBSoGEeSAXKC' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100474.imageUrl} alt={token100474.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100474
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100474.skills} skills &bull; {token100474.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100474.title}
</Box>
<Box color="yellow.500">
{token100474.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100474.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100474
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmfNZiJkRPxbEQHG3q1MC5m1bAnMraybKnc2sr5b1YUHDm' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100475.imageUrl} alt={token100475.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100475
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100475.skills} skills &bull; {token100475.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100475.title}
</Box>
<Box color="yellow.500">
{token100475.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100475.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100475
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRKzo5CqgLubvvqDi3nLk9Hct5a7nqRVtMuitjSceia3Y' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100476.imageUrl} alt={token100476.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100476
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100476.skills} skills &bull; {token100476.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100476.title}
</Box>
<Box color="yellow.500">
{token100476.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100476.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100476
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmP2zKGH8rKkfJV7oiiP8AeFA9yssM4S8HahH5GnPZRFha' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100478.imageUrl} alt={token100478.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100478
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100478.skills} skills &bull; {token100478.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100478.title}
</Box>
<Box color="yellow.500">
{token100478.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100478.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100478
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRMtZ8VjaTKF5Zc93aP7KArH3dQkSU329uvn11eEkB7Gt' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100479.imageUrl} alt={token100479.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100479
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100479.skills} skills &bull; {token100479.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100479.title}
</Box>
<Box color="yellow.500">
{token100479.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100479.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100479
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSTmjsKVW5SdciaSJ7UQZkRz5HBmLHP9XHPKhCBjyZwQk' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100480.imageUrl} alt={token100480.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100480
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100480.skills} skills &bull; {token100480.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100480.title}
</Box>
<Box color="yellow.500">
{token100480.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100480.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100480
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmcodZuC2DNPEEyDT15iFSkeYKRey89bzyruqpE84Dw6VM' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100481.imageUrl} alt={token100481.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100481
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100481.skills} skills &bull; {token100481.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100481.title}
</Box>
<Box color="yellow.500">
{token100481.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100481.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100481
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSr8fqAtNjnJVTQ3pypnTyiUGK9EDvj7qVaYg4VHp3JWB' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100483.imageUrl} alt={token100483.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100483
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100483.skills} skills &bull; {token100483.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100483.title}
</Box>
<Box color="yellow.500">
{token100483.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100483.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100483
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmawGkJpqinmFkpUQ9nLVkUuwcyQJfL8msYwrQPYFEEv99' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100484.imageUrl} alt={token100484.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100484
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100484.skills} skills &bull; {token100484.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100484.title}
</Box>
<Box color="yellow.500">
{token100484.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100484.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100484
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRZRapW1iqs4MuDwAbMoBgks4zS5Cua7djcQBKnZ6ZwT4' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100485.imageUrl} alt={token100485.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100485
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100485.skills} skills &bull; {token100485.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100485.title}
</Box>
<Box color="yellow.500">
{token100485.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100485.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100485
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTQF319zvVBYminAMfZhUmXitTDmMo38QXT9R1MX3a96a' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100486.imageUrl} alt={token100486.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100486
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100486.skills} skills &bull; {token100486.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100486.title}
</Box>
<Box color="yellow.500">
{token100486.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100486.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100486
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmP2dWvpw2z4HtAEw1Qosai5CBAGNEtdURR97pNTeZuAPC' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100487.imageUrl} alt={token100487.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100487
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100487.skills} skills &bull; {token100487.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100487.title}
</Box>
<Box color="yellow.500">
{token100487.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100487.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100487
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRfDx6fTZST52h8oxGbsgfwHbHPMbJpCPnvp6dnuN9Got' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100488.imageUrl} alt={token100488.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100488
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100488.skills} skills &bull; {token100488.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100488.title}
</Box>
<Box color="yellow.500">
{token100488.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100488.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100488
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmT3PGBAraiUpUxzX2zqNsQJHAaoeFNH58HhaqGUtnmSNC' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100489.imageUrl} alt={token100489.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100489
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100489.skills} skills &bull; {token100489.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100489.title}
</Box>
<Box color="yellow.500">
{token100489.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100489.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100489
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRE2k7QdyoJEeJVHkXBQezEndKQefpqGvvodyhY6guvW8' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100490.imageUrl} alt={token100490.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100490
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100490.skills} skills &bull; {token100490.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100490.title}
</Box>
<Box color="yellow.500">
{token100490.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100490.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100490
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmfBiPs3YJSdyTnv8bX5yJ4JpCu1ERNMbjNSBthyGZbFdT' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100491.imageUrl} alt={token100491.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100491
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100491.skills} skills &bull; {token100491.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100491.title}
</Box>
<Box color="yellow.500">
{token100491.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100491.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100491
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYpNk7ULG9vAeZNYbn4xAXnZ4WX55r57favyqkHgEfHgM' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100492.imageUrl} alt={token100492.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100492
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100492.skills} skills &bull; {token100492.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100492.title}
</Box>
<Box color="yellow.500">
{token100492.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100492.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100492
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmX22dJFRqsGBL6XffMo9sVBMDLMffs7PKYuQ7Fq9cDz1h' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100493.imageUrl} alt={token100493.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100493
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100493.skills} skills &bull; {token100493.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100493.title}
</Box>
<Box color="yellow.500">
{token100493.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100493.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100493
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmX2K8ng23EDaF4CgenJpayC4VDACtcmN1uNvVsdP76BqV' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100494.imageUrl} alt={token100494.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100494
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100494.skills} skills &bull; {token100494.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100494.title}
</Box>
<Box color="yellow.500">
{token100494.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100494.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100494
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmPLDP5UYFErQBA24eMv36sWJ2CaUadJM1T48E5Bn5J4cJ' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100495.imageUrl} alt={token100495.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100495
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100495.skills} skills &bull; {token100495.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100495.title}
</Box>
<Box color="yellow.500">
{token100495.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100495.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100495
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSuPoWoHsnwggjCYZ3RaBhk7JMiwMptzHEhHGBbLLGTHt' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100496.imageUrl} alt={token100496.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100496
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100496.skills} skills &bull; {token100496.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100496.title}
</Box>
<Box color="yellow.500">
{token100496.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100496.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100496
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSfDzmsLFfh7MBJ9W5oB24K8Dg9mW6XeC4u9FkHwqh54A' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100498.imageUrl} alt={token100498.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100498
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100498.skills} skills &bull; {token100498.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100498.title}
</Box>
<Box color="yellow.500">
{token100498.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100498.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100498
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmPoNLuiVtENnjya2sYNQRoxx58extHSiABhuPxVmjb622' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100499.imageUrl} alt={token100499.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100499
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100499.skills} skills &bull; {token100499.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100499.title}
</Box>
<Box color="yellow.500">
{token100499.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100499.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100499
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmPNmqHaomup7AABWFD9TyuKcmrhXF7ogLrjwmwjHZWtpw' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100500.imageUrl} alt={token100500.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100500
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100500.skills} skills &bull; {token100500.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100500.title}
</Box>
<Box color="yellow.500">
{token100500.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100500.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100500
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTK32ys9nvUJZL3kAZ4T3BTmJ86JxAqXfyB8dzFzgyhHp' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100501.imageUrl} alt={token100501.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100501
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100501.skills} skills &bull; {token100501.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100501.title}
</Box>
<Box color="yellow.500">
{token100501.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100501.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100501
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmag3KcG4FkJeB3wCK7LAnwGt2w34YJHE48EkEJAXzA7Wg' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100502.imageUrl} alt={token100502.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100502
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100502.skills} skills &bull; {token100502.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100502.title}
</Box>
<Box color="yellow.500">
{token100502.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100502.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100502
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTD6VJE9v4NiymD9dT1VKqtcp3r7d9s18WhZmsW5KPVgh' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100503.imageUrl} alt={token100503.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100503
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100503.skills} skills &bull; {token100503.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100503.title}
</Box>
<Box color="yellow.500">
{token100503.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100503.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100503
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRfEhr5oFPQcKCa1pW9TSmvTVy7DMFma9u3nrgUhNn2gt' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100504.imageUrl} alt={token100504.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100504
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100504.skills} skills &bull; {token100504.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100504.title}
</Box>
<Box color="yellow.500">
{token100504.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100504.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100504
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmag3KcG4FkJeB3wCK7LAnwGt2w34YJHE48EkEJAXzA7Wg' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100505.imageUrl} alt={token100505.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100505
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100505.skills} skills &bull; {token100505.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100505.title}
</Box>
<Box color="yellow.500">
{token100505.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100505.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100505
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTfwJ5e5BrdS2jbm94ExS2L11meBNWRs1XrVpidbizbgP' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100506.imageUrl} alt={token100506.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100506
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100506.skills} skills &bull; {token100506.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100506.title}
</Box>
<Box color="yellow.500">
{token100506.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100506.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100506
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmNPkGBxnxk1R1TZZeP4uecyqLed5X3H5ZyYRjqrZHkkMA' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100507.imageUrl} alt={token100507.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100507
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100507.skills} skills &bull; {token100507.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100507.title}
</Box>
<Box color="yellow.500">
{token100507.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100507.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100507
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmPHgQVN4BRiKWf4mPw75ScnjjGk1BqiYz4nzD5EKtjCNX' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100508.imageUrl} alt={token100508.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100508
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100508.skills} skills &bull; {token100508.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100508.title}
</Box>
<Box color="yellow.500">
{token100508.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100508.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100508
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmcoohcasRr62YU3nUTpY6THdnThXczKh1ucumLVXHr2NB' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100509.imageUrl} alt={token100509.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100509
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100509.skills} skills &bull; {token100509.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100509.title}
</Box>
<Box color="yellow.500">
{token100509.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100509.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100509
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWpffuGJELStDqMMRhLN8jZqGTuV5Hv9eWAe3x3ff4GMv' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>












































































































































































































































































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100514.imageUrl} alt={token100514.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100514
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100514.skills} skills &bull; {token100514.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100514.title}
</Box>
<Box color="yellow.500">
{token100514.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100514.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100514
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmUN1LTBKvnvot2GPXKRtLmqrkMCJanz4pzyes19gvZoiz' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100515.imageUrl} alt={token100515.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100515
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100515.skills} skills &bull; {token100515.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100515.title}
</Box>
<Box color="yellow.500">
{token100515.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100515.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100515
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmbuLVwtg4veK4ZJQpmcLNtpzvpHnEgFtFEexXbfM5HYj7' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100516.imageUrl} alt={token100516.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100516
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100516.skills} skills &bull; {token100516.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100516.title}
</Box>
<Box color="yellow.500">
{token100516.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100516.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100516
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmeTv2p25HaSDcpnTJ8U3w121C3kMmCG8dLQKJGb8xvEui' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100517.imageUrl} alt={token100517.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100517
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100517.skills} skills &bull; {token100517.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100517.title}
</Box>
<Box color="yellow.500">
{token100517.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100517.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100517
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmVYrPATLLA31ky3HUjNCajbyeAEZAbB8m26gVW8ZFEChE' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100518.imageUrl} alt={token100518.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100518
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100518.skills} skills &bull; {token100518.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100518.title}
</Box>
<Box color="yellow.500">
{token100518.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100518.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100518
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZj6Twyyz1pzaQFeGEKtHS4TYhUozsJLq4YKR4fpSkwqR' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100520.imageUrl} alt={token100520.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100520
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100520.skills} skills &bull; {token100520.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100520.title}
</Box>
<Box color="yellow.500">
{token100520.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100520.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100520
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmNyT6HriQv7yPRzyfKWayA4bJtVHNjW3cdBNFXiLuFTPh' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100521.imageUrl} alt={token100521.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100521
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100521.skills} skills &bull; {token100521.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100521.title}
</Box>
<Box color="yellow.500">
{token100521.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100521.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100521
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTekUacyu21AcwtvKbrehjDxE7E7imeCBLHLabwToamEk' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100522.imageUrl} alt={token100522.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100522
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100522.skills} skills &bull; {token100522.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100522.title}
</Box>
<Box color="yellow.500">
{token100522.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100522.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100522
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmbJnVPrtr3GQT22JWmSoNuVhuvkzf29gv7XSKMG6LzUcS' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100523.imageUrl} alt={token100523.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100523
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100523.skills} skills &bull; {token100523.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100523.title}
</Box>
<Box color="yellow.500">
{token100523.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100523.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100523
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmdeiMeuXJbH55DL8UwxBVgSypAnqspWuuvGonXQG9g2w9' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100524.imageUrl} alt={token100524.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100524
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100524.skills} skills &bull; {token100524.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100524.title}
</Box>
<Box color="yellow.500">
{token100524.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100524.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100524
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmUeuy7A3kR7GPDSpuUdeiXQEdQV45HH9eunnmqgiGy1Zv' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>























































































































































































































































































































































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100530.imageUrl} alt={token100530.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100530
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100530.skills} skills &bull; {token100530.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100530.title}
</Box>
<Box color="yellow.500">
{token100530.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100530.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100530
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmPMMbeJkhAfNTxSxQ5SUwadykDuSqbn1kDPb3zxafkvsf' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100531.imageUrl} alt={token100531.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100531
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100531.skills} skills &bull; {token100531.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100531.title}
</Box>
<Box color="yellow.500">
{token100531.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100531.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100531
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmcBkjGgizKj7kNHGG8UnoH4XagHwj1AXpKiHosRnRkdtk' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100532.imageUrl} alt={token100532.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100532
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100532.skills} skills &bull; {token100532.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100532.title}
</Box>
<Box color="yellow.500">
{token100532.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100532.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100532
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmb3Tb7DMfgpCTLCLkEx4PHaLfS4mbHuf8aYHdRGEK7zXf' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100533.imageUrl} alt={token100533.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100533
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100533.skills} skills &bull; {token100533.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100533.title}
</Box>
<Box color="yellow.500">
{token100533.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100533.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100533
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmPbbQfGC4pN6DNsE2QhzFza4D419FqNmE7TdtZRjiSQuC' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100534.imageUrl} alt={token100534.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100534
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100534.skills} skills &bull; {token100534.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100534.title}
</Box>
<Box color="yellow.500">
{token100534.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100534.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100534
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmcemsKr2vdXHFxa3FMAeSzuoX2CkDS64hrF7uLo4V7iCB' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100538.imageUrl} alt={token100538.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100538
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100538.skills} skills &bull; {token100538.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100538.title}
</Box>
<Box color="yellow.500">
{token100538.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100538.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100538
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmbJNteVpDAa24XPnwnXcMUxEHY55eKf6kfQ4MeaL1vEu5' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100539.imageUrl} alt={token100539.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100539
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100539.skills} skills &bull; {token100539.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100539.title}
</Box>
<Box color="yellow.500">
{token100539.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100539.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100539
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmahBiKQBRor6VaUhP5kvPmPQKU4EKMP1XoYbggaCiFmoi' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100540.imageUrl} alt={token100540.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100540
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100540.skills} skills &bull; {token100540.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100540.title}
</Box>
<Box color="yellow.500">
{token100540.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100540.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100540
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmbVYDdgfrUP4fwjcnijyD68fj8raE7ZdSrt3WV4k6sqaq' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100541.imageUrl} alt={token100541.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100541
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100541.skills} skills &bull; {token100541.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100541.title}
</Box>
<Box color="yellow.500">
{token100541.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100541.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100541
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qme3uXS3YsVwiQVdvABFEL22adrRbLfXSRJPdmAJ4bZJXD' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100542.imageUrl} alt={token100542.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100542
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100542.skills} skills &bull; {token100542.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100542.title}
</Box>
<Box color="yellow.500">
{token100542.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100542.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100542
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTgFU6wTbxtGExqpkdepJT3ETDwLkuDK1wX6pPfBhYfp6' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100543.imageUrl} alt={token100543.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100543
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100543.skills} skills &bull; {token100543.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100543.title}
</Box>
<Box color="yellow.500">
{token100543.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100543.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100543
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmdi3pkQQv9YtxHEZ9CkEsgWoW261fihf5xNhq4SdbUPcz' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100544.imageUrl} alt={token100544.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100544
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100544.skills} skills &bull; {token100544.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100544.title}
</Box>
<Box color="yellow.500">
{token100544.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100544.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100544
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSunPsrH3AFMNDaG28Vofsk8r5uHnM781X8yNiYHJzorP' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100545.imageUrl} alt={token100545.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100545
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100545.skills} skills &bull; {token100545.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100545.title}
</Box>
<Box color="yellow.500">
{token100545.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100545.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100545
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qme1jZAFjYSwaKQWmAkkHdADdqSjFuCeVh8DJibpuG23ZH' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100546.imageUrl} alt={token100546.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100546
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100546.skills} skills &bull; {token100546.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100546.title}
</Box>
<Box color="yellow.500">
{token100546.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100546.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100546
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYtjdx4yfMuZDyHBFBuPV5KF3zjHCcjGNkPryeVeNdRNU' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100547.imageUrl} alt={token100547.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100547
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100547.skills} skills &bull; {token100547.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100547.title}
</Box>
<Box color="yellow.500">
{token100547.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100547.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100547
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmRqHtnuUQpS9poCV2RKNgp3xyy5BnFocaVty41r4mbVpa' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100548.imageUrl} alt={token100548.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100548
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100548.skills} skills &bull; {token100548.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100548.title}
</Box>
<Box color="yellow.500">
{token100548.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100548.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100548
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmXVNnNdiuWrDPMNoNuAkqBAudmU3yNaYHvYXPpQNfueoN' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>

































































































































































































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100552.imageUrl} alt={token100552.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100552
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100552.skills} skills &bull; {token100552.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100552.title}
</Box>
<Box color="yellow.500">
{token100552.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100552.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100552
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmPFY86WEaK1RyzTXSwMQ6HnPrkBnom1jiipzj1rdmJwXu' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100553.imageUrl} alt={token100553.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100553
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100553.skills} skills &bull; {token100553.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100553.title}
</Box>
<Box color="yellow.500">
{token100553.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100553.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100553
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmbDqYgRgxYyvxPU9iB88sU9y6Lpqq29imRpa6bZP45tMr' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100568.imageUrl} alt={token100568.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100568
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100568.skills} skills &bull; {token100568.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100568.title}
</Box>
<Box color="yellow.500">
{token100568.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100568.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100568
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmeRzDbpeq9RCpcGoGARvxA1TXMc6DBXUvw88RkhtJBJud' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100569.imageUrl} alt={token100569.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100569
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100569.skills} skills &bull; {token100569.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100569.title}
</Box>
<Box color="yellow.500">
{token100569.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100569.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100569
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmc4aU5bUvhJ7acKYYjR4rcr9Vch5NM7XBUfwuVqsuMgMq' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100570.imageUrl} alt={token100570.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100570
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100570.skills} skills &bull; {token100570.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100570.title}
</Box>
<Box color="yellow.500">
{token100570.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100570.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100570
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQp8TjiSizdyGJRGZ5sPM6o4AT3LUQCamHRwDaCs7oPUr' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100571.imageUrl} alt={token100571.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100571
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100571.skills} skills &bull; {token100571.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100571.title}
</Box>
<Box color="yellow.500">
{token100571.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100571.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100571
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWDNQHVqXxsHAUWNd5ssxFhUGD974JfT7T53nrsMt3JXU' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100572.imageUrl} alt={token100572.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100572
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100572.skills} skills &bull; {token100572.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100572.title}
</Box>
<Box color="yellow.500">
{token100572.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100572.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100572
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmP4So7gMziWFRVruzS3sbYmHgdpgVxPCfCjypG9buCrSC' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100573.imageUrl} alt={token100573.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100573
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100573.skills} skills &bull; {token100573.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100573.title}
</Box>
<Box color="yellow.500">
{token100573.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100573.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100573
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmaaHzLDxaRMfterfgtisgtca2KaSpG1rEYz9VGUrmpPBf' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100575.imageUrl} alt={token100575.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100575
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100575.skills} skills &bull; {token100575.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100575.title}
</Box>
<Box color="yellow.500">
{token100575.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100575.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100575
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmNb11WmhQA9SFaz871ujCRu9Jb1JjUFQPNZ3NaaJSaxgY' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100576.imageUrl} alt={token100576.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100576
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100576.skills} skills &bull; {token100576.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100576.title}
</Box>
<Box color="yellow.500">
{token100576.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100576.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100576
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSaR4hWaDw3eoMuJuQ5iNyeJVGENeczfMjVKjgxib4tdt' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100577.imageUrl} alt={token100577.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100577
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100577.skills} skills &bull; {token100577.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100577.title}
</Box>
<Box color="yellow.500">
{token100577.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100577.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100577
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZZQAzPtMUVtUfDj3jU95Kp4w42qnJMxpcJERdKbJmTLF' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100578.imageUrl} alt={token100578.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100578
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100578.skills} skills &bull; {token100578.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100578.title}
</Box>
<Box color="yellow.500">
{token100578.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100578.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100578
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmaQdbPJ6KY9hKbBtxFcVmFY9myT1wuNz9QSit5n1v984R' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100579.imageUrl} alt={token100579.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100579
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100579.skills} skills &bull; {token100579.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100579.title}
</Box>
<Box color="yellow.500">
{token100579.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100579.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100579
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmUMgdF9QtBgmRcDTbMo7McgQqCUoUwzwrW1fagokWFSSw' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100580.imageUrl} alt={token100580.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100580
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100580.skills} skills &bull; {token100580.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100580.title}
</Box>
<Box color="yellow.500">
{token100580.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100580.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100580
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmScwN4Espu9nRG52Fn5npVP5sjgLGXUctKNm1qgKgiv8U' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100581.imageUrl} alt={token100581.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100581
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100581.skills} skills &bull; {token100581.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100581.title}
</Box>
<Box color="yellow.500">
{token100581.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100581.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100581
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmen15AEVCSkmBFs9K1YzTc27epH2v2ZKtrrZ23rG3J9dx' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100582.imageUrl} alt={token100582.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100582
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100582.skills} skills &bull; {token100582.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100582.title}
</Box>
<Box color="yellow.500">
{token100582.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100582.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100582
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmVSgrc7zRHz4wbQtmAM8xm2z4QeLFrWJY52F5QNehD275' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100583.imageUrl} alt={token100583.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100583
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100583.skills} skills &bull; {token100583.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100583.title}
</Box>
<Box color="yellow.500">
{token100583.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100583.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100583
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmUC5zcNm741h89uyQzYLQtKKWhftymVbyZzTvZXZ54VHf' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100584.imageUrl} alt={token100584.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100584
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100584.skills} skills &bull; {token100584.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100584.title}
</Box>
<Box color="yellow.500">
{token100584.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100584.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100584
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYAzWJLs47igtKH8C8NrYdiz22zmjeCXNev6oX3rHzzEm' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100585.imageUrl} alt={token100585.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100585
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100585.skills} skills &bull; {token100585.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100585.title}
</Box>
<Box color="yellow.500">
{token100585.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100585.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100585
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmbj3JQ137j12VcUCDaAp8kHsqHaVu7YFYbxM1ChVKNWT1' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100586.imageUrl} alt={token100586.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100586
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100586.skills} skills &bull; {token100586.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100586.title}
</Box>
<Box color="yellow.500">
{token100586.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100586.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100586
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmbNeXEwixrcfmVuNv3ehS8qo5iSrXE4BnTuVdH2on3GFX' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100587.imageUrl} alt={token100587.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100587
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100587.skills} skills &bull; {token100587.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100587.title}
</Box>
<Box color="yellow.500">
{token100587.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100587.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100587
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmR22Pde7BrA9SVeSNDm9MDR2mB8ESpFdqtb63ty6fwUP8' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100588.imageUrl} alt={token100588.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100588
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100588.skills} skills &bull; {token100588.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100588.title}
</Box>
<Box color="yellow.500">
{token100588.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100588.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100588
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmbMoEJXLjYXGEeGjfJrtzKGo9TK9E7yiqdvj3HzKmg19X' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100589.imageUrl} alt={token100589.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100589
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100589.skills} skills &bull; {token100589.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100589.title}
</Box>
<Box color="yellow.500">
{token100589.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100589.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100589
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZsdWe7S6xrLo5syyfuv3LMrLCpUeDDwBKMho5fLppuRV' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100590.imageUrl} alt={token100590.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100590
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100590.skills} skills &bull; {token100590.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100590.title}
</Box>
<Box color="yellow.500">
{token100590.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100590.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100590
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSqaG3FBQs6qLn5ZHCBubwKB1syxogyYR4La4Mf8BKHSL' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100591.imageUrl} alt={token100591.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100591
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100591.skills} skills &bull; {token100591.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100591.title}
</Box>
<Box color="yellow.500">
{token100591.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100591.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100591
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmX9YwFkPvztXNipnSkmunUuwimVKucBqqTAgWUGzVzNUE' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100592.imageUrl} alt={token100592.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100592
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100592.skills} skills &bull; {token100592.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100592.title}
</Box>
<Box color="yellow.500">
{token100592.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100592.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100592
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWBdcbcoUbSdLNY43ihm5bdcDGJX7KV4oQaEhkA6593jH' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100593.imageUrl} alt={token100593.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100593
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100593.skills} skills &bull; {token100593.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100593.title}
</Box>
<Box color="yellow.500">
{token100593.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100593.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100593
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTQDoqFizmuto4X9GtYoRQ7xsCR2tZU4w6ApuSvLpQF4X' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100594.imageUrl} alt={token100594.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100594
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100594.skills} skills &bull; {token100594.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100594.title}
</Box>
<Box color="yellow.500">
{token100594.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100594.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100594
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmbEbdxXizBK3rubtFgPzzd3WSy6DbVy3vdyPVFNmsF8zN' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100595.imageUrl} alt={token100595.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100595
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100595.skills} skills &bull; {token100595.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100595.title}
</Box>
<Box color="yellow.500">
{token100595.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100595.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100595
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmfZuZg1nr254wL8GzX5S4Am8DzuPG4pJHLpvFHaykBsvR' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100596.imageUrl} alt={token100596.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100596
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100596.skills} skills &bull; {token100596.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100596.title}
</Box>
<Box color="yellow.500">
{token100596.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100596.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100596
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTUBkiXvrhxSVgr1jv4ZcSMEE6L4XBm7wfG7kNFYdHotb' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100597.imageUrl} alt={token100597.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100597
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100597.skills} skills &bull; {token100597.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100597.title}
</Box>
<Box color="yellow.500">
{token100597.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100597.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100597
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmS4tQGfNHYEgP9KG9E7eV13PijjXuKntVCpFjEWvabcXr' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100598.imageUrl} alt={token100598.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100598
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100598.skills} skills &bull; {token100598.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100598.title}
</Box>
<Box color="yellow.500">
{token100598.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100598.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100598
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmesyHgssQEfDJ25Gg93A47TZ7m5nuPkpvkAePuk4LU1TS' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100599.imageUrl} alt={token100599.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100599
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100599.skills} skills &bull; {token100599.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100599.title}
</Box>
<Box color="yellow.500">
{token100599.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100599.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100599
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmaqvV6UEsr6pZz6BvHAypYs9xkJhZfwZ9VrJv5a7VKJdw' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100600.imageUrl} alt={token100600.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100600
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100600.skills} skills &bull; {token100600.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100600.title}
</Box>
<Box color="yellow.500">
{token100600.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100600.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100600
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZihp9B2L1ki1AWsHygAjVX7fP13VLUnbpzwYwi6fqLFJ' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100601.imageUrl} alt={token100601.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100601
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100601.skills} skills &bull; {token100601.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100601.title}
</Box>
<Box color="yellow.500">
{token100601.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100601.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100601
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSeVPauRw1nRzx5MyekMd2v78wCVgbwiZENhkdYBLy5LZ' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100602.imageUrl} alt={token100602.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100602
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100602.skills} skills &bull; {token100602.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100602.title}
</Box>
<Box color="yellow.500">
{token100602.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100602.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100602
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmPvFy45DKjSoZYQmfU7PShvCeN4AMukYnoCkn4SXgBtSM' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100603.imageUrl} alt={token100603.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100603
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100603.skills} skills &bull; {token100603.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100603.title}
</Box>
<Box color="yellow.500">
{token100603.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100603.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100603
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmeDCR8ssVzSFZGF3decMYU1W4LNv6ysCApEFn5nKkDNpP' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100604.imageUrl} alt={token100604.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100604
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100604.skills} skills &bull; {token100604.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100604.title}
</Box>
<Box color="yellow.500">
{token100604.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100604.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100604
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmVGwNfk6L7CgZaCF7rxzFVwsgwKT6PCP8tMtKkg8yVQR7' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100605.imageUrl} alt={token100605.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100605
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100605.skills} skills &bull; {token100605.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100605.title}
</Box>
<Box color="yellow.500">
{token100605.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100605.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100605
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmVe2AWrfmzfKccmWUUJ2NjrSYbCrfJVnScRVpqs6jpKd4' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100606.imageUrl} alt={token100606.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100606
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100606.skills} skills &bull; {token100606.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100606.title}
</Box>
<Box color="yellow.500">
{token100606.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100606.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100606
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWfsNiZcUkobsrwcEun2UkLxL5R8z9SE7PZDydKUxTpiq' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100607.imageUrl} alt={token100607.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100607
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100607.skills} skills &bull; {token100607.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100607.title}
</Box>
<Box color="yellow.500">
{token100607.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100607.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100607
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmaDYF4hhrGWDw36WXuPp8Kg4YGAof961fHxHAwrZmNsZ3' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100608.imageUrl} alt={token100608.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100608
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100608.skills} skills &bull; {token100608.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100608.title}
</Box>
<Box color="yellow.500">
{token100608.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100608.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100608
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZkHEqsoVSWfSLwRansfQqkoVFamcL61jwNTK3ykmHNTC' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100609.imageUrl} alt={token100609.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100609
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100609.skills} skills &bull; {token100609.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100609.title}
</Box>
<Box color="yellow.500">
{token100609.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100609.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100609
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWkie1zDPYhf62K2sss9xWF1rmbGUsVgEppaS9Mq2uUeW' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100610.imageUrl} alt={token100610.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100610
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100610.skills} skills &bull; {token100610.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100610.title}
</Box>
<Box color="yellow.500">
{token100610.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100610.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100610
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmSD5UTjiuPKarSLbXmBc9P3uoqjSPS2mXwvWPHnXHDT5z' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100611.imageUrl} alt={token100611.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100611
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100611.skills} skills &bull; {token100611.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100611.title}
</Box>
<Box color="yellow.500">
{token100611.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100611.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100611
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmerf8hLMTuKXbV4LYYFz5W72BzSFR3oJ3mVE5BWXGM4ik' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100621.imageUrl} alt={token100621.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100621
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100621.skills} skills &bull; {token100621.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100621.title}
</Box>
<Box color="yellow.500">
{token100621.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100621.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100621
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmbwQrg6X6r549GBHauVy3F8tDEgp5W6Q1t9abaatdus2N' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100622.imageUrl} alt={token100622.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100622
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100622.skills} skills &bull; {token100622.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100622.title}
</Box>
<Box color="yellow.500">
{token100622.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100622.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100622
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmfLuymj4sFgJLsZUjngKSbJN7egT7TJVNNPy7eciEMNeb' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100623.imageUrl} alt={token100623.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100623
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100623.skills} skills &bull; {token100623.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100623.title}
</Box>
<Box color="yellow.500">
{token100623.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100623.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100623
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYdj5jdUJsuNpCMcxdLiCv4h6uvLL8Us5CfTY98pAMR2k' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100624.imageUrl} alt={token100624.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100624
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100624.skills} skills &bull; {token100624.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100624.title}
</Box>
<Box color="yellow.500">
{token100624.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100624.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100624
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmY3HSLRAF7b3HKayh1L48Lhbkyc7TFrBniYodoZjq8j7S' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100625.imageUrl} alt={token100625.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100625
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100625.skills} skills &bull; {token100625.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100625.title}
</Box>
<Box color="yellow.500">
{token100625.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100625.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100625
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYkyvG5WLYkgGknouA9tUDbTodg51pAeV1tbDpsaucd6Q' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100626.imageUrl} alt={token100626.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100626
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100626.skills} skills &bull; {token100626.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100626.title}
</Box>
<Box color="yellow.500">
{token100626.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100626.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100626
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWxqi1XeKztfNdhV1XaLv8xbkQyfkyemegX1W5skDeJyV' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100627.imageUrl} alt={token100627.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100627
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100627.skills} skills &bull; {token100627.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100627.title}
</Box>
<Box color="yellow.500">
{token100627.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100627.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100627
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmYRzo5nXj5FRNSJU5pPcLVhhwsMn4GiGqrLcYPcn8h8SX' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100628.imageUrl} alt={token100628.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100628
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100628.skills} skills &bull; {token100628.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100628.title}
</Box>
<Box color="yellow.500">
{token100628.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100628.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100628
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qme4ADifptj9hHPDn8utaKmhuAEKxVLKjFajR1cvfFR3kF' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100629.imageUrl} alt={token100629.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100629
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100629.skills} skills &bull; {token100629.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100629.title}
</Box>
<Box color="yellow.500">
{token100629.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100629.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100629
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmPsfiL2VwY5FptafKcPn4cSYyeRYrwz9FeS9mGYHVtvgL' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>

































































































































































































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100633.imageUrl} alt={token100633.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100633
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100633.skills} skills &bull; {token100633.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100633.title}
</Box>
<Box color="yellow.500">
{token100633.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100633.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100633
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmepqobbXucQJaoXuJpbhZ8x3ZCs6iyRhzUroZ227G5he6' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100634.imageUrl} alt={token100634.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100634
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100634.skills} skills &bull; {token100634.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100634.title}
</Box>
<Box color="yellow.500">
{token100634.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100634.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100634
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQGvAmu6FLA86jyunmSvojXgKT2VZxnYbFxrFcH82DnaE' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100635.imageUrl} alt={token100635.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100635
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100635.skills} skills &bull; {token100635.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100635.title}
</Box>
<Box color="yellow.500">
{token100635.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100635.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100635
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmcB2EGGzJTnRYo6A2WCF3tCtbBU5DHsV3JwGygofCtv6K' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100636.imageUrl} alt={token100636.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100636
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100636.skills} skills &bull; {token100636.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100636.title}
</Box>
<Box color="yellow.500">
{token100636.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100636.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100636
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmQ7WHsJN5assbH992xsCJ9hyFyhp3BjsbNT5iwfoPspLA' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100637.imageUrl} alt={token100637.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100637
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100637.skills} skills &bull; {token100637.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100637.title}
</Box>
<Box color="yellow.500">
{token100637.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100637.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100637
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZbG1g4h3RH6bojAQUEfVb7sT4Rdh4DK6HM3cB2bfXT2b' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100638.imageUrl} alt={token100638.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100638
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100638.skills} skills &bull; {token100638.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100638.title}
</Box>
<Box color="yellow.500">
{token100638.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100638.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100638
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmV4JLUCCrp35P2BgWoaNBY6rnWZL6TbLeKmsCEjd44Vcm' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100639.imageUrl} alt={token100639.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100639
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100639.skills} skills &bull; {token100639.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100639.title}
</Box>
<Box color="yellow.500">
{token100639.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100639.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100639
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmXXiBZGtzapMFkhHKQ1YjSNMsgm5scqpkR9TFsPCYvPyQ' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100640.imageUrl} alt={token100640.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100640
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100640.skills} skills &bull; {token100640.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100640.title}
</Box>
<Box color="yellow.500">
{token100640.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100640.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100640
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmVQH843h9nv9sFafMHCfdYH1v4GmpeZA2iGDhCVfBEoD5' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>






















































































































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100643.imageUrl} alt={token100643.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100643
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100643.skills} skills &bull; {token100643.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100643.title}
</Box>
<Box color="yellow.500">
{token100643.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100643.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100643
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmd4EJqYMREQv3qvNwfvad856ixonz5KSMMSDowMj2QyZh' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100644.imageUrl} alt={token100644.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100644
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100644.skills} skills &bull; {token100644.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100644.title}
</Box>
<Box color="yellow.500">
{token100644.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100644.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100644
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmdU7P6jqu3UbA7SxWxGagEJtWcimATDpGm2y2yo7UMmgZ' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100645.imageUrl} alt={token100645.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100645
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100645.skills} skills &bull; {token100645.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100645.title}
</Box>
<Box color="yellow.500">
{token100645.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100645.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100645
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmXQD2oEVA1KRdQv5Y7rq93LpowYXzDKYRmxzQrpEfniyr' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100646.imageUrl} alt={token100646.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100646
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100646.skills} skills &bull; {token100646.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100646.title}
</Box>
<Box color="yellow.500">
{token100646.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100646.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100646
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZs1sT5f3UAMymFeQB5ZmnHZCWLJdzEjXDVjV8dGnGdUF' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100647.imageUrl} alt={token100647.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100647
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100647.skills} skills &bull; {token100647.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100647.title}
</Box>
<Box color="yellow.500">
{token100647.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100647.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100647
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmNbcKYTHawFHz1bqSsEkSGfoLgsvrWgCte89koFJonPXH' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100648.imageUrl} alt={token100648.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100648
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100648.skills} skills &bull; {token100648.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100648.title}
</Box>
<Box color="yellow.500">
{token100648.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100648.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100648
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmfLKUyG2zL1aYDETDGWdpgknhvRKyiGF6Lm5DPKCpr5iz' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100649.imageUrl} alt={token100649.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100649
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100649.skills} skills &bull; {token100649.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100649.title}
</Box>
<Box color="yellow.500">
{token100649.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100649.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100649
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmfWcfN5MLsBSLUNzsLEQdHNFxto2sHCKX5mJH7s2C17zm' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100650.imageUrl} alt={token100650.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100650
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100650.skills} skills &bull; {token100650.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100650.title}
</Box>
<Box color="yellow.500">
{token100650.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100650.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100650
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmPU2CjMFCed5Vht3S1Y3Hcc7JYPup1Kys1p2TmDwCkJic' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100651.imageUrl} alt={token100651.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100651
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100651.skills} skills &bull; {token100651.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100651.title}
</Box>
<Box color="yellow.500">
{token100651.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100651.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100651
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmNxTgek9BNhjTjCSK9gUwGoZFLFDKYZZyMkyibUGYqRGk' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100652.imageUrl} alt={token100652.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100652
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100652.skills} skills &bull; {token100652.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100652.title}
</Box>
<Box color="yellow.500">
{token100652.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100652.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100652
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmbhJxH7LBXDLJWu62B35zd9VGpx3L8nxxhJDdbjkh16XE' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100653.imageUrl} alt={token100653.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100653
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100653.skills} skills &bull; {token100653.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100653.title}
</Box>
<Box color="yellow.500">
{token100653.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100653.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100653
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmU88Y6jP8AXqMFWU9ViB71VRjXd8QVsLyMMWu246sdwhX' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100654.imageUrl} alt={token100654.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100654
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100654.skills} skills &bull; {token100654.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100654.title}
</Box>
<Box color="yellow.500">
{token100654.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100654.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100654
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmP5zbWT9YV34JiG9qPyzigXL58ThMRvHXGJRL1okyA5ZH' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100655.imageUrl} alt={token100655.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100655
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100655.skills} skills &bull; {token100655.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100655.title}
</Box>
<Box color="yellow.500">
{token100655.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100655.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100655
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmZC7LgLEFbRmvgmD6YzC44EyV4LjtDCx6t5YSumDwmpC1' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100656.imageUrl} alt={token100656.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100656
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100656.skills} skills &bull; {token100656.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100656.title}
</Box>
<Box color="yellow.500">
{token100656.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100656.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100656
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmdhbjmHLCnKh4RWAoyckMHnuMXthSXE5nGpzAbjFxcg2J' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100657.imageUrl} alt={token100657.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100657
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100657.skills} skills &bull; {token100657.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100657.title}
</Box>
<Box color="yellow.500">
{token100657.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100657.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100657
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmT5H2iekLJE5h4ioBVoaabKNPib4NmR5ANVzQuDhsfjKm' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100658.imageUrl} alt={token100658.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100658
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100658.skills} skills &bull; {token100658.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100658.title}
</Box>
<Box color="yellow.500">
{token100658.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100658.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100658
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTvrvdarQJJbu8fhC41hL5hKseSnCKoEJPAxv8erYwFZ5' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100659.imageUrl} alt={token100659.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100659
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100659.skills} skills &bull; {token100659.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100659.title}
</Box>
<Box color="yellow.500">
{token100659.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100659.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100659
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmbbDdxc6sWx32SGt2kscau52qu1FLxm8KgpGm9pVXaU9N' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100660.imageUrl} alt={token100660.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100660
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100660.skills} skills &bull; {token100660.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100660.title}
</Box>
<Box color="yellow.500">
{token100660.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100660.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100660
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmNTkF6intdShAH9vXdoEJrELCfVTpEW26duPD3JGENQAy' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100661.imageUrl} alt={token100661.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100661
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100661.skills} skills &bull; {token100661.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100661.title}
</Box>
<Box color="yellow.500">
{token100661.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100661.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100661
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmWNHwRDyhS7HeDPqNe17F1V4x6dEk9SXubYFznpTR4FgH' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100662.imageUrl} alt={token100662.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100662
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100662.skills} skills &bull; {token100662.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100662.title}
</Box>
<Box color="yellow.500">
{token100662.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100662.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100662
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmPuqrqVfXnozadQ8KgqpEaQHnbuoMNfeRgi4jTZ94rMHH' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100663.imageUrl} alt={token100663.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100663
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100663.skills} skills &bull; {token100663.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100663.title}
</Box>
<Box color="yellow.500">
{token100663.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100663.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100663
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/Qmba3zWD5rYcnJbgLLfPdwnKSioAaKxfHDit1Mq7rSD3rw' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100664.imageUrl} alt={token100664.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100664
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100664.skills} skills &bull; {token100664.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100664.title}
</Box>
<Box color="yellow.500">
{token100664.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100664.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100664
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTZrLmmE4zDhj3dXyi1g5nX4anVSQxWQhyGFY7UsJcDbS' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100665.imageUrl} alt={token100665.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100665
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100665.skills} skills &bull; {token100665.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100665.title}
</Box>
<Box color="yellow.500">
{token100665.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100665.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100665
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmdgnUdvG64SpZG5em1uBEFY92RjG8cebjchXDifm6dpQ7' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>











































































<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100667.imageUrl} alt={token100667.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100667
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100667.skills} skills &bull; {token100667.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100667.title}
</Box>
<Box color="yellow.500">
{token100667.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100667.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100667
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmTGWzGFbShpDVKfNoKqaNarJA5HvR1E5feh4Yb5oX49zc' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100668.imageUrl} alt={token100668.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100668
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100668.skills} skills &bull; {token100668.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100668.title}
</Box>
<Box color="yellow.500">
{token100668.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100668.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100668
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmahnhsSqH8iedoZmNtKG7yx4UouYzTeudGQambm2i8DHU' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>
<Box
w="100%"
bg="gray.600"
maxW="sm"
borderWidth="1px"
borderRadius="lg"
ml="2"
mr="2"
overflow="hidden"
>
<Image src={token100669.imageUrl} alt={token100669.imageAlt} />
<Box p="6">
<Box d="flex" alignItems="baseline">
<Badge borderRadius="full" px="2" colorScheme="orange">
100669
</Badge>
<Box
color="yellow.500"
fontWeight="semibold"
letterSpacing="wide"
fontSize="xs"
textTransform="uppercase"
ml="2"
>
{token100669.skills} skills &bull; {token100669.guilds} guilds
</Box>
</Box>
<Box
mt="1"
fontWeight="semibold"
as="h4"
lineHeight="tight"
isTruncated
>
{token100669.title}
</Box>
<Box color="yellow.500">
{token100669.formattedPrice}
<Box as="span" color="white.600" fontSize="sm">
per texture
</Box>
</Box>
<Box d="flex" mt="2" alignItems="center">
{/* {Array(5)
.fill("")
.map((_, i) => (
<ImStarFull
key={i}
color={i < token100669.rating ? "teal.500" : "gray.300"}
/>
))} */}
{/* <Box as="span" ml="2" color="gray.600" fontSize="sm">
100669
</Box> */}
<Box ml="5" fontSize="xs">
<Link href='https://freeweb3.infura-ipfs.io/ipfs/QmNUu2tUmWAYgaEJDEdNChDeGgZ7uKaSPjC8fbKo71gqyt' isExternal>
Token Metadata <ExternalLinkIcon mx='2px' />
</Link>
</Box>
</Box>
</Box>
<Box
as="button"
fontSize="xs"
ml="2"
mb="2"
borderRadius="md"
bg="deepskyblue"
color="black"
px={4}
h={6}
>
Contact Designer
</Box>
</Box>















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































      </SimpleGrid>
    </div>
  );
}
export default GridLayout6;
